import { AuthorDTO } from '@/types/AuthorDTO';
import { BasketDTO } from '@/types/order/BasketDTO';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { GoodsBasket } from '@/types/order/GoodsBasket';
import { GoodsDTO } from '@/types/order/GoodsDTO';
import { PageRequestDTO } from '@/types/page/PageRequestDTO';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RegionDTO } from '@/types/RegionDTO';
import { RootState } from '@/app/store';
import { SenderDTO } from '@/types/SenderDTO';

export interface CreateOrderState {
  region: RegionDTO | null;
  department: DepartmentDTO | null;
  author: AuthorDTO | null;
  sender: SenderDTO | null;
  basket: BasketDTO | null;
  pageRequest: PageRequestDTO;
  uuid: string | null;
}

const initialState: CreateOrderState = {
  region: null,
  department: null,
  author: null,
  sender: null,
  basket: null,
  pageRequest: {
    pageSize: 50,
    pageNumber: 0,
  },
  uuid: null,
};

export const createOrderSlice = createSlice({
  name: 'createOrder',
  initialState,
  reducers: {
    setRegionAndDepartment(
      state: CreateOrderState,
      action: PayloadAction<{
        region: RegionDTO | null;
        department: DepartmentDTO | null;
      }>
    ) {
      state.region = action.payload.region;
      state.department = action.payload.department;
    },
    setDepartment(
      state: CreateOrderState,
      action: PayloadAction<{
        department: DepartmentDTO | null;
      }>
    ) {
      state.department = action.payload.department;
    },
    setAuthor(state: CreateOrderState, action: PayloadAction<AuthorDTO>) {
      state.author = action.payload;
    },
    setSender(state: CreateOrderState, action: PayloadAction<SenderDTO>) {
      state.sender = action.payload;
    },
    setPageNumber(state: CreateOrderState, action: PayloadAction<number>) {
      state.pageRequest.pageNumber = action.payload;
    },
    increaseGoodsBasket(
      state: CreateOrderState,
      action: PayloadAction<GoodsDTO>
    ) {
      const basket = state.basket;
      const goodsBasket = state.basket?.goodsBasket;
      if (basket && goodsBasket && goodsBasket.length > 0) {
        const goodsPresentIndex = goodsBasket.findIndex(
          (basketItem) => basketItem.goods.id === action.payload.id
        );
        if (goodsPresentIndex > -1) {
          goodsBasket[goodsPresentIndex].count =
            goodsBasket[goodsPresentIndex].count + 1;
        } else {
          goodsBasket.push({ goods: action.payload, count: 1, isStoke: true });
        }
        basket.currency = basket.currency + action.payload.price;
      } else {
        state.basket = {
          goodsBasket: [{ goods: action.payload, count: 1, isStoke: true }],
          currency: action.payload.price,
        };
      }
    },
    decreaseGoodsBasket(
      state: CreateOrderState,
      action: PayloadAction<GoodsDTO>
    ) {
      const basket = state.basket;
      const goodsBasket = state.basket?.goodsBasket;
      if (basket && goodsBasket && goodsBasket.length > 0) {
        const goodsPresentIndex = goodsBasket.findIndex(
          (basketItem) => basketItem.goods.id === action.payload.id
        );
        if (goodsPresentIndex > -1) {
          if (goodsBasket[goodsPresentIndex].count > 1) {
            goodsBasket[goodsPresentIndex].count =
              goodsBasket[goodsPresentIndex].count - 1;
          } else {
            goodsBasket.splice(goodsPresentIndex, 1);
          }
        }
        basket.currency = basket.currency - action.payload.price;
        if (goodsBasket.length === 0) {
          state.basket = null;
        }
      }
    },
    addGoodsBasket(
      state: CreateOrderState,
      action: PayloadAction<{ product: GoodsDTO; count: number }>
    ) {
      const basket = state.basket;
      const goodsBasket = state.basket?.goodsBasket;
      if (basket && goodsBasket && goodsBasket.length > 0) {
        const goodsPresentIndex = goodsBasket.findIndex(
          (basketItem) => basketItem.goods.id === action.payload.product.id
        );
        if (goodsPresentIndex > -1) {
          if (action.payload.count > 0) {
            basket.currency +=
              action.payload.product.price *
              (action.payload.count - goodsBasket[goodsPresentIndex].count);
            goodsBasket[goodsPresentIndex].count = action.payload.count;
          } else {
            goodsBasket.splice(goodsPresentIndex, 1);
          }
        }
        if (goodsBasket.length === 0) {
          state.basket = null;
        }
      }
    },
    clearGoodsBasket(state: CreateOrderState, action: PayloadAction<GoodsDTO>) {
      const basket = state.basket;
      if (basket) {
        const goodsPresentIndex = basket.goodsBasket.findIndex(
          (basketItem) => basketItem.goods.id === action.payload.id
        );
        if (goodsPresentIndex > -1) {
          basket.currency =
            basket.currency -
            basket.goodsBasket[goodsPresentIndex].goods.price *
              basket.goodsBasket[goodsPresentIndex].count;
          basket.goodsBasket.splice(goodsPresentIndex, 1);
          if (basket.goodsBasket.length === 0) {
            state.basket = null;
          }
        }
      }
    },
    setAllGoodsBasket(
      state: CreateOrderState,
      action: PayloadAction<GoodsBasket[]>
    ) {
      if (state.basket && state.basket.goodsBasket && state.basket.currency) {
        state.basket.goodsBasket = action.payload;
        let currentCurrency = 0;
        state.basket.goodsBasket.map((product) => {
          if (product.isStoke) {
            currentCurrency += product.count * product.goods.price;
          }
        });
        state.basket.currency = currentCurrency;
      }
    },
    resetGoodsBasket(state: CreateOrderState) {
      if (state.basket?.goodsBasket) {
        state.basket = null;
      }
    },
  },
});

export const {
  setRegionAndDepartment,
  setDepartment,
  setAuthor,
  setSender,
  setPageNumber,
  increaseGoodsBasket,
  decreaseGoodsBasket,
  addGoodsBasket,
  clearGoodsBasket,
  setAllGoodsBasket,
  resetGoodsBasket,
} = createOrderSlice.actions;

export const getAuthor = (state: RootState): AuthorDTO | null => {
  return state.order.author;
};

export const getSender = (state: RootState): SenderDTO | null => {
  return state.order.sender;
};

export const getRegion = (state: RootState): RegionDTO | null => {
  return state.order.region;
};

export const getDepartment = (state: RootState): DepartmentDTO | null => {
  return state.order.department;
};

export const getBasket = (state: RootState): BasketDTO | null => {
  return state.order.basket;
};

export const getPageRequest = (state: RootState): PageRequestDTO => {
  return state.order.pageRequest;
};
