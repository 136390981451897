import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const SearchIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      d={
        'M10.3584 16.8135C11.7207 16.8135 12.9863 16.3828 14.0322 15.6533L17.873 19.5029C18.0752 19.6963 18.3389 19.793 18.6201 19.793C19.209 19.793 19.6309 19.3447 19.6309 18.7646C19.6309 18.4922 19.543 18.2285 19.3408 18.0264L15.5264 14.2031C16.3262 13.1309 16.8008 11.8037 16.8008 10.3711C16.8008 6.8291 13.9004 3.92871 10.3584 3.92871C6.81641 3.92871 3.91602 6.82031 3.91602 10.3711C3.91602 13.9131 6.80762 16.8135 10.3584 16.8135ZM10.3584 15.3105C7.64258 15.3105 5.41016 13.0781 5.41016 10.3711C5.41016 7.66406 7.64258 5.43164 10.3584 5.43164C13.0654 5.43164 15.2979 7.66406 15.2979 10.3711C15.2979 13.0781 13.0654 15.3105 10.3584 15.3105Z'
      }
    />
  </SvgIcon>
);
