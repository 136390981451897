import { BasketDTO } from '@/types/order/BasketDTO';
import { GoodsBasket } from '@/types/order/GoodsBasket';
import { GoodsCounter } from '@/components/order/GoodsCounter';
import { GoodsDTO } from '@/types/order/GoodsDTO';
import { Grid, Typography } from '@mui/material';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  goods: GoodsDTO;
  basket: BasketDTO | null;
  isProductPresentInBasket: (
    goods: GoodsDTO,
    goodsBasket?: GoodsBasket[]
  ) => GoodsBasket | undefined;
}

export const GoodsMobileCurrency: FC<Props> = (props: Props) => {
  const { goods, basket, isProductPresentInBasket } = props;

  return (
    <Grid container={true} mt={1} mb={1} justifyContent={'space-between'}>
      <Grid item={true}>
        <Typography
          fontSize={'20px'}
          lineHeight={'28px'}
          fontWeight={500}
          color={'primary'}>
          {formatCurrency(goods.price)}
        </Typography>
      </Grid>
      <Grid item={true}>
        <GoodsCounter
          goods={goods}
          goodsFromBasket={isProductPresentInBasket(goods, basket?.goodsBasket)}
        />
      </Grid>
    </Grid>
  );
};
