import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const BasketIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 27 36'}
    sx={{ width: '27px', height: '36px' }}
    {...props}>
    <path
      d={'M16 9H11'}
      stroke={'#009F53'}
      strokeWidth={'2'}
      strokeMiterlimit={'10'}
    />
    <path
      d={
        'M7 9H2.0964L1.00249 31.3181C0.985355 31.7574 1.05689 32.1958 1.21285 32.6067C1.36881 33.0177 1.60597 33.3927 1.91007 33.7095C2.21418 34.0263 2.57895 34.2783 2.98256 34.4503C3.38616 34.6223 3.82029 34.7107 4.25884 34.7104H22.4995C22.937 34.7109 23.37 34.6224 23.7723 34.4501C24.1745 34.2778 24.5378 34.0254 24.8399 33.7082C25.142 33.391 25.3767 33.0156 25.5297 32.6048C25.6828 32.1939 25.7511 31.7562 25.7304 31.3181L24.6619 9H20'
      }
      stroke={'#009F53'}
      strokeWidth={'2'}
      strokeMiterlimit={'10'}
    />
    <path
      d={
        'M9 15V5.5C9 3.01472 11.0147 1 13.5 1V1C15.9853 1 18 3.01472 18 5.5V15'
      }
      stroke={'#009F53'}
      strokeWidth={'2'}
    />
    <line
      x1={'7'}
      y1={'14'}
      x2={'11'}
      y2={'14'}
      stroke={'#009F53'}
      strokeWidth={'2'}
    />
    <line
      x1={'16'}
      y1={'14'}
      x2={'20'}
      y2={'14'}
      stroke={'#009F53'}
      strokeWidth={'2'}
    />
  </SvgIcon>
);
