import { AppBar, Box, Grid, Theme } from '@mui/material';
import { HeaderTab } from '@/components/common/header/HeaderTab';
import { LogoIcon } from '@/app/icons/LogoIcon';
import { ROUTE_INFO, ROUTE_ORDER } from '@/app/routes';
import { RouteLink } from '@/components/common/RouteLink';
import { getBasket, getDepartment } from '@/services/createOrderSlice';
import { useAppSelector } from '@/app/hooks';
import Basket from '@/components/basket/Basket';
import React, { FC } from 'react';

export const Header: FC = () => {
  const basket = useAppSelector(getBasket);
  const department = useAppSelector(getDepartment);

  return (
    <AppBar
      position={'fixed'}
      sx={(theme: Theme) => ({
        bottom: 'auto',
        left: 'auto',
        right: 'auto',
        top: theme.spacing(0),
        maxWidth: theme.spacing(144),
        backgroundColor: 'white',
        boxShadow: 'none',
        padding: theme.spacing(3, 0),
        width: 'calc(100% - 48px)',
      })}
      component={'div'}>
      <Grid
        container={true}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Grid item={true}>
          <Grid
            container={true}
            direction={'row'}
            columnSpacing={3}
            alignItems={'center'}>
            <Grid item={true} display={'flex'}>
              <RouteLink to={ROUTE_ORDER}>
                <LogoIcon height={'64px'} width={'86px'} />
              </RouteLink>
            </Grid>
            <Grid item={true}>
              <Grid container={true} columnSpacing={6}>
                <Grid item={true}>
                  <HeaderTab to={ROUTE_ORDER} label={'Отправить заказ'} />
                </Grid>
                <Grid item={true}>
                  <HeaderTab to={ROUTE_INFO} label={'Информация'} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {basket && department && (
          <Grid item={true} xs={2}>
            <Basket basket={basket} deliveryCost={department.deliveryCost} />
          </Grid>
        )}
      </Grid>
    </AppBar>
  );
};
