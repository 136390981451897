import { BasketDTO } from '@/types/order/BasketDTO';
import { Box, Button, Grid, Theme, Typography } from '@mui/material';
import { GoodsBasket } from '@/types/order/GoodsBasket';
import { GoodsDTO } from '@/types/order/GoodsDTO';
import { GoodsMobileCurrency } from '@/components/order/GoodsMobileCurrency';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { FC } from 'react';

interface Props {
  showInfo: boolean;
  isShowInfo: (showInfo: boolean) => void;
  isMobile: boolean | undefined;
  goods: GoodsDTO;
  basket: BasketDTO | null;
  isProductPresentInBasket: (
    goods: GoodsDTO,
    goodsBasket?: GoodsBasket[]
  ) => GoodsBasket | undefined;
}

export const GoodsItemName: FC<Props> = (props: Props) => {
  const {
    showInfo,
    isShowInfo,
    isMobile,
    goods,
    basket,
    isProductPresentInBasket,
  } = props;

  return (
    <Grid container={true} columnSpacing={2} wrap={'nowrap'}>
      <Grid item={true}>
        <Box
          sx={{
            position: 'relative',
            height: '40px',
            overflow: 'hidden',
            width: '46px',
          }}>
          <img
            src={goods.photoUrl}
            alt={'drug-image'}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'scale-down',
            }}
          />
        </Box>
      </Grid>
      <Grid item={true} xs={true}>
        <Grid container={true} direction={'column'} wrap={'nowrap'}>
          <Grid item={true}>
            <Grid container={true}>
              <Grid item={true}>
                <Typography
                  variant={'h4'}
                  fontSize={'18px'}
                  sx={{ wordBreak: 'break-all' }}>
                  {goods.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {isMobile && (
            <Grid item={true}>
              <GoodsMobileCurrency
                goods={goods}
                basket={basket}
                isProductPresentInBasket={isProductPresentInBasket}
              />
            </Grid>
          )}
          <Grid item={true}>
            <Grid container={true} direction={'column'} wrap={'nowrap'}>
              <Grid item={true}>
                <Typography
                  variant={'body2'}
                  component={'span'}
                  color={(theme: Theme) => theme.colors.grayText}>
                  {`${goods.manufacturer}, `}
                </Typography>
                <Typography
                  variant={'body2'}
                  component={'span'}
                  color={(theme: Theme) => theme.colors.grayText}
                  sx={{ textTransform: 'capitalize', display: 'inline-block' }}>
                  {goods?.manufacturerCountry?.toLowerCase()}
                </Typography>
              </Grid>
            </Grid>
            <Grid item={true}>
              <Button
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  padding: '0px',
                }}
                disableRipple={true}
                onClick={() => isShowInfo(!showInfo)}>
                <Grid container={true} alignItems={'center'}>
                  <Grid item={true}>{'Показать'}</Grid>
                  <Grid item={true}>
                    <ArrowDropDownIcon
                      sx={{
                        display: 'block',
                        fontSize: 18,
                        transform: `rotate(${showInfo ? '180deg' : '0'})`,
                      }}
                    />
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
