import { GoodsDTO } from '@/types/order/GoodsDTO';
import { ShowcaseDTO } from '@/types/order/ShowcaseDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const goodsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAllGoods: build.query<GoodsDTO[], number>({
      query: (departmentId: number) => ({
        url: '/goods',
        method: 'GET',
        params: {
          departmentId: departmentId,
        },
      }),
      providesTags: ['Goods'],
    }),
    getAllGoodsByCategories: build.query<ShowcaseDTO[], number>({
      query: (departmentId: number) => ({
        url: '/goods/showcase',
        method: 'GET',
        params: {
          departmentId: departmentId,
        },
      }),
      providesTags: ['Goods'],
    }),
  }),
});

export const { useGetAllGoodsQuery, useGetAllGoodsByCategoriesQuery } =
  goodsApiSlice;
