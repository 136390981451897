import { BannerProvider } from '@/components/common/banner/BannerProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { LocalizationProvider } from '@mui/lab';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { config } from '@/app/config';
import { persistor, store } from '@/app/store';
import { ru } from 'date-fns/locale';
import DateAdapter from '@mui/lab/AdapterDateFns';
import Main from '@/components/Main';
import React, { FC } from 'react';
import theme from '@/app/theme';
import '@assets/styles/index.scss';

export const App: FC = () => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={ru}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BannerProvider>
              <Router basename={config.basePath}>
                <GoogleReCaptchaProvider
                  language={'ru-RU'}
                  reCaptchaKey={config.reCaptcha.siteKey}>
                  <Main />
                </GoogleReCaptchaProvider>
              </Router>
            </BannerProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  );
};
