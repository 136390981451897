import { BasketDTO } from '@/types/order/BasketDTO';
import {
  Collapse,
  Grid,
  Skeleton,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { GoodsAddInfo } from '@/components/order/GoodsAddInfo';
import { GoodsBasket } from '@/types/order/GoodsBasket';
import { GoodsCounter } from '@/components/order/GoodsCounter';
import { GoodsDTO } from '@/types/order/GoodsDTO';
import { GoodsItemName } from '@/components/order/GoodsItemName';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC, Fragment, useState } from 'react';

interface Props {
  loading: boolean;
  index: number;
  item: GoodsDTO;
  isProductPresentInBasket: (
    goods: GoodsDTO,
    goodsBasket?: GoodsBasket[]
  ) => GoodsBasket | undefined;
  basket: BasketDTO | null;
  isMobile: boolean | undefined;
}

export const GoodsItem: FC<Props> = (props: Props) => {
  const { loading, index, item, isProductPresentInBasket, basket, isMobile } =
    props;

  const [showInfo, isShowInfo] = useState<boolean>(false);
  return (
    <Fragment>
      <TableRow>
        <TableCell
          sx={{
            verticalAlign: 'top',
            borderTop: (theme: Theme) =>
              index === 0 ? '1px solid ' + theme.colors.tableBorder : '',
            borderBottomStyle: 'unset',
            paddingBottom: '14px',
          }}>
          {loading ? (
            <Skeleton height={'28px'} />
          ) : (
            <GoodsItemName
              showInfo={showInfo}
              isShowInfo={isShowInfo}
              isMobile={isMobile}
              isProductPresentInBasket={isProductPresentInBasket}
              goods={item}
              basket={basket}
            />
          )}
        </TableCell>
        {!isMobile && (
          <Fragment>
            <TableCell
              sx={{
                borderTop: (theme: Theme) =>
                  index === 0 ? '1px solid ' + theme.colors.tableBorder : '',
                borderBottomStyle: 'unset',
                paddingBottom: '14px',
              }}>
              <Grid container={true} justifyContent={'flex-end'}>
                <Grid item={true}>
                  {loading ? (
                    <Skeleton height={'28px'} width={'120px'} />
                  ) : (
                    <Typography
                      fontSize={'20px'}
                      lineHeight={'28px'}
                      fontWeight={500}>
                      {formatCurrency(item.price)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </TableCell>
            <TableCell
              sx={{
                pr: 0,
                width: (theme: Theme) => theme.spacing(14),
                borderTop: (theme: Theme) =>
                  index === 0 ? '1px solid ' + theme.colors.tableBorder : '',
                borderBottomStyle: 'unset',
                paddingBottom: '14px',
              }}>
              <Grid container={true} justifyContent={'flex-end'}>
                <Grid item={true}>
                  {loading ? (
                    <Skeleton
                      variant={'circular'}
                      width={'36px'}
                      height={'36px'}
                    />
                  ) : (
                    <GoodsCounter
                      goods={item}
                      goodsFromBasket={isProductPresentInBasket(
                        item,
                        basket?.goodsBasket
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </TableCell>
          </Fragment>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={3}
          sx={{
            '&.MuiTableCell-root': {
              padding: 0,
              paddingBottom: showInfo
                ? isMobile
                  ? '16px'
                  : '22px'
                : isMobile
                ? '0px'
                : '8px',
            },
          }}>
          <Collapse in={showInfo} timeout={'auto'} unmountOnExit={true}>
            <GoodsAddInfo goods={item} />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
