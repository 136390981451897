import { CategoriesTab } from '@/components/order/CategoriesTab';
import { Grid, Skeleton, Stack, Tabs } from '@mui/material';
import React, { FC, SyntheticEvent } from 'react';

interface Props {
  categories: string[];
  currentCategory?: string;
  setCategory: (categoryName: string) => void;
  loading: boolean;
}

const NavigationPanelGoods: FC<Props> = (props: Props) => {
  const { categories, loading, currentCategory, setCategory } = props;

  const handleChange = (event: SyntheticEvent, category: string) => {
    setCategory(category);
  };

  return (
    <Grid container={true}>
      <Grid item={true}>
        {loading ? (
          <Stack spacing={1}>
            {Array.from(new Array(4)).map((item, index) => (
              <Skeleton
                key={index}
                height={'36px'}
                width={'200px'}
                style={{ marginBottom: '8px' }}
              />
            ))}
          </Stack>
        ) : (
          currentCategory && (
            <Tabs
              value={currentCategory}
              onChange={handleChange}
              aria-label={'nav categories tabs'}
              orientation={'vertical'}
              variant={'scrollable'}
              visibleScrollbar={true}
              scrollButtons={false}
              TabIndicatorProps={{ style: { height: '0px' } }}>
              {categories.map((category, index) => (
                <CategoriesTab key={index} label={category} value={category} />
              ))}
            </Tabs>
          )
        )}
      </Grid>
    </Grid>
  );
};

export default NavigationPanelGoods;
