import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const FsinAtlasIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{
      width: '24px',
      height: '24px',
      display: 'flex',
      fill: 'none',
    }}
    {...props}>
    <path
      d={
        'M12 7.2L4.33501 4.47467C3.684 4.2432 3 4.72595 3 5.41689V16.0942C3 16.5174 3.26631 16.8947 3.66499 17.0364L9.58748 19.1422C9.69503 19.1805 9.80834 19.2 9.92249 19.2H10H14.0775C14.1917 19.2 14.305 19.1805 14.4125 19.1422L20.335 17.0364C20.7337 16.8947 21 16.5174 21 16.0942V5.41689C21 4.72595 20.316 4.2432 19.665 4.47467L12 7.2Z'
      }
    />
    <path
      d={
        'M16 22.2501C14.7603 22.7343 13.4112 23 12 23C10.5888 23 9.23971 22.7343 7.99997 22.2501M6.5 2.4716C8.11795 1.53567 9.99641 1 12 1C14.0036 1 15.882 1.53567 17.5 2.4716'
      }
      strokeLinecap={'round'}
    />
    <path d={'M12 7V19'} />
    <path d={'M14 19.2L19 21V17.5'} />
    <path d={'M10 19.2L5 21V17.5'} />
    <path d={'M5 4.5V18'} />
    <path d={'M19 4.5V17.5'} />
  </SvgIcon>
);
