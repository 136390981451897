import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const PlusIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 13 13'}
    sx={{ width: '13px', height: '13px' }}
    {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M5.5 12.5C5.5 13.0523 5.94771 13.5 6.5 13.5C7.05228 13.5 7.5 13.0523 7.5 12.5V8L12 8C12.5523 8 13 7.55229 13 7C13 6.44772 12.5523 6 12 6L7.5 6V1.5C7.5 0.947715 7.05228 0.5 6.5 0.5C5.94771 0.5 5.5 0.947715 5.5 1.5V6L1 6C0.447715 6 0 6.44771 0 7C0 7.55228 0.447715 8 1 8L5.5 8V12.5Z'
      }
      fill={'#009F53'}
    />
  </SvgIcon>
);
