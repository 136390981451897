import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const DeliveryIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 18 14'}
    sx={{ width: '18px', height: '14px' }}
    {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M2.25 11.25H0.75C0.336 11.25 0 10.914 0 10.5V9H11.25V2.25H14.7502C15.588 2.25 15.9503 2.682 16.2023 3.08025C16.6478 3.78525 17.3542 4.90425 17.784 5.61375C17.925 5.84775 18 6.11625 18 6.39V9.75C18 10.5907 17.454 11.25 16.5 11.25H15.75C15.75 12.492 14.742 13.5 13.5 13.5C12.258 13.5 11.25 12.492 11.25 11.25H6.75C6.75 12.492 5.742 13.5 4.5 13.5C3.258 13.5 2.25 12.492 2.25 11.25ZM4.5 10.35C4.9965 10.35 5.4 10.7535 5.4 11.25C5.4 11.7465 4.9965 12.15 4.5 12.15C4.0035 12.15 3.6 11.7465 3.6 11.25C3.6 10.7535 4.0035 10.35 4.5 10.35ZM13.5 10.35C13.9965 10.35 14.4 10.7535 14.4 11.25C14.4 11.7465 13.9965 12.15 13.5 12.15C13.0035 12.15 12.6 11.7465 12.6 11.25C12.6 10.7535 13.0035 10.35 13.5 10.35ZM10.5 8.25H0V0.75C0 0.336 0.336 0 0.75 0H9.75C10.164 0 10.5 0.336 10.5 0.75V8.25ZM12.75 3.75V6H16.2863L15.216 4.128C15.0825 3.894 14.8343 3.75 14.565 3.75H12.75Z'
      }
      fill={'#040C1E'}
    />
  </SvgIcon>
);
