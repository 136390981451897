import { BasketField } from '@/components/common/field/BasketField';
import { Collapse, Grid, IconButton } from '@mui/material';
import { GoodsBasket } from '@/types/order/GoodsBasket';
import { GoodsDTO } from '@/types/order/GoodsDTO';
import { MaxCountInfo } from '@/components/order/MaxCountInfo';
import { MinusIcon } from '@/app/icons/MinusIcon';
import { PlusIcon } from '@/app/icons/PlusIcon';
import {
  decreaseGoodsBasket,
  increaseGoodsBasket,
} from '@/services/createOrderSlice';
import { useAppDispatch } from '@/app/hooks';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  goods: GoodsDTO;
  goodsFromBasket?: GoodsBasket;
}

export const GoodsCounter: FC<Props> = (props: Props) => {
  const { goods, goodsFromBasket } = props;
  const dispatch = useAppDispatch();
  const active = !!goodsFromBasket;
  const handleAddGoods = () => {
    dispatch(increaseGoodsBasket(goods));
    setCount(countGoods ? countGoods + 1 : 1);
  };
  const handleRemoveGoods = () => {
    if (goodsFromBasket && goodsFromBasket.count > 0) {
      dispatch(decreaseGoodsBasket(goods));
      setCount(countGoods ? countGoods - 1 : 0);
    }
  };
  const isDisabled = () => {
    return !!(
      goodsFromBasket && goodsFromBasket.count >= goods.maxCountPerOrder
    );
  };
  const [countGoods, setCount] = useState(goodsFromBasket?.count);

  useEffect(() => {
    setCount(goodsFromBasket?.count);
  }, [goodsFromBasket]);

  return (
    <Grid container={true} justifyContent={'flex-end'}>
      <Grid
        item={true}
        sx={{
          height: '36px',
          width: active ? '107px' : '36px',
          border: '1px solid green',
          borderRadius: '29px',
          transition: 'width 0.4s ease-in-out, padding 0.4s ease-in-out',
          padding: active ? '8px 11px' : '8px',
          display: 'flex',
          alignItems: 'center',
        }}>
        <Collapse
          orientation={'horizontal'}
          in={active}
          collapsedSize={18}
          timeout={400}>
          <Grid
            container={true}
            wrap={'nowrap'}
            sx={{ minWidth: '83px' }}
            direction={active ? 'row-reverse' : 'row'}>
            <Grid item={true} sx={{ display: 'flex', alignItems: 'center' }}>
              {isDisabled() ? (
                <MaxCountInfo maxCount={goods.maxCountPerOrder} />
              ) : (
                <IconButton
                  disabled={isDisabled()}
                  color={'primary'}
                  onClick={handleAddGoods}
                  sx={{ padding: '0px' }}>
                  <PlusIcon sx={{ width: '18px', height: '18px' }} />
                </IconButton>
              )}
            </Grid>
            <Grid item={true} xs={true}>
              <BasketField
                countGoods={countGoods}
                setCountGoods={setCount}
                product={goods}
              />
            </Grid>
            <Grid item={true} sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                color={'primary'}
                onClick={handleRemoveGoods}
                sx={{ padding: '0px' }}>
                <MinusIcon sx={{ width: '18px', height: '18px' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};
