import {
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { OrderDTO } from '@/types/order/OrderDTO';
import { OrderTotalSumInfo } from '@/components/common/OrderTotalSumInfo';
import { calculateOrderTotalSum } from '@/utils/common-utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { FC } from 'react';

interface Props {
  order: OrderDTO;
}

export const OrderPayedInfo: FC<Props> = (props: Props) => {
  const { order } = props;
  const totalSum: number = calculateOrderTotalSum(order);
  return (
    <Grid
      container={true}
      direction={'column'}
      sx={(theme: Theme) => ({
        borderRadius: theme.spacing(1.5),
        backgroundColor: theme.colors.grayBackground,
      })}>
      <Grid
        item={true}
        sx={(theme: Theme) => ({
          padding: theme.spacing(4, 3, 0),
        })}>
        <OrderTotalSumInfo totalSum={totalSum} />
      </Grid>
      <Grid item={true} pt={3.5} pb={3}>
        <Divider />
      </Grid>
      <Grid item={true}>
        <Grid
          container={true}
          wrap={'nowrap'}
          sx={(theme: Theme) => ({
            padding: theme.spacing(0, 3, 4),
          })}>
          <Grid item={true} pl={0.25} pr={1}>
            {order?.number ? (
              <CheckCircleIcon color={'primary'} />
            ) : (
              <CircularProgress
                sx={(theme: Theme) => ({
                  color: theme.colors.orange,
                })}
                size={'24px'}
              />
            )}
          </Grid>
          <Grid item={true}>
            <Grid container={true} direction={'column'}>
              <Grid item={true} pb={1}>
                <Typography
                  variant={'h4'}
                  sx={(theme: Theme) => ({
                    color: order?.number
                      ? theme.colors.primary
                      : theme.colors.orange,
                  })}>
                  {order?.number ? 'Заказ сформирован' : 'Заказ формируется'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant={'body1'}>
                  {
                    'После оплаты Вам придет на почту уведомление о смене статуса заказа.'
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
