import * as Yup from 'yup';
import { CreateFeedbackDTO } from '@/types/help/CreateFeedbackDTO';
import { FeedbackTopicDTO } from '@/types/help/FeedbackTopicDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MESSAGE_REQUIRED } from '@/utils/validation-utils';
import { MobileBar } from '@/components/common/MobileBar';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { OutlinedSelect } from '@/components/common/field/OutlinedSelect';
import { emailSchema } from '@/validation/emailSchema';
import { isBlank } from '@/utils/string-utils';
import {
  useGetFeedbackTopicsQuery,
  useSendFeedbackMutation,
} from '@/services/api/helpApiSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback } from 'react';

interface FormValues {
  topic: number;
  email?: string;
  authorFullName?: string;
  orderNumber?: number | null;
  question?: string;
}

interface Props {
  isMobile: boolean;
}

export const FeedbackForm: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const { data: topics } = useGetFeedbackTopicsQuery();
  const [sendFeedback, { isLoading }] = useSendFeedbackMutation();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: FormValues = {
    topic: 0,
    email: '',
    authorFullName: '',
    orderNumber: null,
    question: '',
  };

  const initialErrors: FormikErrors<FormValues> = {
    email: isBlank(initialValues.email) ? MESSAGE_REQUIRED : undefined,
    question: isBlank(initialValues.email) ? MESSAGE_REQUIRED : undefined,
  };

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    topic: Yup.number().required(),
    email: emailSchema.required(MESSAGE_REQUIRED).nullable(),
    authorFullName: Yup.string(),
    orderNumber: Yup.number().nullable(),
    question: Yup.string().required(MESSAGE_REQUIRED).nullable(),
  });

  //TODO: make handleReCaptchaVerify like custom hook
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    return await executeRecaptcha('feedbackAction');
  }, [executeRecaptcha]);

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ): Promise<void> => {
    handleReCaptchaVerify().then(async (token) => {
      const { setFieldValue, setFieldTouched } = helpers;
      const dto: CreateFeedbackDTO = {
        topic: values.topic,
        email: values.email || '',
        author: values.authorFullName || '',
        question: values.question || '',
        order: values.orderNumber ? values.orderNumber.toString() : '',
        token: token,
      };
      await sendFeedback(dto)
        .unwrap()
        .then(() => {
          setFieldValue('email', '');
          setFieldTouched('email', false);
          setFieldValue('question', '');
          setFieldTouched('question', false);
          setFieldValue('orderNumber', '');
          setFieldTouched('orderNumber', false);
          setFieldValue('authorFullName', '');
          setFieldTouched('authorFullName', false);
          enqueueSnackbar('Ваш отзыв отправлен', {
            variant: 'success',
          });
        })
        .catch(() =>
          enqueueSnackbar('Ошибка отправки отзыва', {
            variant: 'error',
          })
        );
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        submitForm,
        isValid,
      }: FormikProps<FormValues>) => {
        return (
          <Grid container={true} direction={'column'}>
            <Grid item={true} paddingBottom={6.5}>
              <Grid
                container={true}
                spacing={isMobile ? 5.5 : 3}
                direction={isMobile ? 'column' : 'row'}>
                <Grid item={true} xs={true}>
                  <OutlinedSelect
                    name={'topic'}
                    displayEmpty={false}
                    disabled={isLoading}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      setFieldTouched(e.target.name, true, false);
                      setFieldValue(e.target.name, e.target.value, true);
                    }}
                    value={values.topic}
                    error={touched.topic && Boolean(errors.topic)}
                    helperText={touched.topic ? errors.topic : ''}
                    label={'тема'}
                    MenuProps={{ PaperProps: { sx: { marginTop: '3px' } } }}>
                    {topics &&
                      topics.map((topic: FeedbackTopicDTO) => (
                        <MenuItem key={topic.id} value={topic.id}>
                          {topic.name}
                        </MenuItem>
                      ))}
                  </OutlinedSelect>
                </Grid>
                <Grid item={true} xs={true}>
                  <OutlinedField
                    name={'authorFullName'}
                    label={'имя'}
                    disabled={isLoading}
                    value={values.authorFullName}
                    error={
                      touched.authorFullName && Boolean(errors.authorFullName)
                    }
                    helperText={
                      touched.authorFullName ? errors.authorFullName : ''
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setFieldTouched(e.target.name, true, false);
                      setFieldValue(e.target.name, e.target.value, true);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} paddingBottom={isMobile ? 2.5 : 3.5}>
              <Grid
                container={true}
                spacing={isMobile ? 5.5 : 3}
                direction={isMobile ? 'column' : 'row'}>
                <Grid item={true} xs={true}>
                  <OutlinedField
                    name={'email'}
                    label={'E-MAIL'}
                    disabled={isLoading}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setFieldTouched(e.target.name, true, false);
                      setFieldValue(e.target.name, e.target.value, true);
                    }}
                  />
                </Grid>
                <Grid item={true} xs={true}>
                  <OutlinedField
                    name={'orderNumber'}
                    label={'Номер заказа'}
                    disabled={isLoading}
                    value={values.orderNumber}
                    error={touched.orderNumber && Boolean(errors.orderNumber)}
                    helperText={touched.orderNumber ? errors.orderNumber : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setFieldTouched(e.target.name, true, false);
                      setFieldValue(
                        e.target.name,
                        e.target.value.replace(/[^0-9]/g, ''),
                        true
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} paddingBottom={6.5}>
              <OutlinedField
                name={'question'}
                disabled={isLoading}
                multiline={true}
                rows={5}
                placeholder={'Ваш вопрос'}
                isNotAbsoluteHelperText={true}
                value={values.question}
                error={touched.question && Boolean(errors.question)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldTouched(e.target.name, true, false);
                  setFieldValue(e.target.name, e.target.value, true);
                }}
              />
            </Grid>
            <Grid item={true}>
              {isMobile ? (
                <MobileBar>
                  <FixedButton
                    color={'primary'}
                    variant={'contained'}
                    disabled={isLoading || !isValid}
                    onClick={submitForm}>
                    {'Отправить'}
                  </FixedButton>
                </MobileBar>
              ) : (
                <Grid container={true}>
                  <Grid item={true} xs={8}>
                    <LoadingButton
                      fullWidth={true}
                      variant={'contained'}
                      type={'submit'}
                      onClick={submitForm}
                      isLoading={isLoading}
                      disabled={isLoading || !isValid}>
                      {'Отправить'}
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};
