import { CancelIcon } from '@/app/icons/CancelIcon';
import {
  Fade,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC, MouseEvent } from 'react';

export interface OutlinedFieldProps extends OutlinedInputProps {
  size?: 'small' | 'medium';
  helperText?: string;
  onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
  withLabel?: boolean;
  isNotAbsoluteHelperText?: boolean;
}

export const OutlinedField: FC<OutlinedFieldProps> = (
  props: OutlinedFieldProps
) => {
  const {
    size,
    onClear,
    label,
    error,
    helperText,
    withLabel,
    isNotAbsoluteHelperText,
    ...other
  } = props;
  return (
    <FormControl
      variant={'outlined'}
      size={size}
      fullWidth={true}
      sx={(theme: Theme) => ({
        marginTop: theme.spacing(withLabel ? 3 : 0),
      })}>
      {label && (
        <InputLabel error={error} id={props.name + '-input-label'}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        sx={{
          paddingRight: (theme: Theme) =>
            theme.spacing(props.endAdornment ? 1 : 0),
        }}
        endAdornment={
          onClear ? (
            <InputAdornment position={'end'}>
              <Fade in={Boolean(props.value)}>
                <IconButton
                  size={'dense'}
                  edge={'end'}
                  sx={{ padding: 0, margin: 0 }}
                  onClick={onClear}
                  onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                  }}>
                  <CancelIcon
                    sx={{
                      width: (theme: Theme) => theme.spacing(4),
                      height: (theme: Theme) => theme.spacing(4),
                    }}
                  />
                </IconButton>
              </Fade>
            </InputAdornment>
          ) : null
        }
        error={error}
        {...other}
      />
      {helperText && (
        <FormHelperText
          error={error}
          id={props.name + '-input-helperText'}
          sx={
            isNotAbsoluteHelperText
              ? { position: 'inherit' }
              : {
                  position: 'absolute',
                  transform:
                    size == 'small'
                      ? 'translate(0, 40px)'
                      : 'translate(0, 48px)',
                }
          }>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
