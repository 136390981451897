import { FooterItem } from '@/components/common/footer/FooterItem';
import { FsinAtlasIcon } from '@/app/icons/fsin-services/FsinAtlasIcon';
import { FsinLetterIcon } from '@/app/icons/fsin-services/FsinLetterIcon';
import { FsinMoneyIcon } from '@/app/icons/fsin-services/FsinMoneyIcon';
import { FsinWindowIcon } from '@/app/icons/fsin-services/FsinWindowIcon';
import { FsinetIcon } from '@/app/icons/fsin-services/FsinetIcon';
import { Grid } from '@mui/material';
import { config } from '@/app/config';
import React, { FC } from 'react';

export const FooterNavigationMobile: FC = () => {
  return (
    <Grid
      container={true}
      columnSpacing={2}
      justifyContent={'center'}
      rowSpacing={'22px'}>
      <Grid item={true}>
        <Grid
          container={true}
          direction={'column'}
          wrap={'nowrap'}
          rowSpacing={'22px'}>
          <Grid item={true}>
            <FooterItem
              icon={<FsinMoneyIcon />}
              href={config.links.money}
              mainText={'Деньги'}
              secondaryText={'Денежные переводы'}
            />
          </Grid>
          <Grid item={true}>
            <FooterItem
              icon={<FsinAtlasIcon />}
              href={config.links.atlas}
              mainText={'Атлас'}
              secondaryText={'Справочник тюрем'}
            />
          </Grid>
          <Grid item={true}>
            <FooterItem
              icon={<FsinetIcon />}
              href={config.links.fsinet}
              mainText={'ФСИНЭТ'}
              secondaryText={'Связь с осужденными'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <Grid
          container={true}
          direction={'column'}
          wrap={'nowrap'}
          rowSpacing={'22px'}>
          <Grid item={true}>
            <FooterItem
              icon={<FsinLetterIcon />}
              href={config.links.letter}
              mainText={'Письмо'}
              secondaryText={'Электронные письма'}
            />
          </Grid>
          <Grid item={true}>
            <FooterItem
              icon={<FsinWindowIcon />}
              href={config.links.window}
              mainText={'Окно'}
              secondaryText={'Запись в бюро передач'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
