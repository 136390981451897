import { Divider, Grid, Theme } from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MastercardIcon } from '@/app/icons/payment/MastercardIcon';
import { MirIcon } from '@/app/icons/payment/MirIcon';
import { OrderTotalSumInfo } from '@/components/common/OrderTotalSumInfo';
import { PaymentTimeInfo } from '@/components/common/PaymentTimeInfo';
import { TypographyConditions } from '@/components/common/TypographyConditions';
import { VisaIcon } from '@/app/icons/payment/VisaIcon';
import React, { FC } from 'react';

interface Props {
  totalSum: number;
  isLoading?: boolean;
  handlePaySubmit: () => Promise<void>;
}

export const OrderPaymentBlock: FC<Props> = (props: Props) => {
  const { totalSum, handlePaySubmit, isLoading } = props;
  return (
    <Grid
      container={true}
      direction={'column'}
      sx={(theme: Theme) => ({
        borderRadius: theme.spacing(1.5),
        backgroundColor: theme.colors.grayBackground,
      })}>
      <Grid
        item={true}
        sx={(theme: Theme) => ({
          padding: theme.spacing(4, 3, 0),
        })}>
        <OrderTotalSumInfo totalSum={totalSum} />
      </Grid>
      <Grid item={true} pt={3.5} pb={3}>
        <Divider />
      </Grid>
      <Grid
        item={true}
        sx={(theme: Theme) => ({
          padding: theme.spacing(0, 3, 4),
        })}>
        <PaymentTimeInfo />
      </Grid>
      <Grid item={true} pb={3.5} pr={3} pl={3}>
        <LoadingButton
          fullWidth={true}
          variant={'contained'}
          isLoading={isLoading}
          onClick={handlePaySubmit}>
          {'Оплатить'}
        </LoadingButton>
      </Grid>
      <Grid item={true} pb={3.5} pr={3} pl={3}>
        <Grid container={true} direction={'row'} spacing={3}>
          <Grid item={true}>
            <Grid container={true} spacing={1.5}>
              <Grid item={true}>
                <VisaIcon />
              </Grid>
              <Grid item={true}>
                <MastercardIcon />
              </Grid>
              <Grid item={true}>
                <MirIcon />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <TypographyConditions
              buttonName={'Оплатить'}
              conditionsText={
                'Нажимая кнопку «Оплатить», я соглашаюсь и принимаю\n'
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
