import { Grid, Typography } from '@mui/material';
import { MainCategoryButton } from '@/components/common/button/MainCategoryButton';
import React, { FC } from 'react';

interface Props {
  currentCategory: string | undefined;
  handleSetCategory: (categoryName: string) => void;
}

export const NavigationMobileHeader: FC<Props> = (props: Props) => {
  const { currentCategory, handleSetCategory } = props;

  return (
    <Grid
      container={true}
      direction={'column'}
      wrap={'nowrap'}
      alignItems={'center'}
      spacing={2}>
      <Grid item={true}>
        <Typography
          variant={'h4'}
          align={'center'}
          fontSize={'20px'}
          lineHeight={'28px'}>
          {currentCategory}
        </Typography>
      </Grid>
      <Grid item={true} pb={2}>
        <MainCategoryButton onClick={() => handleSetCategory('')} />
      </Grid>
    </Grid>
  );
};
