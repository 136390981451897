import { BasketGoodsCounter } from '@/components/basket/BasketGoodsCounter';
import { Divider, Grid, IconButton, Theme, Typography } from '@mui/material';
import { GoodsBasket } from '@/types/order/GoodsBasket';
import { MESSAGE_PRODUCT_ERROR } from '@/utils/validation-utils';
import { TrashIcon } from '@/app/icons/TrashIcon';
import { clearGoodsBasket } from '@/services/createOrderSlice';
import { formatCurrency } from '@/utils/string-utils';
import { useAppDispatch } from '@/app/hooks';
import React, { FC } from 'react';

interface Props {
  product: GoodsBasket;
  nextProductOutStoke?: boolean;
}

export const BasketProduct: FC<Props> = (props: Props) => {
  const { product, nextProductOutStoke } = props;
  const dispatch = useAppDispatch();
  const handleClearProduct = () => {
    dispatch(clearGoodsBasket(product.goods));
  };

  return (
    <Grid
      container={true}
      direction={'column'}
      sx={(theme: Theme) => ({
        background: product.isStoke
          ? theme.colors.white
          : theme.colors.redBackground,
      })}>
      <Grid
        item={true}
        sx={(theme: Theme) => ({
          padding: theme.spacing(2, 4.5),
        })}>
        <Grid container={true}>
          <Grid item={true} pr={5.5}>
            <BasketGoodsCounter product={product} />
          </Grid>
          <Grid item={true} pr={1.5} xs={true}>
            <Typography variant={'body2'} mt={product.isStoke ? 0 : 0.25}>
              {product.goods.name}
            </Typography>
            {!product.isStoke && (
              <Typography
                variant={'body2'}
                color={(theme: Theme) => theme.colors.red}>
                {MESSAGE_PRODUCT_ERROR}
              </Typography>
            )}
          </Grid>
          <Grid item={true} pr={2}>
            {product.isStoke && (
              <Typography variant={'h5'}>
                {formatCurrency(product.goods.price * product.count)}
              </Typography>
            )}
          </Grid>
          <Grid item={true} sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={handleClearProduct}
              sx={(theme: Theme) => ({
                marginTop: theme.spacing(-0.5),
                padding: theme.spacing(0.25),
              })}>
              <TrashIcon
                sx={(theme: Theme) => ({
                  width: theme.spacing(2.5),
                  height: theme.spacing(2.5),
                  color: theme.colors.inputBorder,
                })}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {product.isStoke && !nextProductOutStoke && (
        <Grid item={true} pr={4.5} pl={4.5}>
          <Divider />
        </Grid>
      )}
    </Grid>
  );
};
