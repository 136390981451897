import { ButtonProps, Typography } from '@mui/material';
import { Path } from 'history';
import { RouteLink } from '@/components/common/RouteLink';
import { Theme } from '@mui/material/styles';
import { useRouteMatch } from 'react-router-dom';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  to: Path;
  label: string;
  value?: number;
  total?: number;
  size?: 'small' | 'medium';
  activeOnlyWhenExact?: boolean;
}

export const HeaderTab: FC<Props> = (props: Props) => {
  const { to, label, activeOnlyWhenExact } = props;
  const match = useRouteMatch({
    path: to.toString(),
    exact: activeOnlyWhenExact,
  });
  return (
    <RouteLink to={to}>
      <Typography
        variant={'h4'}
        fontSize={'14px'}
        lineHeight={'14px'}
        sx={(theme: Theme) => ({
          color: match ? theme.colors.grayText : theme.colors.primary,
          '&: hover': {
            color: match ? theme.colors.grayText : theme.colors.primaryDark,
          },
        })}>
        {label}
      </Typography>
    </RouteLink>
  );
};
