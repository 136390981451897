import { FeedbackForm } from '@/components/feedback/FeedbackForm';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';

export const FeedbackPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  return (
    <Grid
      container={true}
      direction={'column'}
      mt={isMobile ? -3 : 0}
      wrap={'nowrap'}>
      <Grid item={true} paddingBottom={4}>
        <Typography variant={'h1'} style={{ fontSize: '24px' }}>
          {'Обратная связь'}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Grid container={true} columns={16}>
          <Grid item={true} xs={isMobile ? 16 : 9}>
            <Grid container={true} direction={'column'}>
              {!isMobile && (
                <Grid item={true} paddingBottom={8.25}>
                  <Typography
                    variant={'body2'}
                    whiteSpace={'pre-line'}
                    pb={1.25}>
                    {
                      'Здесь Вы можете задать вопрос службе технической поддержки.'
                    }
                  </Typography>
                  <Typography variant={'body2'} whiteSpace={'pre-line'}>
                    {
                      'Обязательно укажите ваш e-mail, именно на него и придет ответ на ваш вопрос.'
                    }
                  </Typography>
                </Grid>
              )}
              <Grid item={true}>
                <FeedbackForm isMobile={isMobile} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
