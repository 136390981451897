import * as Yup from 'yup';
import { RegionDTO } from '@/types/RegionDTO';
import { departmentDTOSchema } from '@/validation/departmentDTOSchema';

export const regionDTOSchema: Yup.SchemaOf<RegionDTO> = Yup.object({
  id: Yup.number().required(),
  name: Yup.string().required(),
  departments: Yup.array()
    .of(departmentDTOSchema)
    .min(1, 'Регион не содержит подключенных учреждений'),
}).typeError('Выберите регион.');
