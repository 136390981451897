import { PaymentRequestDTO } from '@/types/aloe/PaymentRequestDTO';
import { PaymentResponseDTO } from '@/types/aloe/PaymentResponseDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const paymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    payOrder: build.mutation<PaymentResponseDTO, PaymentRequestDTO>({
      query: (dto: PaymentRequestDTO) => ({
        url: '/payment/order-aloe',
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['Order'],
    }),
  }),
});

export const { usePayOrderMutation } = paymentApiSlice;
