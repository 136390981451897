import { AlertIcon } from '@/app/icons/AlertIcon';
import { Divider, Grid, Theme, Typography } from '@mui/material';
import { OrderDTO } from '@/types/order/OrderDTO';
import { OrderTotalSumInfo } from '@/components/common/OrderTotalSumInfo';
import { calculateOrderTotalSum } from '@/utils/common-utils';
import React, { FC } from 'react';

interface Props {
  order: OrderDTO;
}

export const OrderModerationInfo: FC<Props> = (props: Props) => {
  const { order } = props;
  const totalSum: number = calculateOrderTotalSum(order);
  return (
    <Grid
      container={true}
      direction={'column'}
      sx={(theme: Theme) => ({
        borderRadius: theme.spacing(1.5),
        backgroundColor: theme.colors.grayBackground,
      })}>
      <Grid
        item={true}
        sx={(theme: Theme) => ({
          padding: theme.spacing(4, 3, 0),
        })}>
        <OrderTotalSumInfo totalSum={totalSum} />
      </Grid>
      <Grid item={true} pt={3.5} pb={3}>
        <Divider />
      </Grid>
      <Grid item={true}>
        <Grid
          container={true}
          wrap={'nowrap'}
          sx={(theme: Theme) => ({
            padding: theme.spacing(0, 3, 4),
          })}>
          <Grid item={true} pl={0.25} pr={1}>
            <AlertIcon
              sx={(theme: Theme) => ({
                width: '24px',
                height: '24px',
                color: theme.colors.iconColor,
              })}
            />
          </Grid>
          <Grid item={true}>
            <Grid container={true} direction={'column'}>
              <Grid item={true} pb={1}>
                <Typography variant={'h4'}>
                  {'Заказ передан в обработку'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant={'body1'} mb={2}>
                  {
                    'Врач в учреждении посмотрит заказ. Если что-то из товаров нельзя доставить в учреждение, врач уберёт это из заказа.'
                  }
                </Typography>
                <Typography variant={'body1'}>
                  {
                    'После модерации вы сможете оплатить заказ. Ожидайте уведомление на почту.'
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
