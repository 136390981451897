import { AloePaymentNotificationStatus } from '@/types/aloe/AloePaymentNotificationStatus';
import { AppDispatch } from '@/app/store';
import { GoodsDTO } from '@/types/order/GoodsDTO';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { ROUTE_ORDER_CLIENT } from '@/app/routes';
import { ShowcaseMobileForm } from '@/components/order/ShowcaseMobileForm';
import { StickMobileHeader } from '@/components/common/header/StickMobileHeader';
import {
  getAllCategories,
  getAllGoods,
  getGoodsByCategoryName,
  getTotalPages,
  isEndGoodsArray,
} from '@/utils/common-utils';
import {
  getDepartment,
  getPageRequest,
  setPageNumber,
} from '@/services/createOrderSlice';
import { isBlank } from '@/utils/string-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetAllGoodsByCategoriesQuery } from '@/services/api/goodsApiSlice';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import SearchPanelGoods from '@/components/order/SearchPanelGoods';
import ShowcaseCreateForm from '@/components/order/ShowcaseCreateForm';
import TittleOrderForm from '@/components/order/TittleOrderForm';

export const ShowcasePage: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [showPaymentNotification, setPaymentNotification] =
    useState<AloePaymentNotificationStatus | null>(null);

  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const dispatch: AppDispatch = useAppDispatch();
  const currentDepartment = useAppSelector(getDepartment);
  const pageRequest = useAppSelector(getPageRequest);
  const { enqueueSnackbar } = useSnackbar();
  const departmentId = currentDepartment?.id || 0;
  const {
    data: goodsJSON,
    isFetching,
    isLoading,
    isSuccess,
    isError,
  } = useGetAllGoodsByCategoriesQuery(departmentId, {
    skip: departmentId === 0,
  });

  const [currentCategory, setCurrentCategory] = useState<string>();
  const [currentGoods, setCurrentGoods] = useState<GoodsDTO[]>([]);

  const [openSearchPanel, setOpenSearchPanel] = useState<boolean>(false);
  const [valueSearchPanel, setValueSearchPanel] = useState<GoodsDTO | null>(
    null
  );

  const currentCategories = getAllCategories(goodsJSON);
  const allGoodsInCategory = getGoodsByCategoryName(goodsJSON, currentCategory);

  const handleSetCategory = (categoryName: string) => {
    dispatch(setPageNumber(0));
    setCurrentPageSize(pageRequest.pageSize);
    setCurrentCategory(isBlank(categoryName) ? undefined : categoryName);
    setNotEndCurrentGoods(true);
    setValueSearchPanel(null);
    setCurrentGoods(
      getGoodsByCategoryName(goodsJSON, categoryName, {
        pageNumber: 0,
        pageSize: pageRequest.pageSize,
      })
    );
  };

  const handleChangeGoods = (goods: GoodsDTO | null) => {
    setValueSearchPanel(goods);
    if (goods) {
      setCurrentGoods(new Array(goods));
      setCurrentCategory(goods.categoryName);
    } else {
      setCurrentGoods(
        getGoodsByCategoryName(goodsJSON, currentCategory, {
          pageNumber: 0,
          pageSize: pageRequest.pageSize,
        })
      );
      setNotEndCurrentGoods(true);
    }
  };

  const handlePageChange = (page: number) => {
    dispatch(setPageNumber(page - 1));
    setCurrentPageSize(pageRequest.pageSize);
    setCurrentGoods(
      getGoodsByCategoryName(goodsJSON, currentCategory, {
        pageNumber: page - 1,
        pageSize: pageRequest.pageSize,
      })
    );
    setNotEndCurrentGoods(true);
  };

  const [currentPageSize, setCurrentPageSize] = useState<number>(
    pageRequest.pageSize
  );
  const [isNotEndCurrentGoods, setNotEndCurrentGoods] = useState<boolean>(true);
  const handleShowMoreGoods = (pageSize: number) => {
    setCurrentPageSize(currentPageSize + pageSize);
    setCurrentGoods(
      getGoodsByCategoryName(goodsJSON, currentCategory, {
        pageNumber: pageRequest.pageNumber,
        pageSize: currentPageSize + pageSize,
        oldPageSize: currentPageSize,
      })
    );
    setNotEndCurrentGoods(
      isEndGoodsArray(allGoodsInCategory, {
        pageNumber: pageRequest.pageNumber,
        pageSize: currentPageSize + pageSize,
        oldPageSize: currentPageSize,
      })
    );
  };

  const isNotEndGoodsTable =
    pageRequest.pageNumber !==
    getTotalPages(allGoodsInCategory, pageRequest.pageSize) - 1;

  useEffect(() => {
    if (isSuccess) {
      if (isMobile) {
        setCurrentCategory(undefined);
      } else {
        setCurrentCategory(currentCategories[0]);
      }
      setCurrentGoods(
        getGoodsByCategoryName(goodsJSON, currentCategories[0], {
          pageNumber: 0,
          pageSize: pageRequest.pageSize,
        })
      );
    }
  }, [isSuccess, isMobile]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (showPaymentNotification) {
      setPaymentNotification(null);
    }
    if (queryParams.has('payment')) {
      setPaymentNotification(
        queryParams.get('payment') as AloePaymentNotificationStatus
      );
      if (queryParams.has('payment')) {
        queryParams.delete('payment');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
  }, [showPaymentNotification, history]);

  //stickyHeader listener
  const mainDiv = document.getElementById('app');
  const [showStickMobileHeader, setShowStickMobileHeader] =
    useState<boolean>(false);
  const searchPanelRef = useRef<HTMLDivElement>(null);
  const handleShowcaseScroll = useCallback(() => {
    if (searchPanelRef && searchPanelRef.current) {
      if (searchPanelRef.current.getBoundingClientRect().bottom <= 0) {
        setShowStickMobileHeader(true);
      } else if (showStickMobileHeader) {
        setShowStickMobileHeader(false);
      }
    }
  }, [showStickMobileHeader]);
  useEffect(() => {
    mainDiv?.addEventListener('scroll', handleShowcaseScroll);
  }, [handleShowcaseScroll]);

  useEffect(() => {
    if (typeof currentCategory === 'undefined' || currentCategory === '') {
      if (mainDiv) {
        mainDiv.scrollTop = 0;
      }
    }
  }, [currentCategory]);
  //---------------------------------------------------------------------------
  return (
    <Grid
      container={true}
      direction={'column'}
      sx={{ height: '100%' }}
      wrap={'nowrap'}>
      <Grid item={true} paddingBottom={4.5} paddingTop={1.5}>
        <TittleOrderForm
          tittle={'Шаг 2 из 2: Добавьте товары в корзину'}
          mobileTittle={'2/2: Добавьте товары в корзину'}
          isMobile={isMobile}
          labelPath={'Шаг 1'}
          routePath={ROUTE_ORDER_CLIENT}
        />
      </Grid>
      <Grid item={true} mb={3.5}>
        <SearchPanelGoods
          goods={getAllGoods(goodsJSON)}
          value={valueSearchPanel}
          isFetching={isFetching}
          open={openSearchPanel}
          setOpen={setOpenSearchPanel}
          handleChangeGoods={handleChangeGoods}
          disabled={showStickMobileHeader}
        />
      </Grid>
      {isMobile && (
        <Grid item={true}>
          <StickMobileHeader
            goodsDTO={getAllGoods(goodsJSON)}
            valueSearchPanel={valueSearchPanel}
            openSearchPanel={openSearchPanel}
            setOpenSearchPanel={setOpenSearchPanel}
            handleChangeGoods={handleChangeGoods}
            isFetching={isFetching}
            showHeader={showStickMobileHeader}
            handleMainButtonClick={() => {
              setShowStickMobileHeader(false);
              handleSetCategory('');
            }}
          />
        </Grid>
      )}
      <Grid item={true} xs={true} paddingBottom={isMobile ? 0 : 8.5}>
        {isMobile ? (
          <ShowcaseMobileForm
            goodsJSON={goodsJSON}
            loading={isFetching || isLoading}
            handleSetCategory={handleSetCategory}
            currentCategory={currentCategory}
            currentGoods={currentGoods}
            valueSearchPanel={valueSearchPanel}
            isCanShowMore={isNotEndGoodsTable && isNotEndCurrentGoods}
            handleShowMoreGoods={handleShowMoreGoods}
            navigationRef={searchPanelRef}
          />
        ) : (
          <ShowcaseCreateForm
            currentCategories={currentCategories}
            loading={isFetching || isLoading}
            handleSetCategory={handleSetCategory}
            currentCategory={currentCategory}
            currentGoods={currentGoods}
            valueSearchPanel={valueSearchPanel}
            isCanShowMore={isNotEndGoodsTable && isNotEndCurrentGoods}
            handleShowMoreGoods={handleShowMoreGoods}
          />
        )}
      </Grid>
      {isError &&
        enqueueSnackbar('Ошибка загрузки данных с сервера', {
          variant: 'error',
        })}
      {showPaymentNotification &&
        enqueueSnackbar(
          showPaymentNotification === AloePaymentNotificationStatus.SUCCESS
            ? 'Заказ успешно оплачен'
            : 'Ошибка оплаты',
          {
            variant:
              showPaymentNotification === AloePaymentNotificationStatus.SUCCESS
                ? 'success'
                : 'error',
          }
        )}
    </Grid>
  );
};
