import { ClientInfoItem } from '@/components/order/success/ClientInfoItem';
import { Grid } from '@mui/material';
import { OrderDTO } from '@/types/order/OrderDTO';
import React, { FC } from 'react';

interface Props {
  order: OrderDTO;
}

export const OrderClientInfo: FC<Props> = (props: Props) => {
  const { order } = props;
  return (
    <Grid container={true} direction={'column'} rowSpacing={2}>
      <Grid item={true}>
        <ClientInfoItem tittle={'Учреждение'} text={order?.colony} />
      </Grid>
      <Grid item={true}>
        <ClientInfoItem
          tittle={'Адресат'}
          text={
            order?.prisonerMiddleName +
            ' ' +
            order?.prisonerFirstName +
            ' ' +
            order?.prisonerLastName +
            ', ' +
            order?.prisonerBirthDate
          }
        />
      </Grid>
      <Grid item={true}>
        <ClientInfoItem tittle={'Отправитель'} text={order?.senderName} />
      </Grid>
      <Grid item={true}>
        <ClientInfoItem
          tittle={'Электронная почта'}
          text={order?.senderEmail}
        />
      </Grid>
      <Grid item={true}>
        <ClientInfoItem
          tittle={'Телефон'}
          text={order?.senderPhone}
          isPhoneNumber={true}
        />
      </Grid>
    </Grid>
  );
};
