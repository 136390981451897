import { Button, Grid, Theme, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { FC } from 'react';

interface Props {
  categoryName: string;
  handleSetCategory: (categoryName: string) => void;
}

export const CategoriesTabMobile: FC<Props> = (props: Props) => {
  const { categoryName, handleSetCategory } = props;
  return (
    <Button
      variant={'text'}
      fullWidth={true}
      disableRipple={true}
      onClick={() => handleSetCategory(categoryName)}
      sx={(theme: Theme) => ({
        borderTop: '1px solid ' + theme.colors.tableBorder,
        padding: '16px 0',
        borderRadius: 0,
        color: theme.colors.textColor,
        '&:hover': {
          color: theme.colors.textColor,
        },
      })}>
      <Grid
        container={true}
        justifyContent={'space-between'}
        alignItems={'center'}
        wrap={'nowrap'}>
        <Grid item={true} pr={1}>
          <Typography variant={'h5'} align={'left'}>
            {categoryName}
          </Typography>
        </Grid>
        <Grid item={true}>
          <ChevronRightIcon
            color={'primary'}
            fontSize={'small'}
            sx={{ display: 'block' }}
          />
        </Grid>
      </Grid>
    </Button>
  );
};
