import { InfoIcon } from '@/app/icons/InfoIcon';
import { Theme, Tooltip } from '@mui/material';
import React, { FC } from 'react';

export interface MaxCountInfoProps {
  maxCount: number;
  isBasket?: boolean;
}

export const MaxCountInfo: FC<MaxCountInfoProps> = (
  props: MaxCountInfoProps
) => {
  const { maxCount, isBasket } = props;
  return (
    <Tooltip
      title={`Максимальное количество в заказе не более ${maxCount} шт.`}
      disableInteractive={true}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: '180px',
          },
        },
      }}>
      <div>
        <InfoIcon
          sx={(theme: Theme) => ({
            display: 'block',
            width: isBasket ? '16px' : '22px',
            height: isBasket ? '16px' : '22px',
            color: theme.colors.orange,
            ':hover': {
              color: theme.colors.orangeLight,
              cursor: 'pointer',
            },
          })}
        />
      </div>
    </Tooltip>
  );
};
