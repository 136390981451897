import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const FsinLetterIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{
      width: '24px',
      height: '24px',
      display: 'flex',
      fill: 'none',
    }}
    {...props}>
    <rect x={'2.5'} y={'5.5'} width={'19'} height={'13'} rx={'1.5'} />
    <path
      d={
        'M2.5 6.5L11.4353 12.6136C11.7757 12.8466 12.2243 12.8466 12.5647 12.6136L21.5 6.5'
      }
    />
    <path
      d={
        'M18.3261 21C16.5368 22.2601 14.3548 23 12 23C9.64517 23 7.46318 22.2601 5.67383 21M18.3261 3C16.5368 1.73994 14.3548 1 12 1C9.64517 1 7.46318 1.73994 5.67383 3'
      }
      strokeLinecap={'round'}
    />
  </SvgIcon>
);
