import { AuthorDTO } from '@/types/AuthorDTO';
import { BasketDTO } from '@/types/order/BasketDTO';
import { BasketDelivery } from '@/components/basket/BasketDelivery';
import { BasketPay } from '@/components/basket/BasketPay';
import { BasketProduct } from '@/components/basket/BasketProduct';
import {
  ClickAwayListener,
  Drawer,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { CloseIcon } from '@/app/icons/CloseIcon';
import { CreatedOrderDTO } from '@/types/order/CreatedOrderDTO';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { GoodsBasket } from '@/types/order/GoodsBasket';
import {
  MESSAGE_BASKET_ERROR,
  MESSAGE_FETCH_ERROR,
} from '@/utils/validation-utils';
import { SenderDTO } from '@/types/SenderDTO';
import {
  getAuthor,
  getDepartment,
  getSender,
  resetGoodsBasket,
  setAllGoodsBasket,
  setDepartment,
} from '@/services/createOrderSlice';
import { getOrderModerationRoute, getOrderSuccessRoute } from '@/app/routes';
import { handleAloePayment } from '@/utils/payment-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useCheckOrdersModerationMutation } from '@/services/api/regionsApiSlice';
import { useCreateOrderMutation } from '@/services/api/orderApiSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import { usePayOrderMutation } from '@/services/api/paymentApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback } from 'react';

interface Props {
  basket: BasketDTO;
  open: boolean;
  onClose: () => void;
}

export const BasketDrawer: FC<Props> = (props: Props) => {
  const { basket, open, onClose } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const author: AuthorDTO | null = useAppSelector(getAuthor);
  const sender: SenderDTO | null = useAppSelector(getSender);
  const department: DepartmentDTO | null = useAppSelector(getDepartment);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [createOrder, { isLoading: isValidLoading }] = useCreateOrderMutation();
  const [checkOrdersModeration, { isLoading: isModerationLoading }] =
    useCheckOrdersModerationMutation();
  const [payOrder, { isLoading: isPayOrderLoading }] = usePayOrderMutation();

  //TODO: make handleReCaptchaVerify like custom hook
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    return await executeRecaptcha('payAction');
  }, [executeRecaptcha]);

  const handleCheckOrdersModerationEnabled = async (
    departmentId: number,
    departmentOrdersModerationEnabled: boolean,
    handleSubmit: () => void
  ) => {
    if (department) {
      await checkOrdersModeration(departmentId)
        .unwrap()
        .then((ordersModerationEnabled: boolean) => {
          if (ordersModerationEnabled == departmentOrdersModerationEnabled) {
            handleSubmit();
          } else {
            dispatch(
              setDepartment({
                department: {
                  ...department,
                  enableOrdersModeration: ordersModerationEnabled,
                },
              })
            );
            enqueueSnackbar('Изменились параметры отправки заказов', {
              variant: 'warning',
            });
          }
        })
        .catch(() => {
          enqueueSnackbar('Ошибка загрузки данных с сервера', {
            variant: 'error',
          });
        });
    }
  };

  const handlePaySubmit = async (): Promise<void> => {
    if (!author || !sender || !department) {
      return;
    }
    const token: string | undefined = await handleReCaptchaVerify();
    await handleCheckOrdersModerationEnabled(
      department.id,
      department.enableOrdersModeration,
      async () => {
        await createOrder({
          departmentId: department.id,
          sender: sender,
          author: author,
          goods: basket.goodsBasket,
          token: token,
        })
          .unwrap()
          .then((response: CreatedOrderDTO): void => {
            const haveError: GoodsBasket | undefined =
              response.goodsBasket.find((item: GoodsBasket) => !item.isStoke);
            if (haveError) {
              dispatch(setAllGoodsBasket(response.goodsBasket));
              enqueueSnackbar(MESSAGE_BASKET_ERROR, { variant: 'error' });
            } else {
              if (department.enableOrdersModeration) {
                dispatch(resetGoodsBasket());
                history.push(getOrderModerationRoute(response.uuid));
              } else {
                handleAloePayment(
                  response.uuid,
                  payOrder,
                  () => {
                    dispatch(resetGoodsBasket());
                    history.push(getOrderSuccessRoute(response.uuid));
                  },
                  enqueueSnackbar,
                  dispatch,
                  history
                );
              }
            }
          })
          .catch((e: { status: number; data: ErrorDTO }) => {
            enqueueSnackbar(
              e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR,
              { variant: 'error' }
            );
          });
      }
    );
  };

  return (
    <ClickAwayListener
      mouseEvent={'onMouseDown'}
      touchEvent={'onTouchStart'}
      onClickAway={() => open && onClose()}>
      <Drawer
        variant={'persistent'}
        open={open}
        anchor={'right'}
        hideBackdrop={false}>
        <Grid
          container={true}
          direction={'column'}
          sx={{ height: '100%' }}
          wrap={'nowrap'}>
          <Grid
            item={true}
            xs={true}
            sx={(theme: Theme) => ({
              padding: theme.spacing(5, 0, 3.75),
              flexGrow: 1,
              overflow: 'auto',
            })}>
            <Grid
              container={true}
              direction={'column'}
              height={'100%'}
              wrap={'nowrap'}>
              <Grid item={true} pb={4} pr={4.5} pl={4.5}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true}>
                    <Typography variant={'h2'} lineHeight={'28px'}>
                      {'Ваша корзина'}
                    </Typography>
                  </Grid>
                  <Grid item={true} alignItems={'center'}>
                    <IconButton
                      onClick={onClose}
                      sx={(theme: Theme) => ({
                        marginTop: theme.spacing(-1),
                        marginRight: theme.spacing(-1),
                      })}>
                      <CloseIcon
                        sx={{
                          color: (theme: Theme) => theme.colors.grayText,
                          width: (theme: Theme) => theme.spacing(3),
                          height: (theme: Theme) => theme.spacing(3),
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={true} mb={3}>
                <Grid container={true} direction={'column'}>
                  {basket.goodsBasket.map((product, index, goods) => (
                    <Grid item={true} key={index}>
                      <BasketProduct
                        product={product}
                        nextProductOutStoke={
                          index === goods.length ? false : goods[index].isStoke
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {author && sender && department && (
                <Grid item={true} pr={4.5} pl={4.5} pb={3.75}>
                  <BasketDelivery
                    author={author}
                    sender={sender}
                    department={department}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {department && (
            <Grid item={true}>
              <BasketPay
                basketCost={basket.currency}
                deliveryCost={department.deliveryCost}
                handlePaySubmit={handlePaySubmit}
                handleOrderSubmit={handlePaySubmit}
                isLoading={
                  isValidLoading || isModerationLoading || isPayOrderLoading
                }
                enableOrdersModeration={department.enableOrdersModeration}
              />
            </Grid>
          )}
        </Grid>
      </Drawer>
    </ClickAwayListener>
  );
};
