import { AlarmIcon } from '@/app/icons/AlarmIcon';
import { Grid, Theme, Typography } from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MastercardIcon } from '@/app/icons/payment/MastercardIcon';
import { MirIcon } from '@/app/icons/payment/MirIcon';
import { TypographyConditions } from '@/components/common/TypographyConditions';
import { VisaIcon } from '@/app/icons/payment/VisaIcon';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  currency: number;
  deliveryCost: number;
  isLoading?: boolean;
  handlePaySubmit: () => Promise<void>;
  handleOrderSubmit: () => Promise<void>;
  enableOrdersModeration?: boolean;
}

export const BasketActionsYaBrowser: FC<Props> = (props: Props) => {
  const {
    currency,
    deliveryCost,
    isLoading,
    handlePaySubmit,
    handleOrderSubmit,
    enableOrdersModeration,
  } = props;
  return (
    <Grid
      direction={'column'}
      wrap={'nowrap'}
      sx={(theme: Theme) => ({
        margin: theme.spacing(0, 2),
      })}>
      <Grid item={true} pb={1.5}>
        <Grid
          container={true}
          direction={'column'}
          wrap={'nowrap'}
          sx={(theme: Theme) => ({
            borderRadius: theme.spacing(2),
            backgroundColor: theme.colors.secondaryGrayBackground,
          })}>
          <Grid item={true} p={'12px 16px'} pb={0}>
            <Typography variant={'h2'}>
              <Grid container={true} justifyContent={'space-between'}>
                <Grid item={true}>{'Итог'}</Grid>
                <Grid item={true}>
                  {formatCurrency(currency + deliveryCost)}
                </Grid>
              </Grid>
            </Typography>
          </Grid>
          <Grid item={true} p={'14px 16px'}>
            <Grid container={true} wrap={'nowrap'} alignItems={'center'}>
              <Grid item={true} pr={3}>
                <AlarmIcon style={{ display: 'block' }} />
              </Grid>
              <Grid item={true} sx={{ maxWidth: '260px' }}>
                {enableOrdersModeration ? (
                  <Typography
                    variant={'body2'}
                    fontSize={'12px'}
                    lineHeight={'14px'}
                    color={(theme: Theme) => theme.colors.grayTextDark}>
                    {'Заказ будет отправлен врачу учреждения на обработку. '}
                    {
                      'Если что-то из товаров нельзя доставить, врач уберёт это из заказа.'
                    }
                    <br />
                    {'Оплата заказа возможна после обработки.'}
                  </Typography>
                ) : (
                  <Typography
                    variant={'body2'}
                    fontSize={'12px'}
                    lineHeight={'14px'}
                    color={(theme: Theme) => theme.colors.grayTextDark}>
                    {'После нажатия на кнопку «Оплатить» у вас будет '}
                    <strong>{'6 минут'}</strong>
                    {' на оплату заказа'}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} pb={2}>
            <Grid container={true} justifyContent={'center'}>
              <Grid item={true} xs={10}>
                {enableOrdersModeration ? (
                  <LoadingButton
                    fullWidth={true}
                    color={'primary'}
                    variant={'contained'}
                    disabled={isLoading}
                    onClick={handleOrderSubmit}>
                    {'Оформить заказ'}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    fullWidth={true}
                    color={'primary'}
                    variant={'contained'}
                    disabled={isLoading}
                    onClick={handlePaySubmit}>
                    {'Оплатить'}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} pb={5.5}>
        <Grid container={true} columnSpacing={3.75} wrap={'nowrap'}>
          {!enableOrdersModeration && (
            <Grid item={true}>
              <Grid container={true} columnSpacing={1.5} wrap={'nowrap'}>
                <Grid item={true}>
                  <VisaIcon />
                </Grid>
                <Grid item={true}>
                  <MastercardIcon />
                </Grid>
                <Grid item={true}>
                  <MirIcon />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item={true}>
            {enableOrdersModeration ? (
              <TypographyConditions buttonName={'Оформить заказ'} />
            ) : (
              <TypographyConditions buttonName={'Оплатить'} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
