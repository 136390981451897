import * as Yup from 'yup';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Formik, FormikHelpers, FormikProps, getIn } from 'formik';
import { Grid, MenuItem, Theme, Typography } from '@mui/material';
import { LetterAlert } from '@/components/common/LetterAlert';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MESSAGE_DEPARTMENT_INVALID_PROPERTIES } from '@/utils/validation-utils';
import { MobileBar } from '@/components/common/MobileBar';
import { OutlinedSelect } from '@/components/common/field/OutlinedSelect';
import { ROUTE_ORDER_CLIENT } from '@/app/routes';
import { RegionDTO } from '@/types/RegionDTO';
import { RegionField } from '@/components/order/RegionField';
import { departmentDTOSchema } from '@/validation/departmentDTOSchema';
import {
  getDepartment,
  getRegion,
  resetGoodsBasket,
  setRegionAndDepartment,
} from '@/services/createOrderSlice';
import { regionDTOSchema } from '@/validation/regionDTOSchema';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  useCheckValidDepartmentMutation,
  useGetRegionsQuery,
} from '@/services/api/regionsApiSlice';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  isMobile: boolean;
}

interface FormValues {
  region: RegionDTO | null;
  department: DepartmentDTO | null;
}

const validationSchema = Yup.object({
  region: regionDTOSchema,
  department: departmentDTOSchema,
});

const DepartmentCreateForm: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const {
    data: regions,
    isFetching,
    isLoading: isRegionLoading,
    isError,
    refetch,
  } = useGetRegionsQuery();

  const currentRegion = useAppSelector(getRegion);
  const currentDepartment = useAppSelector(getDepartment);
  const { enqueueSnackbar } = useSnackbar();
  const [checkValidDepartment, { isLoading: isValidLoading }] =
    useCheckValidDepartmentMutation();

  //fix if save incorrect data
  useEffect(() => {
    if (!regionDTOSchema.isValidSync(currentRegion)) {
      refetch();
      dispatch(setRegionAndDepartment({ region: null, department: null }));
    }
  }, [currentRegion, dispatch, refetch]);

  useEffect(() => {
    if (regions) {
      const region: RegionDTO | null =
        regions?.find((region: RegionDTO) => {
          return region.id === currentRegion?.id;
        }) || null;
      const department: DepartmentDTO | null =
        region?.departments?.find((department: DepartmentDTO) => {
          return department.id === currentDepartment?.id;
        }) || null;
      dispatch(setRegionAndDepartment({ region, department }));
    }
  }, [dispatch, currentRegion, currentDepartment, regions]);

  const initialValues: FormValues = {
    region: currentRegion,
    department: currentDepartment,
  };

  const handleSubmit = async (
    value: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    const { setFieldValue } = helpers;
    if (value.department) {
      await checkValidDepartment(value.department.id)
        .unwrap()
        .then((response) => {
          if (response) {
            dispatch(
              setRegionAndDepartment({
                region: value.region,
                department: value.department,
              })
            );
            history.push(ROUTE_ORDER_CLIENT);
          } else {
            const selectDepartment = regions
              ?.find((region) =>
                value.region ? region.id === value.region.id : false
              )
              ?.departments.find(
                (department) => department.id === value.department?.id
              );
            setFieldValue('department', selectDepartment);
            enqueueSnackbar('Ошибка загрузки данных учреждения', {
              variant: 'error',
            });
          }
        })
        .catch(() => {
          enqueueSnackbar('Ошибка загрузки данных с сервера', {
            variant: 'error',
          });
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {(formikProps: FormikProps<FormValues>) => {
        const {
          values,
          errors,
          touched,
          submitForm,
          setFieldValue,
          setFieldTouched,
        } = formikProps;
        const handleSetFieldValue = (field: string, value: unknown) => {
          setFieldTouched(field, true, false);
          setFieldValue(field, value, true);
        };

        return (
          <Grid container={true} direction={'column'}>
            <Grid item={true} paddingBottom={isMobile ? 6 : 9}>
              <Typography
                variant={isMobile ? 'body2' : 'body1'}
                color={(theme: Theme) =>
                  isMobile ? theme.colors.textColor : theme.colors.grayText
                }>
                {'Чтобы сделать заказ заполните, пожалуйста, форму.'}
              </Typography>
            </Grid>
            <Grid item={true} paddingBottom={6}>
              <RegionField
                open={open}
                loading={isFetching}
                options={regions}
                value={values.region}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onChange={(event, value) => {
                  handleSetFieldValue('region', value);
                  setFieldValue('department', null);
                  setFieldTouched('department', false);
                  dispatch(resetGoodsBasket());
                }}
                error={touched.region && Boolean(errors.region)}
                helperText={
                  touched.region && Boolean(errors.region)
                    ? getIn(errors.region, 'departments')
                      ? Array.isArray(getIn(errors.region, 'departments')) &&
                        getIn(errors.region, 'departments').length > 1
                        ? 'Ошибка загрузки региона.'
                        : JSON.stringify(getIn(errors.region, 'departments'))
                      : errors.region
                    : ''
                }
              />
            </Grid>
            <Grid item={true} paddingBottom={isMobile ? 3 : 6}>
              <OutlinedSelect
                size={'medium'}
                displayEmpty={true}
                onChange={(event) => {
                  const selectDepartment = values.region?.departments.find(
                    (department) =>
                      department.id === (event.target.value as number)
                  );
                  handleSetFieldValue('department', selectDepartment);
                }}
                value={values.department ? values.department.id : ''}
                disabled={Boolean(errors.region) || !values.region}
                label={'Выберите учреждение'}
                MenuProps={{ PaperProps: { sx: { marginTop: '3px' } } }}
                helperText={
                  touched.department &&
                  Boolean(errors.department) &&
                  !(Boolean(errors.region) || !values.region)
                    ? errors.department
                    : ''
                }
                error={
                  touched.department &&
                  Boolean(errors.department) &&
                  !(Boolean(errors.region) || !values.region)
                }>
                {values.region?.departments &&
                  values.region.departments.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
              </OutlinedSelect>
            </Grid>
            <Grid
              item={true}
              paddingBottom={isMobile ? 0 : 6}
              xs={isMobile ? 12 : 5}>
              {isMobile ? (
                <MobileBar>
                  <FixedButton
                    color={'primary'}
                    variant={'contained'}
                    onClick={submitForm}
                    disabled={
                      isRegionLoading ||
                      isFetching ||
                      isValidLoading ||
                      !values.department ||
                      !values.department.enableOrdersSending ||
                      !values.department.validProperties
                    }>
                    {'Заказать'}
                  </FixedButton>
                </MobileBar>
              ) : (
                <LoadingButton
                  fullWidth={true}
                  variant={'contained'}
                  isLoading={isRegionLoading || isFetching || isValidLoading}
                  disabled={
                    !values.department ||
                    !values.department.enableOrdersSending ||
                    !values.department.validProperties
                  }
                  onClick={submitForm}>
                  {'Заказать'}
                </LoadingButton>
              )}
            </Grid>
            <Grid item={true}>
              {values.department &&
                (values.department?.validProperties ? (
                  values.department?.message && (
                    <LetterAlert error={!values.department.enableOrdersSending}>
                      {values.department.message}
                    </LetterAlert>
                  )
                ) : (
                  <LetterAlert error={true}>
                    {MESSAGE_DEPARTMENT_INVALID_PROPERTIES}
                  </LetterAlert>
                ))}
              {isError &&
                enqueueSnackbar('Ошибка загрузки данных с сервера', {
                  variant: 'error',
                })}
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default DepartmentCreateForm;
