import { AppDispatch } from '@/app/store';
import { History } from 'history';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { PaymentRequestDTO } from '@/types/aloe/PaymentRequestDTO';
import { PaymentResponseDTO } from '@/types/aloe/PaymentResponseDTO';
import { PaymentResponseStatus } from '@/types/aloe/PaymentResponseStatus';
import {
  getAloePaymentForm,
  getOrderDeclinedRoute,
  getOrderMismatchRoute,
} from '@/app/routes';
import { isIOS } from '@/utils/common-utils';
import { resetGoodsBasket } from '@/services/createOrderSlice';

/**
 * Handles the Aloe payment form by opening it in a new window or redirecting the current window.
 *
 * @param formLink The link to the Aloe payment form.
 * @param onSuccess Callback function to execute after handling the payment form.
 */
export const handleAloePaymentForm = (
  formLink: string,
  onSuccess: () => void
): void => {
  // Fix for Safari (window.open doesn't work in async functions).
  if (isIOS()) {
    // Redirect the current window to the Aloe payment form link.
    window.location.href = getAloePaymentForm(formLink);
  } else {
    // Open a new window and navigate to the Aloe payment form link.
    const newWindow: Window | null = window.open();
    if (newWindow) {
      newWindow.location = getAloePaymentForm(formLink);
    }
    // Execute the onSuccess callback after handling the payment form.
    onSuccess();
  }
};

/**
 * Handles the Aloe payment process based on the response status.
 *
 * @param orderUUID The UUID of the order to be paid.
 * @param payOrder Function to initiate the payment.
 * @param onSuccess Callback function to execute after successful payment.
 * @param enqueueSnackbar Function to display snackbars.
 * @param dispatch Redux dispatch function.
 * @param history History object for navigation.
 * @returns A Promise that resolves once the payment handling is complete.
 */
export const handleAloePayment = async (
  orderUUID: string,
  payOrder: (request: PaymentRequestDTO) => any,
  onSuccess: () => void,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey,
  dispatch: AppDispatch,
  history: History
): Promise<void> => {
  try {
    if (!orderUUID) {
      return;
    }
    const request: PaymentRequestDTO = {
      uuid: orderUUID,
      silent: false,
    };
    const response: PaymentResponseDTO = await payOrder(request).unwrap();
    switch (response.paymentStatus) {
      case PaymentResponseStatus.NO_ITEMS_IN_STORE: {
        resetBasketAndNavigate(
          dispatch,
          history,
          getOrderDeclinedRoute(orderUUID)
        );
        break;
      }
      case PaymentResponseStatus.NOT_ALL_ITEMS_IN_STORE: {
        resetBasketAndNavigate(
          dispatch,
          history,
          getOrderMismatchRoute(orderUUID)
        );
        break;
      }
      case PaymentResponseStatus.SUCCESS: {
        handleAloePaymentForm(
          response?.aloePaymentResponse?.form_link,
          onSuccess
        );
        break;
      }
      case PaymentResponseStatus.ERROR: {
        enqueueSnackbar('Ошибка при оплате. Пожалуйста, попробуйте еще раз.', {
          variant: 'error',
        });
        break;
      }
      default: {
        console.log('Unexpected payment status:', response);
        enqueueSnackbar(
          'Неожиданный статус оплаты. Пожалуйста, попробуйте еще раз.',
          { variant: 'error' }
        );
        break;
      }
    }
  } catch (error) {
    console.error('Error during payment:', error);
    enqueueSnackbar('Ошибка при оплате. Пожалуйста, попробуйте еще раз.', {
      variant: 'error',
    });
  }
};

/**
 * Resets the goods basket and navigates to the specified route.
 *
 * @param dispatch Redux dispatch function.
 * @param history History object for navigation.
 * @param route The route to navigate to after resetting the basket.
 */
export const resetBasketAndNavigate = (
  dispatch: AppDispatch,
  history: History,
  route: string
): void => {
  dispatch(resetGoodsBasket());
  history.push(route);
};
