import { BasketDTO } from '@/types/order/BasketDTO';
import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { GoodsBasket } from '@/types/order/GoodsBasket';
import { GoodsDTO } from '@/types/order/GoodsDTO';
import { GoodsItem } from '@/components/order/GoodsItem';
import { SHOW_MORE_SIZE } from '@/utils/common-utils';
import { getBasket } from '@/services/createOrderSlice';
import { useAppSelector } from '@/app/hooks';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { FC } from 'react';

interface Props {
  goods: GoodsDTO[];
  loading: boolean;
  skeletonSize?: number;
  isMobile?: boolean;
  isCanShowMore: boolean;
  handleShowMoreGoods: (showSize: number) => void;
}

const GoodsTable: FC<Props> = (props: Props) => {
  const {
    goods,
    loading,
    skeletonSize,
    isMobile,
    isCanShowMore,
    handleShowMoreGoods,
  } = props;
  const basket: BasketDTO | null = useAppSelector(getBasket);
  const isProductPresentInBasket = (
    goods: GoodsDTO,
    goodsBasket?: GoodsBasket[]
  ): GoodsBasket | undefined => {
    if (goodsBasket) {
      const index = goodsBasket.findIndex(
        (product) => product.goods.id === goods.id
      );
      if (index > -1) {
        return goodsBasket[index];
      } else return undefined;
    } else return undefined;
  };

  return (
    <InfiniteScroll
      dataLength={goods.length}
      next={() => handleShowMoreGoods(SHOW_MORE_SIZE)}
      hasMore={isCanShowMore}
      loader={<CircularProgress color={'inherit'} size={24} />}
      scrollableTarget={'app'}
      style={{ overflow: 'inherit' }}>
      <TableContainer>
        <Table>
          <TableBody>
            {(loading
              ? Array.from(new Array(skeletonSize ? skeletonSize : 20))
              : goods
            ).map((item: GoodsDTO, index: number) => (
              <GoodsItem
                loading={loading}
                index={index}
                item={item}
                basket={basket}
                isProductPresentInBasket={isProductPresentInBasket}
                isMobile={isMobile}
                key={loading ? index : item.id}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </InfiniteScroll>
  );
};

export default GoodsTable;
