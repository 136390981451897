import { config } from '@/app/config';

export const ROUTE_HOME = '/';
export const ROUTE_ORDER = '/order';
export const ROUTE_ORDER_CLIENT = '/order/client';
export const ROUTE_ORDER_SHOWCASE = '/order/showcase';
export const ROUTE_ORDER_SUCCESS_UUID = '/order/success/:uuid';
export const ROUTE_ORDER_MODERATION_UUID = '/order/moderation/:uuid';
export const ROUTE_ORDER_PAYMENT_UUID = '/order/payment/:uuid';
export const ROUTE_ORDER_MODERATED_UUID = '/order/moderated/:uuid';
export const ROUTE_ORDER_DECLINED_UUID = '/order/declined/:uuid';
export const ROUTE_ORDER_MISMATCH_UUID = '/order/mismatch/:uuid';
export const ROUTE_INFO = '/info';
export const ROUTE_FEEDBACK = '/feedback';
export const ROUTE_ALOE_PAYMENT_FORM = `${config.baseUrl}/payment/aloe-payment-form?formLink=:formLink`;

export const getAloePaymentForm = (formLink: string): string => {
  return ROUTE_ALOE_PAYMENT_FORM.replace(':formLink', formLink);
};

export const getOrderSuccessRoute = (uuid: string): string => {
  return ROUTE_ORDER_SUCCESS_UUID.replace(':uuid', uuid);
};

export const getOrderModerationRoute = (uuid: string): string => {
  return ROUTE_ORDER_MODERATION_UUID.replace(':uuid', uuid);
};

export const getOrderPaymentRoute = (uuid: string): string => {
  return ROUTE_ORDER_PAYMENT_UUID.replace(':uuid', uuid);
};

export const getOrderModeratedRoute = (uuid: string): string => {
  return ROUTE_ORDER_MODERATED_UUID.replace(':uuid', uuid);
};

export const getOrderDeclinedRoute = (uuid: string): string => {
  return ROUTE_ORDER_DECLINED_UUID.replace(':uuid', uuid);
};

export const getOrderMismatchRoute = (uuid: string): string => {
  return ROUTE_ORDER_MISMATCH_UUID.replace(':uuid', uuid);
};
