import { CloseIcon } from '@/app/icons/CloseIcon';
import { DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';

interface Props {
  label: ReactNode;
  sxTittle?: SxProps<Theme>;
  onClose: () => void;
}

export const DrugstoreDialogTitle: FC<Props> = (props: Props) => {
  const { label, onClose, sxTittle } = props;
  return (
    <DialogTitle sx={sxTittle}>
      <Grid container={true} justifyContent={'space-between'} wrap={'nowrap'}>
        <Grid item={true}>
          <Typography variant={'h1'}>{label}</Typography>
        </Grid>
        <Grid item={true}>
          <IconButton
            disableRipple={true}
            onClick={onClose}
            sx={(theme: Theme) => ({
              marginTop: theme.spacing(-1),
              marginRight: theme.spacing(-1),
            })}>
            <CloseIcon color={'primary'} />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};
