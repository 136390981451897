import { CreateFeedbackDTO } from '@/types/help/CreateFeedbackDTO';
import { FeedbackDTO } from '@/types/help/FeedbackDTO';
import { FeedbackTopicDTO } from '@/types/help/FeedbackTopicDTO';
import { HelpContentDTO } from '@/types/help/HelpContentDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const helpApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    sendFeedback: build.mutation<FeedbackDTO, CreateFeedbackDTO>({
      query: (dto: CreateFeedbackDTO) => ({
        url: '/feedback',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Re-Captcha-Token': dto.token,
        },
        body: dto,
      }),
    }),
    getFeedbackTopics: build.query<FeedbackTopicDTO[], void>({
      query: () => ({ url: `/feedback/topics` }),
    }),
    getInfoPageContent: build.query<HelpContentDTO, void>({
      query: () => ({ url: `/static/info` }),
    }),
  }),
});

export const {
  useSendFeedbackMutation,
  useGetFeedbackTopicsQuery,
  useGetInfoPageContentQuery,
} = helpApiSlice;
