import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const MirIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 46 14'}
    sx={{ width: '46px', height: '14px', display: 'flex' }}
    {...props}>
    <g style={{ mixBlendMode: 'luminosity' }} opacity={'0.5'}>
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M7.30875 1.51754C7.67039 1.51534 8.74517 1.41714 9.20043 2.9759C9.5071 4.0259 9.9956 5.74585 10.6659 8.13575H10.9389C11.6578 5.61615 12.1516 3.8962 12.4204 2.9759C12.8804 1.4009 14.0304 1.51757 14.4904 1.51757L18.0394 1.51757V12.7175H14.4222V6.11721H14.1796L12.1632 12.7175H9.44168L7.42524 6.11232H7.18268V12.7175H3.56543V1.51757L7.30875 1.51754ZM23.2337 1.51757V8.12282H23.5223L25.9751 2.69137C26.4512 1.6105 27.466 1.51757 27.466 1.51757H30.9664V12.7176H27.2737V6.11232H26.9851L24.5804 11.5438C24.1042 12.6198 23.0414 12.7176 23.0414 12.7176H19.5409V1.51757H23.2337ZM43.7304 6.83988C43.2153 8.32073 41.5976 9.38126 39.8067 9.38126H35.9341V12.7176H32.4225V6.83988H43.7304Z'
        }
        fill={'#525252'}
      />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M39.976 1.51562H32.2383C32.4224 4.00912 34.5391 6.14372 36.7303 6.14372H43.9744C44.3924 4.07133 42.9533 1.51562 39.976 1.51562Z'
        }
        fill={'url(#paint0_linear_1579_92240)'}
      />
    </g>
    <defs>
      <linearGradient
        id={'paint0_linear_1579_92240'}
        x1={'44.0454'}
        y1={'4.4269'}
        x2={'32.2383'}
        y2={'4.4269'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'#525252'} />
        <stop offset={'1'} stopColor={'#838383'} />
      </linearGradient>
    </defs>
  </SvgIcon>
);
