export enum OrderStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  CONFIRMED = 'CONFIRMED',
  IN_PROCESS = 'IN_PROCESS',
  DECLINED = 'DECLINED',
  CITY_COURT = 'CITY_COURT',
  PROCESSED = 'PROCESSED',
  RETURNED = 'RETURNED',
  READY_TO_MODERATION = 'READY_TO_MODERATION',
  ON_MODERATION = 'ON_MODERATION',
  MODERATED = 'MODERATED',
}
