import { BasketGoodsCounter } from '@/components/basket/BasketGoodsCounter';
import { Divider, Grid, IconButton, Theme, Typography } from '@mui/material';
import { GoodsBasket } from '@/types/order/GoodsBasket';
import { MESSAGE_PRODUCT_ERROR } from '@/utils/validation-utils';
import { TrashIcon } from '@/app/icons/TrashIcon';
import { clearGoodsBasket } from '@/services/createOrderSlice';
import { formatCurrency } from '@/utils/string-utils';
import { useAppDispatch } from '@/app/hooks';
import React, { FC } from 'react';

interface Props {
  product: GoodsBasket;
  nextProductOutStoke?: boolean;
}

export const BasketProductMobile: FC<Props> = (props: Props) => {
  const { product, nextProductOutStoke } = props;
  const dispatch = useAppDispatch();
  const handleClearProduct = () => {
    dispatch(clearGoodsBasket(product.goods));
  };

  console.log(`LOG ${product.goods.name} isStoke : `, product.isStoke);
  console.log(
    `LOG ${product.goods.name} nextProductOutStoke : `,
    nextProductOutStoke
  );
  return (
    <Grid
      container={true}
      direction={'column'}
      sx={(theme: Theme) => ({
        background: product.isStoke
          ? theme.colors.white
          : theme.colors.redBackground,
        padding: theme.spacing(0, 2),
      })}>
      <Grid item={true} pt={1}>
        <Grid container={true} direction={'column'} rowSpacing={1.5}>
          <Grid item={true}>
            <Grid container={true}>
              <Grid item={true} xs={true}>
                <Typography variant={'body2'} mt={product.isStoke ? 0 : 0.25}>
                  {product.goods.name}
                </Typography>
              </Grid>
              <Grid item={true} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={handleClearProduct}
                  sx={(theme: Theme) => ({
                    marginTop: theme.spacing(-0.5),
                    padding: theme.spacing(0.25),
                  })}>
                  <TrashIcon
                    sx={(theme: Theme) => ({
                      width: theme.spacing(2.5),
                      height: theme.spacing(2.5),
                      color: theme.colors.inputBorder,
                    })}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Grid container={true}>
              <Grid item={true}>
                <BasketGoodsCounter product={product} />
              </Grid>
              <Grid item={true} xs={true}>
                {product.isStoke ? (
                  <Typography variant={'h5'} align={'right'}>
                    {formatCurrency(product.goods.price * product.count)}
                  </Typography>
                ) : (
                  <Typography
                    variant={'body2'}
                    align={'right'}
                    color={(theme: Theme) => theme.colors.red}>
                    {MESSAGE_PRODUCT_ERROR}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {product.isStoke && nextProductOutStoke && (
        <Grid item={true} pt={1}>
          <Divider />
        </Grid>
      )}
    </Grid>
  );
};
