import { FooterNavigation } from '@/components/common/footer/FooterNavigation';
import { FooterNavigationMobile } from '@/components/common/footer/FooterNavigationMobile';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTE_FEEDBACK } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { format } from 'date-fns';
import React, { FC } from 'react';

interface Props {
  isMobile?: boolean;
}
export const Footer: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  return (
    <Grid container={true} direction={'column'} wrap={'nowrap'}>
      <Grid item={true} pb={isMobile ? 2 : '6px'}>
        <Typography
          variant={'body2'}
          fontWeight={500}
          align={isMobile ? 'center' : 'left'}
          fontSize={isMobile ? '14px' : '10px'}
          lineHeight={isMobile ? '20px' : '16px'}
          color={(theme: Theme) => theme.colors.secondaryGrayText}>
          {'Другие сервисы'}
        </Typography>
      </Grid>
      <Grid item={true} pb={isMobile ? '30px' : 3}>
        {isMobile ? <FooterNavigationMobile /> : <FooterNavigation />}
      </Grid>
      <Grid item={true}>
        <Grid container={true}>
          <Grid item={true} xs={isMobile ? 12 : 'auto'}>
            <Typography
              variant={'body2'}
              align={'center'}
              whiteSpace={'pre-line'}
              color={(theme: Theme) => theme.colors.grayText}>
              {`© 2021—${format(new Date(), 'yyyy')} ${
                isMobile ? '\n' : ''
              } «ООО Интертелекомсервис»`}
            </Typography>
          </Grid>
          {!isMobile && (
            <Grid item={true} pl={2}>
              <Link to={ROUTE_FEEDBACK}>
                <Typography
                  variant={'body2'}
                  sx={(theme: Theme) => ({
                    color: theme.colors.primary,
                    fontWeight: 400,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  })}>
                  {'Обратная связь'}
                </Typography>
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
