import { Divider, Grid, Theme, Typography } from '@mui/material';
import { InfoIcon } from '@/app/icons/InfoIcon';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MastercardIcon } from '@/app/icons/payment/MastercardIcon';
import { MirIcon } from '@/app/icons/payment/MirIcon';
import { OrderDTO } from '@/types/order/OrderDTO';
import { OrderTotalSumInfo } from '@/components/common/OrderTotalSumInfo';
import { PaymentTimeInfo } from '@/components/common/PaymentTimeInfo';
import { TypographyConditions } from '@/components/common/TypographyConditions';
import { VisaIcon } from '@/app/icons/payment/VisaIcon';
import { calculateOrderTotalSum } from '@/utils/common-utils';
import { getOrderSuccessRoute } from '@/app/routes';
import { handleAloePayment } from '@/utils/payment-utils';
import { resetGoodsBasket } from '@/services/createOrderSlice';
import { useAppDispatch } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import { usePayOrderMutation } from '@/services/api/paymentApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';

interface Props {
  order: OrderDTO;
}

export const OrderMismatchInfo: FC<Props> = (props: Props) => {
  const { order } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const totalSum: number = calculateOrderTotalSum(order);

  const [payOrder, { isLoading: isPayOrderLoading }] = usePayOrderMutation();

  const handleContinue = async (): Promise<void> => {
    if (order?.uuid) {
      const orderUUID: string = order?.uuid;
      await handleAloePayment(
        orderUUID,
        payOrder,
        () => {
          dispatch(resetGoodsBasket());
          history.push(getOrderSuccessRoute(orderUUID));
        },
        enqueueSnackbar,
        dispatch,
        history
      );
    }
  };

  return (
    <Grid
      container={true}
      direction={'column'}
      sx={(theme: Theme) => ({
        borderRadius: theme.spacing(1.5),
        backgroundColor: theme.colors.grayBackground,
      })}>
      <Grid
        item={true}
        sx={(theme: Theme) => ({
          padding: theme.spacing(4, 3, 0),
        })}>
        <OrderTotalSumInfo totalSum={totalSum} />
      </Grid>
      <Grid item={true} pt={3.5} pb={3}>
        <Divider />
      </Grid>
      <Grid item={true}>
        <Grid
          container={true}
          wrap={'nowrap'}
          sx={(theme: Theme) => ({
            padding: theme.spacing(0, 3, 4),
          })}>
          <Grid item={true} pl={0.25} pr={1}>
            <InfoIcon
              sx={(theme: Theme) => ({
                color: theme.colors.orange,
              })}
            />
          </Grid>
          <Grid item={true}>
            <Grid container={true} direction={'column'}>
              <Grid item={true} pb={1}>
                <Typography
                  variant={'h4'}
                  sx={(theme: Theme) => ({
                    color: theme.colors.orange,
                  })}>
                  {'Оплата заказа не выполнена'}
                </Typography>
              </Grid>
              <Grid item={true} pb={1}>
                <Typography variant={'body1'}>
                  {
                    'К сожалению, некоторых товаров из вашего заказа временно нет в наличии.'
                  }
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant={'body1'}>
                  {
                    'Вы можете продолжить оформление заказа и оплатить только те товары, которые доступны на данный момент.'
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item={true}
        sx={(theme: Theme) => ({
          padding: theme.spacing(0, 3, 4),
        })}>
        <PaymentTimeInfo />
      </Grid>
      <Grid item={true} pb={3.5} pr={3} pl={3}>
        <LoadingButton
          fullWidth={true}
          variant={'contained'}
          isLoading={isPayOrderLoading}
          onClick={handleContinue}>
          {'Оплатить'}
        </LoadingButton>
      </Grid>
      <Grid item={true} pb={3.5} pr={3} pl={3}>
        <Grid container={true} direction={'row'} spacing={3}>
          <Grid item={true}>
            <Grid container={true} spacing={1.5}>
              <Grid item={true}>
                <VisaIcon />
              </Grid>
              <Grid item={true}>
                <MastercardIcon />
              </Grid>
              <Grid item={true}>
                <MirIcon />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <TypographyConditions
              buttonName={'Оплатить'}
              conditionsText={
                'Нажимая кнопку «Оплатить», я соглашаюсь и принимаю\n'
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
