import { Footer } from '@/components/common/footer/Footer';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { MESSAGE_ORDER_NOT_FOUND } from '@/utils/validation-utils';
import { OrderClientInfo } from '@/components/order/success/OrderClientInfo';
import { OrderItemDTO } from '@/types/order/OrderItemDTO';
import { OrderPaymentBlock } from '@/components/order/success/OrderPaymentBlock';
import { OrderStatus } from '@/types/OrderStatus';
import { OrderTableInfo } from '@/components/order/success/OrderTableInfo';
import { ROUTE_HOME, getOrderModeratedRoute } from '@/app/routes';
import { calculateOrderTotalSum } from '@/utils/common-utils';
import { handleAloePayment } from '@/utils/payment-utils';
import { resetGoodsBasket } from '@/services/createOrderSlice';
import { useAppDispatch } from '@/app/hooks';
import { useGetOrderByUuidQuery } from '@/services/api/orderApiSlice';
import { useHistory, useParams } from 'react-router-dom';
import { usePayOrderMutation } from '@/services/api/paymentApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useEffect } from 'react';

export const OrderPaymentPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data: order,
    error,
    isError,
    isFetching,
    isSuccess,
  } = useGetOrderByUuidQuery(uuid);
  const [payOrder, { isLoading: isPayOrderLoading }] = usePayOrderMutation();

  const totalSum: number = calculateOrderTotalSum(order);

  useEffect(() => {
    if (!uuid) {
      history.replace(ROUTE_HOME);
    }
    if (isSuccess && order && !(order.status === OrderStatus.MODERATED)) {
      history.replace(ROUTE_HOME);
    }
    if (!!error && 'data' in error && error.status == 404) {
      history.replace(ROUTE_HOME);
      enqueueSnackbar(MESSAGE_ORDER_NOT_FOUND, {
        variant: 'error',
      });
    }
  }, [
    history,
    isSuccess,
    isFetching,
    isError,
    order,
    enqueueSnackbar,
    error,
    uuid,
  ]);

  const handlePaySubmit = async (): Promise<void> => {
    await handleAloePayment(
      uuid,
      payOrder,
      () => {
        dispatch(resetGoodsBasket());
        history.push(getOrderModeratedRoute(uuid));
      },
      enqueueSnackbar,
      dispatch,
      history
    );
  };

  return (
    <Fragment>
      {!isFetching && order && (
        <Grid
          container={true}
          direction={'column'}
          sx={{ height: '100%' }}
          wrap={'nowrap'}
          mt={isMobile ? -3 : 0}>
          <Grid item={true} paddingBottom={3}>
            <Grid container={true} wrap={'nowrap'}>
              <Grid item={true} pr={1.5}>
                <Typography variant={'h1'}>{'Заказ готов к оплате'}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={true} pb={isMobile ? 3 : 0}>
            <Grid
              container={true}
              columnSpacing={15.5}
              direction={isMobile ? 'column' : 'row'}>
              <Grid item={true} xs={isMobile ? 'auto' : 7}>
                <Grid container={true} direction={'column'}>
                  <Grid item={true} paddingBottom={isMobile ? 4 : 6}>
                    <OrderClientInfo order={order} />
                  </Grid>
                  {isMobile && (
                    <Grid item={true} paddingBottom={4}>
                      <OrderPaymentBlock
                        totalSum={totalSum}
                        handlePaySubmit={handlePaySubmit}
                      />
                    </Grid>
                  )}
                  <Grid item={true}>
                    <OrderTableInfo
                      goods={order.orderItems.filter((item: OrderItemDTO) => {
                        return item.approved;
                      })}
                      commission={order.commission}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {!isMobile && (
                <Grid item={true} xs={true}>
                  <OrderPaymentBlock
                    isLoading={isPayOrderLoading}
                    totalSum={totalSum}
                    handlePaySubmit={handlePaySubmit}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {isMobile && (
            <Grid item={true}>
              <Footer isMobile={isMobile} />
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  );
};
