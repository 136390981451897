import { FormikProps } from 'formik';
import { Grid } from '@mui/material';
import { OrderClientValues } from '@/types/order/OrderClientValues';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PhoneNumberField } from '@/components/common/field/PhoneNumberField';
import { regExOnlyNumberKey } from '@/utils/validation-utils';
import React, { ChangeEvent, FC } from 'react';

interface Props {
  formikProps: FormikProps<OrderClientValues>;
  handleSetFieldValue: (field: string, value: unknown) => void;
  isMobile: boolean;
}

const AuthorForm: FC<Props> = (props: Props) => {
  const { formikProps, handleSetFieldValue, isMobile } = props;
  const { values, errors, touched } = formikProps;

  return (
    <Grid
      container={true}
      direction={isMobile ? 'column' : 'row'}
      spacing={isMobile ? 6 : 3}>
      <Grid item={true} xs={6}>
        <OutlinedField
          name={'authorFullName'}
          label={'ФИО'}
          value={values.authorFullName ? String(values.authorFullName) : ''}
          error={touched.authorFullName && Boolean(errors.authorFullName)}
          helperText={touched.authorFullName ? errors.authorFullName : ''}
          onKeyPress={(event) => {
            if (values.authorFullName.length === 0 && event.key === ' ') {
              event.preventDefault();
            }
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSetFieldValue('authorFullName', e.target.value);
          }}
        />
      </Grid>
      <Grid item={true} xs={3}>
        <OutlinedField
          name={'authorEmail'}
          label={'Электронная почта'}
          value={values.authorEmail ? String(values.authorEmail) : ''}
          error={touched.authorEmail && Boolean(errors.authorEmail)}
          helperText={touched.authorEmail ? errors.authorEmail : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSetFieldValue('authorEmail', e.target.value);
          }}
        />
      </Grid>
      <Grid item={true} xs={3}>
        <PhoneNumberField
          name={'authorPhoneNumber'}
          label={'Телефон'}
          value={
            values.authorPhoneNumber ? String(values.authorPhoneNumber) : ''
          }
          error={touched.authorPhoneNumber && Boolean(errors.authorPhoneNumber)}
          helperText={touched.authorPhoneNumber ? errors.authorPhoneNumber : ''}
          onKeyPress={(event) => {
            if (!regExOnlyNumberKey.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSetFieldValue('authorPhoneNumber', e.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AuthorForm;
