import { Button, ButtonProps, Grid, Theme, Typography } from '@mui/material';
import { Path } from 'history';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  to: Path;
  label: string;
  value?: number;
  total?: number;
  activeOnlyWhenExact?: boolean;
  onBeforeRedirect?: () => void;
}

export const DrawerLink: FC<Props> = (props: Props) => {
  const {
    label,
    value,
    total,
    to,
    activeOnlyWhenExact,
    onBeforeRedirect,
    ...other
  } = props;
  const history = useHistory();
  const match = useRouteMatch({
    path: to.toString(),
    exact: activeOnlyWhenExact,
  });
  const handleClick = (): void => {
    if (onBeforeRedirect) {
      onBeforeRedirect();
    }
    history.push(to);
  };
  return (
    <Button
      color={'inherit'}
      fullWidth={true}
      sx={(theme: Theme) => ({
        textTransform: 'none',
        padding: theme.spacing(1.25, 1),
        backgroundColor: match ? theme.colors.alert + '!important' : 'inherit',
      })}
      onClick={handleClick}
      {...other}>
      <Grid
        container={true}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'baseline'}
        spacing={1}>
        <Grid item={true}>
          <Typography
            whiteSpace={'pre-line'}
            sx={(theme: Theme) => ({
              fontSize: theme.spacing(2),
              fontWeight: 500,
              lineHeight: theme.spacing(2.5),
            })}>
            {label}
          </Typography>
        </Grid>
        <Grid item={true}>
          <Typography
            sx={{
              fontSize: (theme: Theme) => theme.spacing(1.75),
              lineHeight: 1,
            }}>
            <span style={{ fontWeight: 500 }}>
              {value && value > 0 ? value : ''}
            </span>
            <span style={{ fontWeight: 500 }}>
              {value && total && value > 0 ? '/' : ''}
            </span>
            <span style={{ opacity: 0.5 }}>{total}</span>
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};
