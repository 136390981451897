import { ClientYearField } from '@/components/order/ClientYearField';
import { FormikProps } from 'formik';
import { Grid } from '@mui/material';
import { OrderClientValues } from '@/types/order/OrderClientValues';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import React, { ChangeEvent, FC } from 'react';

interface Props {
  formikProps: FormikProps<OrderClientValues>;
  handleSetFieldValue: (field: string, value: unknown) => void;
  isMobile: boolean;
}

const SenderForm: FC<Props> = (props: Props) => {
  const { formikProps, handleSetFieldValue, isMobile } = props;
  const { values, errors, touched } = formikProps;

  return (
    <Grid
      container={true}
      direction={isMobile ? 'column' : 'row'}
      spacing={isMobile ? 6 : 3}>
      <Grid item={true} xs={true}>
        <OutlinedField
          name={'prisonerLastName'}
          label={'Фамилия'}
          value={values.prisonerLastName ? String(values.prisonerLastName) : ''}
          error={touched.prisonerLastName && Boolean(errors.prisonerLastName)}
          helperText={touched.prisonerLastName ? errors.prisonerLastName : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSetFieldValue('prisonerLastName', e.target.value);
          }}
        />
      </Grid>
      <Grid item={true} xs={true}>
        <OutlinedField
          name={'prisonerFirstName'}
          label={'Имя'}
          value={
            values.prisonerFirstName ? String(values.prisonerFirstName) : ''
          }
          error={touched.prisonerFirstName && Boolean(errors.prisonerFirstName)}
          helperText={touched.prisonerFirstName ? errors.prisonerFirstName : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSetFieldValue('prisonerFirstName', e.target.value);
          }}
        />
      </Grid>
      <Grid item={true} xs={true}>
        <OutlinedField
          name={'prisonerMiddleName'}
          label={'Отчество'}
          value={
            values.prisonerMiddleName ? String(values.prisonerMiddleName) : ''
          }
          error={
            touched.prisonerMiddleName && Boolean(errors.prisonerMiddleName)
          }
          helperText={
            touched.prisonerMiddleName ? errors.prisonerMiddleName : ''
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSetFieldValue('prisonerMiddleName', e.target.value);
          }}
        />
      </Grid>
      <Grid item={true} xs={true}>
        <ClientYearField
          value={
            values.prisonerBirthDate ? String(values.prisonerBirthDate) : ''
          }
          onChange={(date) => {
            handleSetFieldValue(
              'prisonerBirthDate',
              date ? date.getFullYear() : ''
            );
          }}
          error={touched.prisonerBirthDate && Boolean(errors.prisonerBirthDate)}
          helperText={touched.prisonerBirthDate ? errors.prisonerBirthDate : ''}
          isMobile={isMobile}
        />
      </Grid>
    </Grid>
  );
};

export default SenderForm;
