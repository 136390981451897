import { Box, Theme } from '@mui/material';
import { LogoIcon } from '@/app/icons/LogoIcon';
import { NavigationDrawer } from '@/components/common/NavigationDrawer';
import React, { FC } from 'react';

export const MobileHeader: FC = () => {
  return (
    <Box
      display={'flex'}
      position={'relative'}
      alignItems={'center'}
      justifyContent={'center'}>
      <NavigationDrawer
        sx={{
          zIndex: 100,
          position: 'absolute',
          left: 0,
          margin: (theme: Theme) => theme.spacing(-1),
        }}
      />
      <LogoIcon height={'45px'} width={'61px'} />
    </Box>
  );
};
