import * as Yup from 'yup';
import { DepartmentDTO } from '@/types/DepartmentDTO';

//TODO: могут быть проблемы с валидацией у deliveryCost и enableOrdersSending?
export const departmentDTOSchema: Yup.SchemaOf<DepartmentDTO> = Yup.object({
  id: Yup.number().required(),
  name: Yup.string().required(),
  message: Yup.string().notRequired().nullable(),
  deliveryCost: Yup.number().required(),
  enableOrdersSending: Yup.boolean().required(),
  enableOrdersModeration: Yup.boolean().required(),
  validProperties: Yup.boolean().required(),
}).typeError('Выберите учреждение.');
