import { FullName } from '@/types/FullName';
import { PrescriptionStatus } from '@/types/order/PrescriptionStatus';
import { fullNameRegExp } from '@/utils/validation-utils';
import parsePhoneNumber from 'libphonenumber-js';

export const formatCurrency = (
  value: number | string,
  maximumFractionDigits?: number,
  minimumFractionDigits?: number
): string => {
  if (isNaN(Number(value))) {
    return 'NaN';
  }
  const format = new Intl.NumberFormat('ru-Ru', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits
      ? minimumFractionDigits
      : maximumFractionDigits,
  });
  return format.format(Number(value));
};

const isWhitespace = (char: string): boolean => {
  return /\s/.test(char);
};

export const isBlank = (value: string | undefined | null): boolean => {
  let length;
  if (value == undefined || (length = value.length) === 0) {
    return true;
  }
  for (let i = 0; i < length; i++) {
    if (!isWhitespace(value.charAt(i))) {
      return false;
    }
  }
  return true;
};

export const isNotBlank = (value: string | undefined | null): boolean => {
  return !isBlank(value);
};

/**
 * Функция возвращает окончание для множественного числа слова на основании числа и массива окончаний.
 * @param  iNumber Integer Число на основе которого нужно сформировать окончание
 * @param  aEndings Array Массив слов или окончаний для чисел (1, 4, 5),
 *         например ['яблоко', 'яблока', 'яблок']
 * @return String
 */
export const getNumEnding = (iNumber: number, aEndings: string[]): string => {
  let sEnding, i;
  iNumber = iNumber % 100;
  if (iNumber >= 11 && iNumber <= 19) {
    sEnding = aEndings[2];
  } else {
    i = iNumber % 10;
    switch (i) {
      case 1:
        sEnding = aEndings[0];
        break;
      case 2:
      case 3:
      case 4:
        sEnding = aEndings[1];
        break;
      default:
        sEnding = aEndings[2];
    }
  }
  return sEnding;
};

export const formatFullName = (
  lastName: string,
  firstName: string,
  middleName?: string
): string => {
  return [
    upperFirstCaseInSentence(lastName),
    upperFirstCaseInSentence(firstName),
    upperFirstCaseInSentence(middleName || ''),
  ]
    .filter((s: string) => isNotBlank(s))
    .join(' ');
};

export const formatAuthorFullName = (
  lastName?: string,
  firstName?: string,
  middleName?: string
): string => {
  return [
    lastName ? upperFirstCaseInSentence(lastName) : '',
    firstName ? upperFirstCaseInSentence(firstName) : '',
    middleName ? upperFirstCaseInSentence(middleName) : '',
  ]
    .filter((s: string) => isNotBlank(s))
    .join(' ');
};

export const formatPhoneNumber = (phone?: string): string => {
  if (phone) {
    const phoneNumber = parsePhoneNumber('+' + phone);
    return phoneNumber
      ? phoneNumber.country === 'RU'
        ? phoneNumber.formatNational().replace('8', '+7')
        : phoneNumber.formatNational()
      : phone;
  }
  return '';
};

export const upperFirstCase = (value: string): string => {
  if (isNotBlank(value)) {
    return removeExtraSpaces(value.charAt(0).toUpperCase() + value.slice(1));
  }
  return removeExtraSpaces(value);
};

export const upperFirstCaseInSentence = (value: string): string => {
  if (isNotBlank(value)) {
    const words: string[] = removeExtraSpaces(value).split(' ');
    return words.map((word: string) => upperFirstCase(word)).join(' ');
  }
  return removeExtraSpaces(value);
};

export const removeExtraSpaces = (value: string): string => {
  if (isNotBlank(value)) {
    return value
      .replace(/\s+/g, ' ')
      .replace(/^\s+|\s+$/g, '')
      .trim();
  }
  return '';
};

export const onlyNumberFormat = (value: string): string => {
  return value.replace(/[^0-9]/g, '');
};

export const parseFullName = (
  fullName: string | undefined | null
): FullName | null => {
  if (isNotBlank(fullName)) {
    const match: RegExpExecArray | null = fullNameRegExp.exec(fullName || '');
    if (match?.groups) {
      return {
        lastName: upperFirstCaseInSentence(match?.groups?.lastName),
        firstName: upperFirstCaseInSentence(match?.groups?.firstName),
        middleName: upperFirstCaseInSentence(match?.groups?.middleName),
      };
    }
  }
  return null;
};

export const numberWithSpaceFormat = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const phoneFieldNormalize = (value: string): string => {
  if (value[0] === '8') {
    return value.replace('8', '+7').replace(/[-\s]+/g, '');
  }
  return value.replace(/[-\s]+/g, '');
};

export const expirationDateFormat = (expirationDate: number): string => {
  return `${
    Math.floor(expirationDate / 12) > 0
      ? `${Math.floor(expirationDate / 12)} ${getNumEnding(
          Math.floor(expirationDate / 12),
          ['год', 'года', 'лет']
        )}`
      : ''
  } ${
    expirationDate % 12 > 0
      ? `${expirationDate % 12} ${getNumEnding(expirationDate % 12, [
          'месяц',
          'месяца',
          'месяцев',
        ])}`
      : ''
  } с момента производства. Дата производства указанна на упаковке`;
};

export const requiredPrescriptionFormat = (
  requiredPrescription: PrescriptionStatus
): string => {
  if (requiredPrescription === PrescriptionStatus.REQUIRED) {
    return 'Отпускается с рецептом';
  }
  return 'Отпускается без рецепта';
};
