import { Theme, Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  isMobile?: boolean;
  tittle: string;
}

export const ClientTittle: FC<Props> = (props: Props) => {
  const { isMobile, tittle } = props;
  return (
    <Typography
      variant={isMobile ? 'body1' : 'h1'}
      fontSize={isMobile ? '14px' : '20px'}
      color={(theme: Theme) =>
        isMobile ? theme.colors.grayText : theme.colors.textColor
      }>
      {tittle}
    </Typography>
  );
};
