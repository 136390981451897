import { BasketDTO } from '@/types/order/BasketDTO';
import { BasketDrawer } from '@/components/basket/BasketDrawer';
import { BasketIcon } from '@/app/icons/BasketIcon';
import { Button, Grid, TableBody, Theme, Typography } from '@mui/material';
import { countAllGoodsInBasket } from '@/utils/common-utils';
import { formatCurrency, getNumEnding } from '@/utils/string-utils';
import React, { FC, Fragment, useState } from 'react';

interface Props {
  basket: BasketDTO;
  deliveryCost: number;
}

const Basket: FC<Props> = (props: Props) => {
  const { basket, deliveryCost } = props;
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  return (
    <Fragment>
      <Button
        variant={'contained'}
        color={'secondary'}
        fullWidth={true}
        onClick={() => setOpenMenu(true)}
        sx={(theme: Theme) => ({
          minWidth: theme.spacing(18.5),
          background: theme.colors.secondaryGrayBackground,
          borderRadius: theme.spacing(1.5),
          padding: theme.spacing(1.25, 3),
        })}>
        <Grid
          container={true}
          justifyContent={'space-between'}
          alignItems={'center'}
          wrap={'nowrap'}>
          <Grid item={true}>
            <BasketIcon
              sx={(theme: Theme) => ({
                display: 'flex',
                color: theme.colors.secondaryGrayBackground,
                width: theme.spacing(3.25),
                height: theme.spacing(4.625),
              })}
            />
          </Grid>
          <Grid item={true}>
            <Grid container={true} direction={'column'}>
              <Grid
                item={true}
                paddingBottom={(theme: Theme) => theme.spacing(0.5)}>
                <Typography variant={'body2'}>{`${countAllGoodsInBasket(
                  basket
                )} ${getNumEnding(countAllGoodsInBasket(basket), [
                  'товар',
                  'товара',
                  'товаров',
                ])}`}</Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant={'h5'} align={'right'}>
                  {formatCurrency(basket.currency + deliveryCost)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Button>
      <BasketDrawer
        basket={basket}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
      />
    </Fragment>
  );
};

export default Basket;
