import { AlarmIcon } from '@/app/icons/AlarmIcon';
import { AuthorDTO } from '@/types/AuthorDTO';
import { BasketActionsYaBrowser } from '@/components/basket/BasketActionsYaBrowser';
import { BasketDTO } from '@/types/order/BasketDTO';
import { BasketDeliveryMobile } from '@/components/basket/BasketDeliveryMobile';
import { BasketProductMobile } from '@/components/basket/BasketProductMobile';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { DrugstoreDialogTitle } from '@/components/common/modal/DrugstoreDialogTitle';
import { FixedButton } from '@/components/common/button/FixedButton';
import { MastercardIcon } from '@/app/icons/payment/MastercardIcon';
import { MirIcon } from '@/app/icons/payment/MirIcon';
import { SenderDTO } from '@/types/SenderDTO';
import { TypographyConditions } from '@/components/common/TypographyConditions';
import { VisaIcon } from '@/app/icons/payment/VisaIcon';
import { formatCurrency } from '@/utils/string-utils';
import { isYandexBrowser } from '@/utils/common-utils';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  handlePaySubmit: () => Promise<void>;
  handleOrderSubmit: () => Promise<void>;
  basket: BasketDTO;
  sender: SenderDTO;
  author: AuthorDTO;
  department: DepartmentDTO;
  isLoading?: boolean;
}

export const BasketDialog: FC<Props> = (props: Props) => {
  const {
    open,
    onClose,
    basket,
    handlePaySubmit,
    handleOrderSubmit,
    sender,
    author,
    department,
    isLoading,
  } = props;

  const enableOrdersModeration: boolean = department.enableOrdersModeration;

  //fix redirect from gmail app to YaBrowser
  const [isYaBrowser, setIsYaBrowser] = useState<boolean>(false);
  useEffect(() => {
    if (isYandexBrowser()) {
      setIsYaBrowser(true);
    }
  }, []);

  return (
    <Dialog open={open} fullScreen={true}>
      <DrugstoreDialogTitle
        label={'Ваша корзина'}
        sxTittle={(theme: Theme) => ({ padding: theme.spacing(3, 2, 4) })}
        onClose={onClose}
      />
      <DialogContent sx={{ padding: 0 }}>
        <Grid
          container={true}
          direction={'column'}
          sx={{ height: '100%' }}
          wrap={'nowrap'}>
          <Grid item={true} xs={true} pb={2}>
            <Grid container={true} direction={'column'} sx={{ height: '100%' }}>
              {basket.goodsBasket.map((product, index, goods) => (
                <Grid item={true} key={index}>
                  <BasketProductMobile
                    product={product}
                    nextProductOutStoke={
                      index === goods.length ? false : goods[index].isStoke
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {author && sender && department && (
            <Grid item={true} pr={2} pl={2} pb={1.5}>
              <Grid container={true} direction={'column'} rowSpacing={3}>
                <Grid item={true}>
                  <BasketDeliveryMobile
                    author={author}
                    sender={sender}
                    department={department}
                  />
                </Grid>
                {!isYaBrowser && (
                  <Grid item={true}>
                    <Grid container={true} columnSpacing={3.75} wrap={'nowrap'}>
                      {!enableOrdersModeration && (
                        <Grid item={true}>
                          <Grid
                            container={true}
                            columnSpacing={1.5}
                            wrap={'nowrap'}>
                            <Grid item={true}>
                              <VisaIcon />
                            </Grid>
                            <Grid item={true}>
                              <MastercardIcon />
                            </Grid>
                            <Grid item={true}>
                              <MirIcon />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item={true}>
                        <TypographyConditions
                          buttonName={
                            enableOrdersModeration
                              ? 'Оформить заказ'
                              : 'Оплатить'
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        {isYaBrowser ? (
          <BasketActionsYaBrowser
            currency={basket.currency}
            deliveryCost={department.deliveryCost}
            isLoading={isLoading}
            handlePaySubmit={handlePaySubmit}
            handleOrderSubmit={handleOrderSubmit}
            enableOrdersModeration={enableOrdersModeration}
          />
        ) : (
          <Grid
            container={true}
            direction={'column'}
            sx={(theme: Theme) => ({
              backgroundColor: theme.colors.secondaryGrayBackground,
            })}>
            <Grid item={true} p={'12px 16px'} pb={0}>
              <Typography variant={'h2'}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true}>{'Итог'}</Grid>
                  <Grid item={true}>
                    {formatCurrency(basket.currency + department.deliveryCost)}
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item={true} p={'14px 16px'}>
              <Grid container={true} wrap={'nowrap'} alignItems={'center'}>
                <Grid item={true} pr={3}>
                  <AlarmIcon style={{ display: 'block' }} />
                </Grid>
                <Grid item={true} sx={{ maxWidth: '260px' }}>
                  {enableOrdersModeration ? (
                    <Typography
                      variant={'body2'}
                      fontSize={'12px'}
                      lineHeight={'14px'}
                      color={(theme: Theme) => theme.colors.grayTextDark}>
                      {'Заказ будет отправлен врачу учреждения на обработку. '}
                      {
                        'Если что-то из товаров нельзя доставить, врач уберёт это из заказа.'
                      }
                      <br />
                      {'Оплата заказа возможна после обработки.'}
                    </Typography>
                  ) : (
                    <Typography
                      variant={'body2'}
                      fontSize={'12px'}
                      lineHeight={'14px'}
                      color={(theme: Theme) => theme.colors.grayTextDark}>
                      {'После нажатия на кнопку «Оплатить» у вас будет '}
                      <strong>{'6 минут'}</strong>
                      {' на оплату заказа'}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              <FixedButton
                color={'primary'}
                variant={'contained'}
                disabled={isLoading}
                onClick={
                  enableOrdersModeration ? handleOrderSubmit : handlePaySubmit
                }>
                {enableOrdersModeration ? 'Оформить заказ' : 'Оплатить'}
              </FixedButton>
            </Grid>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
};
