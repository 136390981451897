import { Button, ButtonProps } from '@mui/material';
import { Path } from 'history';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

export interface ButtonLinkProps extends ButtonProps {
  to?: Path;
  noHover?: boolean;
}

export const ButtonLink: FC<ButtonLinkProps> = (props: ButtonLinkProps) => {
  const { children, to, noHover, ...other } = props;
  const history = useHistory();
  const noHoverSx: SxProps<Theme> = noHover
    ? {
        '&:hover': {
          background: 'none',
        },
      }
    : {};
  return (
    <Button
      color={'inherit'}
      variant={'text'}
      sx={{
        p: props.size === 'small' ? '10px' : '12px',
        m: props.size === 'small' ? '-10px' : '-12px',
        ...noHoverSx,
      }}
      onClick={() => {
        if (to) {
          history.push(to);
        }
      }}
      disableRipple={!!noHover}
      {...other}>
      {children}
    </Button>
  );
};
