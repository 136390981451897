import { AuthorDTO } from '@/types/AuthorDTO';
import { BasketDTO } from '@/types/order/BasketDTO';
import { BasketDialog } from '@/components/basket/BasketDialog';
import { CreatedOrderDTO } from '@/types/order/CreatedOrderDTO';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Footer } from '@/components/common/footer/Footer';
import { GoodsBasket } from '@/types/order/GoodsBasket';
import { GoodsDTO } from '@/types/order/GoodsDTO';
import { Grid } from '@mui/material';
import {
  MESSAGE_BASKET_ERROR,
  MESSAGE_FETCH_ERROR,
} from '@/utils/validation-utils';
import { MobileBar } from '@/components/common/MobileBar';
import { NavigationMobileHeader } from '@/components/order/NavigationMobileHeader';
import { NavigationPanelMobile } from '@/components/order/NavigationPanelMobile';
import { SenderDTO } from '@/types/SenderDTO';
import { ShowcaseDTO } from '@/types/order/ShowcaseDTO';
import { countAllGoodsInBasket } from '@/utils/common-utils';
import {
  getAuthor,
  getBasket,
  getDepartment,
  getSender,
  resetGoodsBasket,
  setAllGoodsBasket,
  setDepartment,
} from '@/services/createOrderSlice';
import { getOrderModerationRoute, getOrderSuccessRoute } from '@/app/routes';
import { handleAloePayment } from '@/utils/payment-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useCheckOrdersModerationMutation } from '@/services/api/regionsApiSlice';
import { useCreateOrderMutation } from '@/services/api/orderApiSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import { usePayOrderMutation } from '@/services/api/paymentApiSlice';
import { useSnackbar } from 'notistack';
import GoodsTable from '@/components/order/GoodsTable';
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

interface Props {
  goodsJSON: ShowcaseDTO[] | undefined;
  loading: boolean;
  handleSetCategory: (categoryName: string) => void;
  currentCategory: string | undefined;
  currentGoods: GoodsDTO[];
  valueSearchPanel: GoodsDTO | null;
  isCanShowMore: boolean;
  handleShowMoreGoods: (pageSize: number) => void;
  navigationRef?: React.RefObject<any>;
}

export const ShowcaseMobileForm: FC<Props> = (props: Props) => {
  const {
    goodsJSON,
    loading,
    handleSetCategory,
    currentCategory,
    currentGoods,
    valueSearchPanel,
    isCanShowMore,
    handleShowMoreGoods,
    navigationRef,
  } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const basket: BasketDTO | null = useAppSelector(getBasket);
  const author: AuthorDTO | null = useAppSelector(getAuthor);
  const sender: SenderDTO | null = useAppSelector(getSender);
  const department: DepartmentDTO | null = useAppSelector(getDepartment);

  const [createOrder, { isLoading: isValidLoading }] = useCreateOrderMutation();
  const [checkOrdersModeration, { isLoading: isModerationLoading }] =
    useCheckOrdersModerationMutation();
  const [payOrder, { isLoading: isPayOrderLoading }] = usePayOrderMutation();

  const [openBasket, setOpenBasket] = useState<boolean>(false);
  const handleCloseBasket = () => {
    setOpenBasket(false);
  };
  const handleOpenBasket = () => {
    setOpenBasket(true);
  };

  const { executeRecaptcha } = useGoogleReCaptcha();
  //TODO: make handleReCaptchaVerify like custom hook
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    return await executeRecaptcha('payAction');
  }, [executeRecaptcha]);

  const handleCheckOrdersModerationEnabled = async (
    departmentId: number,
    departmentOrdersModerationEnabled: boolean,
    handleSubmit: () => void
  ) => {
    if (department) {
      await checkOrdersModeration(departmentId)
        .unwrap()
        .then((ordersModerationEnabled: boolean) => {
          if (ordersModerationEnabled == departmentOrdersModerationEnabled) {
            handleSubmit();
          } else {
            dispatch(
              setDepartment({
                department: {
                  ...department,
                  enableOrdersModeration: ordersModerationEnabled,
                },
              })
            );
            enqueueSnackbar('Изменились параметры отправки заказов', {
              variant: 'warning',
            });
          }
        })
        .catch(() => {
          enqueueSnackbar('Ошибка загрузки данных с сервера', {
            variant: 'error',
          });
        });
    }
  };

  const handlePaySubmit = async (): Promise<void> => {
    if (!author || !sender || !department || !basket) {
      return;
    }
    const token: string | undefined = await handleReCaptchaVerify();
    await handleCheckOrdersModerationEnabled(
      department.id,
      department.enableOrdersModeration,
      async () => {
        await createOrder({
          departmentId: department.id,
          sender: sender,
          author: author,
          goods: basket.goodsBasket,
          token: token,
        })
          .unwrap()
          .then((response: CreatedOrderDTO): void => {
            const haveError: GoodsBasket | undefined =
              response.goodsBasket.find((item: GoodsBasket) => !item.isStoke);
            if (haveError) {
              dispatch(setAllGoodsBasket(response.goodsBasket));
              enqueueSnackbar(MESSAGE_BASKET_ERROR, { variant: 'error' });
            } else {
              if (department.enableOrdersModeration) {
                dispatch(resetGoodsBasket());
                history.push(getOrderModerationRoute(response.uuid));
              } else {
                handleAloePayment(
                  response.uuid,
                  payOrder,
                  () => {
                    dispatch(resetGoodsBasket());
                    history.push(getOrderSuccessRoute(response.uuid));
                  },
                  enqueueSnackbar,
                  dispatch,
                  history
                );
              }
            }
          })
          .catch((e: { status: number; data: ErrorDTO }) => {
            enqueueSnackbar(
              e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR,
              { variant: 'error' }
            );
          });
      }
    );
  };

  const catalogueRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (catalogueRef && catalogueRef.current) {
      catalogueRef.current.scrollIntoView({ block: 'end', behavior: 'auto' });
    }
  }, [currentCategory]);

  return (
    <Fragment>
      <Grid
        container={true}
        direction={'column'}
        sx={{ height: '100%' }}
        wrap={'nowrap'}>
        <Grid item={true} xs={true} paddingBottom={8.5}>
          {currentCategory ? (
            <Grid
              container={true}
              direction={'column'}
              sx={{ height: '100%' }}
              wrap={'nowrap'}>
              <Grid item={true} ref={navigationRef}>
                <NavigationMobileHeader
                  currentCategory={currentCategory}
                  handleSetCategory={handleSetCategory}
                />
              </Grid>
              <Grid item={true} xs={true}>
                <GoodsTable
                  loading={loading}
                  goods={currentGoods}
                  isMobile={true}
                  skeletonSize={6}
                  isCanShowMore={isCanShowMore && !valueSearchPanel}
                  handleShowMoreGoods={handleShowMoreGoods}
                />
              </Grid>
            </Grid>
          ) : (
            <NavigationPanelMobile
              data={goodsJSON || []}
              loading={loading}
              setCategory={handleSetCategory}
            />
          )}
        </Grid>
        {basket && (
          <Grid item={true}>
            <MobileBar>
              <FixedButton
                color={'primary'}
                variant={'contained'}
                onClick={handleOpenBasket}
                disabled={!department || !basket}>
                {`Корзина (${countAllGoodsInBasket(basket)})`}
              </FixedButton>
            </MobileBar>
          </Grid>
        )}
        <Grid item={true} pb={basket ? 6.5 : 0}>
          <Footer isMobile={true} />
        </Grid>
      </Grid>
      {basket && department && author && sender && (
        <BasketDialog
          open={openBasket}
          onClose={handleCloseBasket}
          basket={basket}
          handlePaySubmit={handlePaySubmit}
          handleOrderSubmit={handlePaySubmit}
          author={author}
          sender={sender}
          department={department}
          isLoading={isValidLoading || isModerationLoading || isPayOrderLoading}
        />
      )}
    </Fragment>
  );
};
