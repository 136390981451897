import * as Yup from 'yup';
import { getYear } from 'date-fns';

export const minBirthYear = getYear(new Date()) - 100;
export const maxBirthYear = getYear(new Date()) - 14;
export const yearSchema = Yup.number()
  .required('Пустое поле')
  .min(minBirthYear, 'Некорректный год')
  .max(maxBirthYear, 'Некорректный год')
  .typeError('Введите число');
