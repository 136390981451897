import * as Yup from 'yup';
import { Button, Grid } from '@mui/material';
import { ClientTittle } from '@/components/order/ClientTittle';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Formik, FormikProps } from 'formik';
import { MobileBar } from '@/components/common/MobileBar';
import { OrderClientValues } from '@/types/order/OrderClientValues';
import { ROUTE_ORDER_SHOWCASE } from '@/app/routes';
import { TypographyConditions } from '@/components/common/TypographyConditions';
import { emailSchema } from '@/validation/emailSchema';
import { fullNameSchema } from '@/validation/fullNameSchema';
import {
  getAuthor,
  getSender,
  setAuthor,
  setSender,
} from '@/services/createOrderSlice';
import { phoneFieldNormalize, removeExtraSpaces } from '@/utils/string-utils';
import { phoneSchema } from '@/validation/phoneSchema';
import { prisonerNameSchema } from '@/validation/prisonerNameSchema';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import { yearSchema } from '@/validation/yearSchema';
import AuthorForm from '@/components/order/AuthorForm';
import React, { FC, Fragment } from 'react';
import SenderForm from '@/components/order/SenderForm';
import TittleOrderForm from '@/components/order/TittleOrderForm';

const validationSchema = Yup.object({
  prisonerLastName: prisonerNameSchema,
  prisonerFirstName: prisonerNameSchema,
  prisonerMiddleName: prisonerNameSchema,
  prisonerBirthDate: yearSchema,
  authorFullName: fullNameSchema,
  authorPhoneNumber: phoneSchema,
  authorEmail: emailSchema,
});

interface Props {
  isMobile: boolean;
}
const ClientCreateForm: FC<Props> = (props: Props) => {
  const { isMobile } = props;

  const history = useHistory();

  const sender = useAppSelector(getSender);
  const author = useAppSelector(getAuthor);

  const initialValues = {
    prisonerLastName: sender?.lastName || '',
    prisonerFirstName: sender?.firstName || '',
    prisonerMiddleName: sender?.middleName || '',
    prisonerBirthDate: sender?.age.toString() || '',
    authorFullName: author?.name || '',
    authorPhoneNumber: author?.phone || '',
    authorEmail: author?.email || '',
  };

  const dispatch = useAppDispatch();

  const handleSubmit = (values: OrderClientValues) => {
    dispatch(
      setAuthor({
        name: removeExtraSpaces(values.authorFullName),
        phone: phoneFieldNormalize(values.authorPhoneNumber),
        email: values.authorEmail,
      })
    );
    dispatch(
      setSender({
        lastName: removeExtraSpaces(values.prisonerLastName),
        firstName: removeExtraSpaces(values.prisonerFirstName),
        middleName: removeExtraSpaces(values.prisonerMiddleName),
        age: parseInt(values.prisonerBirthDate),
      })
    );
    history.push(ROUTE_ORDER_SHOWCASE);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {(formikProps: FormikProps<OrderClientValues>) => {
        const { submitForm, setFieldValue, setFieldTouched, isValid } =
          formikProps;
        const handleSetFieldValue = (field: string, value: unknown) => {
          setFieldTouched(field, true, false);
          setFieldValue(field, value, true);
        };

        return (
          <Grid
            container={true}
            direction={'column'}
            paddingBottom={isMobile ? 8 : 0}>
            <Grid
              item={true}
              paddingBottom={isMobile ? 4.5 : 7}
              paddingTop={isMobile ? 0 : 1.5}
              marginTop={isMobile ? -1.5 : 0}>
              <TittleOrderForm
                tittle={'Шаг 1 из 2: Введите данные получателя и отправителя'}
                mobileTittle={'1/2: Введите данные'}
                isMobile={isMobile}
              />
            </Grid>
            <Grid item={true} paddingBottom={isMobile ? 5 : 5.5}>
              <ClientTittle
                tittle={'Данные получателя заказа'}
                isMobile={isMobile}
              />
            </Grid>
            <Grid item={true} paddingBottom={isMobile ? 4.5 : 6}>
              <SenderForm
                formikProps={formikProps}
                handleSetFieldValue={handleSetFieldValue}
                isMobile={isMobile}
              />
            </Grid>
            <Grid item={true} paddingBottom={isMobile ? 5 : 5.5}>
              <ClientTittle
                tittle={'Данные отправителя заказа'}
                isMobile={isMobile}
              />
            </Grid>
            <Grid item={true} paddingBottom={isMobile ? 3 : 4}>
              <AuthorForm
                formikProps={formikProps}
                handleSetFieldValue={handleSetFieldValue}
                isMobile={isMobile}
              />
            </Grid>
            <Grid item={true}>
              {isMobile ? (
                <Fragment>
                  <TypographyConditions
                    buttonName={'Продолжить'}
                    conditionsText={
                      'Нажимая кнопку «Продолжить», я соглашаюсь\nи принимаю '
                    }
                  />
                  <MobileBar>
                    <FixedButton
                      color={'primary'}
                      variant={'contained'}
                      disabled={!isValid}
                      onClick={submitForm}>
                      {'Продолжить'}
                    </FixedButton>
                  </MobileBar>
                </Fragment>
              ) : (
                <Grid
                  container={true}
                  justifyContent={'space-between'}
                  spacing={3}>
                  <Grid item={true} xs={5}>
                    <TypographyConditions
                      buttonName={'Продолжить'}
                      conditionsText={
                        'Нажимая кнопку «Продолжить», я соглашаюсь и принимаю\n'
                      }
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <Button
                      fullWidth={true}
                      variant={'contained'}
                      disabled={!isValid}
                      onClick={submitForm}>
                      {'Продолжить'}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default ClientCreateForm;
