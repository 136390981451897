import { CreateOrderDTO } from '@/types/order/CreateOrderDTO';
import { CreatedOrderDTO } from '@/types/order/CreatedOrderDTO';
import { OrderDTO } from '@/types/order/OrderDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const orderApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOrderByUuid: build.query<OrderDTO, string | null>({
      query: (uuid: string) => ({
        url: '/order',
        method: 'GET',
        params: {
          uuid: uuid,
        },
      }),
      providesTags: ['Order'],
    }),
    createOrder: build.mutation<CreatedOrderDTO, CreateOrderDTO>({
      query: (dto: CreateOrderDTO) => ({
        url: '/order/create',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Re-Captcha-Token': dto.token,
        },
        body: dto,
      }),
    }),
  }),
});

export const { useGetOrderByUuidQuery, useCreateOrderMutation } = orderApiSlice;
