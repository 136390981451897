import { Button, ButtonProps, Grid, Theme } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { FC } from 'react';

type Props = ButtonProps;

export const MainCategoryButton: FC<Props> = (props: Props) => {
  const { ...other } = props;
  return (
    <Button
      variant={'outlined'}
      sx={(theme: Theme) => ({
        width: '180px',
        borderColor: theme.colors.primary,
        borderRadius: '33px',
        fontSize: '14px',
        lineHeight: '20px',
        padding: '7px 15px',
      })}
      {...other}>
      <Grid container={true} justifyContent={'center'}>
        <Grid item={true} pr={1}>
          <ChevronRightIcon
            color={'primary'}
            fontSize={'small'}
            sx={{ display: 'block', transform: 'rotate(180deg)' }}
          />
        </Grid>
        <Grid item={true}>{'В каталог'}</Grid>
      </Grid>
    </Button>
  );
};
