import { GET_OFFER_URL } from '@/app/config';
import { Theme, Typography, TypographyProps } from '@mui/material';
import { TypographyLink } from '@/components/common/TypographyLink';
import React, { FC } from 'react';

interface Props extends TypographyProps {
  buttonName: string;
  conditionsText?: string;
}

export const TypographyConditions: FC<Props> = (props: Props) => {
  const { buttonName, conditionsText, ...other } = props;
  return (
    <div>
      <Typography
        variant={'body3'}
        color={(theme: Theme) => theme.colors.grayText}
        textAlign={'left'}
        {...other}>
        {conditionsText
          ? conditionsText
          : `Нажимая кнопку «${buttonName}», я соглашаюсь и принимаю `}
        <a href={GET_OFFER_URL} target={'_blank'} rel={'noopener noreferrer'}>
          <TypographyLink>{'условия использования сервиса'}</TypographyLink>
        </a>
      </Typography>
    </div>
  );
};
