import { Grid, Typography } from '@mui/material';
import NavigatePreviousStep from '@/components/common/NavigatePreviousStep';
import React, { FC, Fragment } from 'react';

interface Props {
  tittle: string;
  mobileTittle?: string;
  isMobile?: boolean;
  labelPath?: string;
  routePath?: string;
}

const TittleOrderForm: FC<Props> = (props: Props) => {
  const { tittle, labelPath, routePath, mobileTittle, isMobile } = props;
  return (
    <Fragment>
      {labelPath && (
        <Grid container={true}>
          <NavigatePreviousStep to={routePath}>
            {labelPath}
          </NavigatePreviousStep>
        </Grid>
      )}
      <Typography variant={'h1'}>
        {isMobile && mobileTittle ? mobileTittle : tittle}
      </Typography>
    </Fragment>
  );
};

export default TittleOrderForm;
