import { AuthorDTO } from '@/types/AuthorDTO';
import { DeliveryIcon } from '@/app/icons/DeliveryIcon';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { Grid, Theme, Typography } from '@mui/material';
import { SenderDTO } from '@/types/SenderDTO';
import {
  formatAuthorFullName,
  formatCurrency,
  formatFullName,
  formatPhoneNumber,
  parseFullName,
} from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  sender: SenderDTO;
  author: AuthorDTO;
  department: DepartmentDTO;
}

export const BasketDelivery: FC<Props> = (props: Props) => {
  const { sender, author, department } = props;
  const authorFullName = parseFullName(author.name);
  return (
    <Grid
      container={true}
      sx={(theme: Theme) => ({
        padding: theme.spacing(2.75, 4),
        borderRadius: theme.spacing(2),
        backgroundColor: theme.colors.secondaryGrayBackground,
      })}>
      <Grid item={true} pr={2} pt={'3px'}>
        <DeliveryIcon />
      </Grid>
      <Grid item={true} xs={true} pr={2}>
        <Grid container={true} direction={'column'}>
          <Grid item={true} mb={0.5}>
            <Typography variant={'body2'}>{'Доставка'}</Typography>
          </Grid>
          <Grid item={true} mb={1.5}>
            <Typography variant={'body2'}>
              {`${department.name}, ${formatFullName(
                sender.lastName,
                sender.firstName,
                sender.middleName
              )}, ${sender.age} г.`}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography variant={'body2'}>
              {`Отправитель: ${formatAuthorFullName(
                authorFullName?.lastName,
                authorFullName?.firstName,
                authorFullName?.middleName
              )}, ${author.email}, ${formatPhoneNumber(author.phone)}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <Typography variant={'h5'}>
          {formatCurrency(department.deliveryCost)}
        </Typography>
      </Grid>
    </Grid>
  );
};
