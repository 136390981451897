import { AlarmIcon } from '@/app/icons/AlarmIcon';
import { Grid, Theme, Typography } from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MastercardIcon } from '@/app/icons/payment/MastercardIcon';
import { MirIcon } from '@/app/icons/payment/MirIcon';
import { TypographyConditions } from '@/components/common/TypographyConditions';
import { VisaIcon } from '@/app/icons/payment/VisaIcon';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  basketCost: number;
  deliveryCost: number;
  handlePaySubmit: () => Promise<void>;
  handleOrderSubmit: () => Promise<void>;
  isLoading?: boolean;
  enableOrdersModeration?: boolean;
}

export const BasketPay: FC<Props> = (props: Props) => {
  const {
    basketCost,
    deliveryCost,
    handlePaySubmit,
    handleOrderSubmit,
    isLoading,
    enableOrdersModeration,
  } = props;
  return (
    <Grid
      container={true}
      direction={'column'}
      sx={(theme: Theme) => ({
        backgroundColor: theme.colors.secondaryGrayBackground,
        padding: theme.spacing(3.125, 6.375, 3.25, 4.5),
      })}>
      <Grid item={true} paddingBottom={2}>
        <Grid container={true} justifyContent={'space-between'}>
          <Grid item={true}>
            <Typography variant={'h2'}>{'Итог'}</Typography>
          </Grid>
          <Grid item={true}>
            <Typography variant={'h2'}>
              {formatCurrency(basketCost + deliveryCost)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} paddingBottom={3.5}>
        <Grid container={true} wrap={'nowrap'} alignItems={'center'}>
          <Grid item={true} pr={3}>
            <AlarmIcon style={{ display: 'block' }} />
          </Grid>
          <Grid item={true}>
            {enableOrdersModeration ? (
              <Typography
                variant={'body2'}
                color={(theme: Theme) => theme.colors.grayTextDark}>
                {'Заказ будет отправлен врачу учреждения на обработку. '}
                {
                  'Если что-то из товаров нельзя доставить, врач уберёт это из заказа.'
                }
                <br />
                {'Оплата заказа возможна после обработки.'}
              </Typography>
            ) : (
              <Typography
                variant={'body2'}
                color={(theme: Theme) => theme.colors.grayTextDark}>
                {'После нажатия на кнопку «Оплатить» у вас будет '}
                <strong>{'6 минут'}</strong>
                {' на оплату заказа'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} paddingBottom={3.5}>
        {enableOrdersModeration ? (
          <LoadingButton
            fullWidth={true}
            variant={'contained'}
            isLoading={isLoading}
            onClick={handleOrderSubmit}>
            {'Оформить заказ'}
          </LoadingButton>
        ) : (
          <LoadingButton
            fullWidth={true}
            variant={'contained'}
            isLoading={isLoading}
            onClick={handlePaySubmit}>
            {'Оплатить'}
          </LoadingButton>
        )}
      </Grid>
      <Grid item={true}>
        <Grid container={true} columnSpacing={3}>
          {!enableOrdersModeration && (
            <Grid item={true}>
              <Grid container={true} columnSpacing={1.5}>
                <Grid item={true}>
                  <VisaIcon />
                </Grid>
                <Grid item={true}>
                  <MastercardIcon />
                </Grid>
                <Grid item={true}>
                  <MirIcon />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item={true}>
            {enableOrdersModeration ? (
              <TypographyConditions
                buttonName={'Оформить заказ'}
                conditionsText={
                  'Нажимая кнопку «Оформить заказ», я соглашаюсь и принимаю\n'
                }
              />
            ) : (
              <TypographyConditions
                buttonName={'Оплатить'}
                conditionsText={
                  'Нажимая кнопку «Оплатить», я соглашаюсь и принимаю\n'
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
