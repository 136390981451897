import { BasketField } from '@/components/common/field/BasketField';
import { GoodsBasket } from '@/types/order/GoodsBasket';
import { Grid, IconButton } from '@mui/material';
import { MaxCountInfo } from '@/components/order/MaxCountInfo';
import { MinusIcon } from '@/app/icons/MinusIcon';
import { PlusIcon } from '@/app/icons/PlusIcon';
import {
  decreaseGoodsBasket,
  increaseGoodsBasket,
} from '@/services/createOrderSlice';
import { useAppDispatch } from '@/app/hooks';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  product: GoodsBasket;
}

export const BasketGoodsCounter: FC<Props> = (props: Props) => {
  const { product } = props;
  const dispatch = useAppDispatch();
  const [countGoods, setCount] = useState<number | undefined>(product.count);

  const handleAddGoods = () => {
    dispatch(increaseGoodsBasket(product.goods));
    setCount(countGoods ? countGoods + 1 : 1);
  };
  const handleRemoveGoods = () => {
    dispatch(decreaseGoodsBasket(product.goods));
    setCount(countGoods ? countGoods - 1 : 0);
  };
  const isDisabled = () => {
    return product.count >= product.goods.maxCountPerOrder;
  };

  useEffect(() => {
    setCount(product.count);
  }, [product]);

  return (
    <Grid container={true} sx={{ minWidth: '65px' }}>
      <Grid item={true} sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          color={'primary'}
          onClick={handleRemoveGoods}
          sx={{ padding: '0px' }}>
          <MinusIcon sx={{ width: '13px', height: '13px' }} />
        </IconButton>
      </Grid>
      <Grid item={true} xs={true}>
        <BasketField
          countGoods={countGoods}
          setCountGoods={setCount}
          product={product.goods}
          isBasket={true}
        />
      </Grid>
      <Grid
        item={true}
        sx={{ display: 'flex', alignItems: 'center', paddingBottom: '1.5px' }}>
        {isDisabled() ? (
          <MaxCountInfo
            maxCount={product.goods.maxCountPerOrder}
            isBasket={true}
          />
        ) : (
          <IconButton
            disabled={isDisabled()}
            color={'primary'}
            onClick={handleAddGoods}
            sx={{ padding: '0px' }}>
            <PlusIcon sx={{ width: '13px', height: '13px' }} />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
