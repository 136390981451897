import { BurgerIcon } from '@/app/icons/BurgerIcon';
import { CloseIcon } from '@/app/icons/CloseIcon';
import { Drawer, Grid, IconButton, SxProps, Theme } from '@mui/material';
import { DrawerLink } from '@/components/common/DrawerLink';
import { LogoIcon } from '@/app/icons/LogoIcon';
import { ROUTE_FEEDBACK, ROUTE_INFO, ROUTE_ORDER } from '@/app/routes';
import React, { FC, Fragment, useState } from 'react';

interface Props {
  sx?: SxProps<Theme> | undefined;
}

export const NavigationDrawer: FC<Props> = (props: Props) => {
  const { sx } = props;
  const [open, setOpen] = useState<boolean>(false);
  const handleBeforeRedirect = (): void => {
    setOpen(false);
  };
  return (
    <Fragment>
      <IconButton
        color={'primary'}
        onClick={() => {
          setOpen(true);
        }}
        sx={{
          ...sx,
        }}>
        <BurgerIcon />
      </IconButton>
      <Drawer
        open={open}
        anchor={'left'}
        PaperProps={{ sx: { width: '330px' } }}>
        <Grid
          container={true}
          direction={'column'}
          sx={(theme: Theme) => ({
            height: '100%',
            padding: theme.spacing(2.5, 3, 8, 2),
          })}>
          <Grid item={true} pb={2}>
            <Grid
              container={true}
              alignItems={'center'}
              justifyContent={'center'}
              position={'relative'}>
              <IconButton
                color={'primary'}
                onClick={() => {
                  setOpen(false);
                }}
                sx={{
                  position: 'absolute',
                  left: 0,
                  m: (theme: Theme) => theme.spacing(-1),
                }}>
                <CloseIcon />
              </IconButton>
              <Grid item={true} display={'flex'}>
                <LogoIcon height={'45px'} width={'61px'} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={true}>
            <Grid container={true} direction={'column'} spacing={1}>
              <Grid item={true}>
                <DrawerLink
                  label={'Отправить заказ'}
                  to={ROUTE_ORDER}
                  onBeforeRedirect={handleBeforeRedirect}
                />
              </Grid>
              <Grid item={true}>
                <DrawerLink
                  label={'Информация'}
                  to={ROUTE_INFO}
                  onBeforeRedirect={handleBeforeRedirect}
                />
              </Grid>
              <Grid item={true}>
                <DrawerLink
                  label={'Обратная связь'}
                  to={ROUTE_FEEDBACK}
                  onBeforeRedirect={handleBeforeRedirect}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </Fragment>
  );
};
