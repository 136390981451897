import { AlarmIcon } from '@/app/icons/AlarmIcon';
import { Grid, Theme, Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  small?: boolean;
}

export const PaymentTimeInfo: FC<Props> = (props: Props) => {
  const { small } = props;
  return (
    <Grid
      container={true}
      direction={'row'}
      alignItems={'center'}
      spacing={3}
      wrap={'nowrap'}>
      <Grid item={true}>
        <AlarmIcon />
      </Grid>
      <Grid item={true}>
        <Typography
          variant={'body2'}
          fontSize={small ? '12px' : '14px'}
          lineHeight={small ? '14px' : '20px'}
          color={(theme: Theme) => theme.colors.grayTextDark}>
          {'После нажатия на кнопку «Оплатить» у вас будет '}
          <strong>{'6 минут'}</strong>
          {' на оплату заказа'}
        </Typography>
      </Grid>
    </Grid>
  );
};
