import { GoodsDTO } from '@/types/order/GoodsDTO';
import { Input, InputProps } from '@mui/material';
import { addGoodsBasket, clearGoodsBasket } from '@/services/createOrderSlice';
import { regExOnlyNumberKey } from '@/utils/validation-utils';
import { useAppDispatch } from '@/app/hooks';
import React, { ChangeEvent, FC } from 'react';

export interface BasketFieldProps extends InputProps {
  countGoods: number | undefined;
  setCountGoods: (countGoods?: number) => void;
  product: GoodsDTO;
  isBasket?: boolean;
}

export const BasketField: FC<BasketFieldProps> = (props: BasketFieldProps) => {
  const { countGoods, setCountGoods, product, isBasket, ...other } = props;
  const dispatch = useAppDispatch();

  const handleCountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const count = parseInt(e.target.value);
    setCountGoods(count);
  };
  const handleSubmitCount = () => {
    if (countGoods) {
      dispatch(
        addGoodsBasket({
          product: product,
          count:
            countGoods < product.maxCountPerOrder
              ? countGoods
              : product.maxCountPerOrder,
        })
      );
      if (countGoods > product.maxCountPerOrder) {
        setCountGoods(product.maxCountPerOrder);
      }
    } else {
      dispatch(clearGoodsBasket(product));
    }
  };

  const getCounterValue = (value?: number): string => {
    return value ? (isNaN(value) ? '' : value.toString()) : '';
  };

  return (
    <Input
      {...other}
      disableUnderline={true}
      fullWidth={true}
      value={getCounterValue(countGoods)}
      inputProps={{
        sx: {
          padding: 0,
          textAlign: 'center',
          lineHeight: isBasket ? '20px' : '24px',
          fontSize: isBasket ? '14px' : '16px',
          height: 'auto',
          maxWidth: '47px',
        },
      }}
      onKeyPress={(event) => {
        if (!regExOnlyNumberKey.test(event.key)) {
          event.preventDefault();
        }
      }}
      onChange={handleCountChange}
      onBlur={handleSubmitCount}
    />
  );
};
