import {
  CombinedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import {
  CreateOrderState,
  createOrderSlice,
} from '@/services/createOrderSlice';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import { apiSlice } from '@/services/api/apiSlice';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';

interface ReducerState {
  order: CreateOrderState;
  api: CombinedState<any>;
}

export const rootReducer = combineReducers({
  order: createOrderSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const config: PersistConfig<ReducerState> = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['order'],
};

const reducer = persistReducer(config, rootReducer);

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(apiSlice.middleware)
      .concat(logger);
  },
  devTools: true,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
