import { Grid, Typography } from '@mui/material';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  totalSum: number;
}

export const OrderTotalSumInfo: FC<Props> = (props: Props) => {
  const { totalSum } = props;
  return (
    <Grid container={true} direction={'row'} justifyContent={'space-between'}>
      <Grid item={true}>
        <Typography variant={'h1'} lineHeight={'24px'}>
          {'Итог'}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant={'h1'} lineHeight={'24px'}>
          {formatCurrency(totalSum)}
        </Typography>
      </Grid>
    </Grid>
  );
};
