import { DesktopDatePicker, MobileDatePicker } from '@mui/lab';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ParseableDate } from '@mui/lab/internal/pickers/constants/prop-types';
import { maxBirthYear, minBirthYear } from '@/validation/yearSchema';
import { setYear } from 'date-fns';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { FC, useRef } from 'react';

interface Props {
  value: ParseableDate<Date>;
  onChange: (
    date: Date | null,
    keyboardInputValue?: string | undefined
  ) => void;
  error?: any;
  helperText?: any;
  isMobile?: boolean;
}

export const ClientYearField: FC<Props> = (props: Props) => {
  const { value, onChange, error, helperText, isMobile } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClickDate = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };
  return isMobile ? (
    <MobileDatePicker
      inputRef={inputRef}
      views={['year']}
      label={'Год рождения'}
      DialogProps={{
        PaperProps: {
          sx: {
            padding: '0px',
            '& div div .MuiFormControl-root': {
              marginTop: '12px',
            },
          },
        },
      }}
      cancelText={'Отмена'}
      okText={'Принять'}
      maxDate={setYear(new Date(), maxBirthYear)}
      minDate={setYear(new Date(), minBirthYear)}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          fullWidth={true}
          {...params}
          InputLabelProps={{
            shrink: false,
          }}
          error={error}
          helperText={helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <IconButton onClick={() => handleClickDate()}>
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  ) : (
    <DesktopDatePicker
      views={['year']}
      label={'Год рождения'}
      maxDate={setYear(new Date(), maxBirthYear)}
      minDate={setYear(new Date(), minBirthYear)}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: false,
          }}
          error={error}
          FormHelperTextProps={{
            sx: {
              position: 'absolute',
              transform: 'translate(0, 48px)',
            },
          }}
          helperText={helperText}
        />
      )}
    />
  );
};
