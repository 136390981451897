import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const MinusIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 13 2'}
    sx={{ width: '13px', height: '2px' }}
    {...props}>
    <rect
      x={'13'}
      y={'2'}
      width={'13'}
      height={'2'}
      rx={'0.999999'}
      transform={'rotate(-180 13 2)'}
      fill={'#009F53'}
    />
  </SvgIcon>
);
