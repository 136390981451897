import { Box, ClickAwayListener, Collapse, Grid, Slide } from '@mui/material';
import { GoodsDTO } from '@/types/order/GoodsDTO';
import { MainCategoryButton } from '@/components/common/button/MainCategoryButton';
import { NavigationDrawer } from '@/components/common/NavigationDrawer';
import React, { FC, useEffect, useState } from 'react';
import SearchPanelGoods from '@/components/order/SearchPanelGoods';

interface Props {
  goodsDTO: GoodsDTO[] | undefined;
  valueSearchPanel: GoodsDTO | null;
  openSearchPanel: boolean;
  setOpenSearchPanel: (isOpen: boolean) => void;
  handleChangeGoods: (value: GoodsDTO | null) => void;
  isFetching: boolean;
  showHeader: boolean;
  handleMainButtonClick: () => void;
}

export const StickMobileHeader: FC<Props> = (props: Props) => {
  const {
    goodsDTO,
    valueSearchPanel,
    openSearchPanel,
    setOpenSearchPanel,
    handleChangeGoods,
    isFetching,
    showHeader,
    handleMainButtonClick,
  } = props;
  const [showSearchPanel, setShowSearchPanel] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(true);
  useEffect(() => {
    if (!showSearchPanel && !showButton) {
      setTimeout(() => {
        setShowButton(true);
      }, 400);
    }
  }, [showSearchPanel, showButton]);

  useEffect(() => {
    if (!showHeader) {
      setShowSearchPanel(false);
    }
  }, [showHeader]);

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100vw',
        height: 0,
        zIndex: 100,
      }}>
      <ClickAwayListener onClickAway={() => setShowSearchPanel(false)}>
        <Slide direction={'down'} in={showHeader} timeout={400}>
          <Grid
            container={true}
            justifyContent={'space-between'}
            alignItems={'center'}
            wrap={'nowrap'}
            columnSpacing={1}
            sx={{
              minHeight: '65px',
              padding: '12px 16px 5px',
              background: '#ffffff',
            }}>
            <Grid item={true}>
              <NavigationDrawer sx={{ position: 'relative' }} />
            </Grid>
            {showButton && (
              <Grid item={true}>
                <MainCategoryButton onClick={handleMainButtonClick} />
              </Grid>
            )}
            <Grid
              item={true}
              sx={{
                width: showSearchPanel ? '100%' : '32px',
                transition: 'width 0.4s ease-in-out',
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
              <Collapse
                orientation={'horizontal'}
                in={showSearchPanel}
                collapsedSize={60}
                timeout={400}
                sx={{
                  '&.MuiCollapse-root': {
                    width: '100% !important',
                  },
                  '.MuiCollapse-wrapper': {
                    width: '100%',
                  },
                  '.MuiCollapse-wrapperInner': {
                    width: '100%',
                  },
                }}>
                <SearchPanelGoods
                  goods={goodsDTO}
                  value={valueSearchPanel}
                  isFetching={isFetching}
                  open={openSearchPanel}
                  setOpen={setOpenSearchPanel}
                  handleChangeGoods={handleChangeGoods}
                  handleHidePanel={() => setShowSearchPanel(false)}
                  handleClickEndAdornment={() => {
                    setShowSearchPanel(true);
                    setShowButton(false);
                  }}
                  showOnlyEndAdornment={showButton}
                  disabled={showButton}
                />
              </Collapse>
            </Grid>
          </Grid>
        </Slide>
      </ClickAwayListener>
    </Box>
  );
};
