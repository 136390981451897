import {
  Autocomplete,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { CancelIcon } from '@/app/icons/CancelIcon';
import { GoodsDTO } from '@/types/order/GoodsDTO';
import { SearchIcon } from '@/app/icons/SearchIcon';
import React, { FC, Fragment } from 'react';

interface Props {
  goods: GoodsDTO[] | undefined;
  value: GoodsDTO | null;
  open: boolean;
  disabled?: boolean;
  setOpen: (isOpen: boolean) => void;
  handleChangeGoods: (value: GoodsDTO | null) => void;
  isFetching: boolean;
  handleHidePanel?: () => void;
  showOnlyEndAdornment?: boolean;
  handleClickEndAdornment?: () => void;
}

const SearchPanelGoods: FC<Props> = (props: Props) => {
  const {
    goods,
    open,
    isFetching,
    setOpen,
    value,
    handleChangeGoods,
    handleHidePanel,
    showOnlyEndAdornment,
    handleClickEndAdornment,
    disabled,
  } = props;

  const handleBlur = () => {
    if (handleHidePanel && value === null) {
      handleHidePanel();
    }
  };
  return (
    <Autocomplete
      id={'select-region'}
      open={open && !disabled}
      loading={isFetching}
      options={goods || []}
      value={value}
      getOptionLabel={(option: GoodsDTO) => option.name}
      isOptionEqualToValue={(option: GoodsDTO, value: GoodsDTO) =>
        option.uuid === value.uuid
      }
      onInputChange={(event, newInputValue, reason) => {
        if (newInputValue.length > 2 && reason === 'input') {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }}
      onClose={() => setOpen(false)}
      onChange={(event, value) => handleChangeGoods(value)}
      fullWidth={true}
      noOptionsText={'Не найдено'}
      loadingText={'Загрузка ...'}
      clearText={''}
      clearIcon={<CancelIcon sx={{ width: '32px', height: '32px' }} />}
      popupIcon={null}
      PaperComponent={({ children }) => (
        <Paper sx={{ marginTop: '3px' }}>{children}</Paper>
      )}
      onBlur={handleBlur}
      disabled={disabled}
      sx={(theme: Theme) => ({
        '.MuiAutocomplete-inputRoot': {
          flexWrap: 'nowrap',
          justifyContent: 'flex-end',
        },
        '.MuiOutlinedInput-root input': {
          display: showOnlyEndAdornment ? 'none' : 'block',
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: showOnlyEndAdornment
            ? 'transparent !important'
            : theme.colors.borderColor,
        },
      })}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={'Поиск лекарств...'}
          InputProps={{
            ...params.InputProps,
            style: { paddingRight: '8xp' },
            endAdornment: (
              <Fragment>
                {isFetching ? (
                  <CircularProgress
                    color={'inherit'}
                    size={20}
                    sx={{ marginRight: '-31px' }}
                  />
                ) : value ? null : (
                  <SearchIcon
                    onClick={() => {
                      if (showOnlyEndAdornment && handleClickEndAdornment) {
                        handleClickEndAdornment();
                      }
                    }}
                    sx={{
                      width: (theme: Theme) => theme.spacing(4),
                      height: (theme: Theme) => theme.spacing(4),
                      color: (theme: Theme) => theme.colors.primary,
                      marginRight: '-31px',
                    }}
                  />
                )}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option: GoodsDTO) => {
        return (
          <li {...props} key={option.id}>
            <Grid
              container={true}
              direction={'column'}
              sx={{ padding: '8px 0px' }}>
              <Grid item={true}>
                <Typography component={'div'} variant={'body1'}>
                  {option.name}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default SearchPanelGoods;
