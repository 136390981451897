import { AuthorDTO } from '@/types/AuthorDTO';
import { DeliveryIcon } from '@/app/icons/DeliveryIcon';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { Grid, Theme, Typography } from '@mui/material';
import { SenderDTO } from '@/types/SenderDTO';
import {
  formatAuthorFullName,
  formatCurrency,
  formatFullName,
  formatPhoneNumber,
  parseFullName,
} from '@/utils/string-utils';
import React, { FC } from 'react';
import theme from '@/app/theme';

interface Props {
  sender: SenderDTO;
  author: AuthorDTO;
  department: DepartmentDTO;
}

export const BasketDeliveryMobile: FC<Props> = (props: Props) => {
  const { sender, author, department } = props;
  const authorFullName = parseFullName(author.name);

  return (
    <Grid
      container={true}
      direction={'column'}
      sx={(theme: Theme) => ({
        padding: theme.spacing(1.5, 2),
        borderRadius: theme.spacing(2),
        backgroundColor: theme.colors.secondaryGrayBackground,
      })}>
      <Grid item={true} mb={1.5}>
        <Grid container={true} alignItems={'center'}>
          <Grid item={true} pr={2}>
            <DeliveryIcon
              sx={(theme: Theme) => ({
                display: 'block',
                width: theme.spacing(2.25),
                height: theme.spacing(1.75),
              })}
            />
          </Grid>
          <Grid item={true}>
            <Typography variant={'body2'}>{'Доставка'}</Typography>
          </Grid>
          <Grid item={true} xs={true}>
            <Typography variant={'h5'} align={'right'}>
              {formatCurrency(department.deliveryCost)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} mb={1.5}>
        <Typography variant={'body2'} color={theme.colors.grayText}>
          {`${department.name}, ${formatFullName(
            sender.lastName,
            sender.firstName,
            sender.middleName
          )}, ${sender.age} г.`}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant={'body2'} color={theme.colors.grayText}>
          {`Отправитель: ${formatAuthorFullName(
            authorFullName?.lastName,
            authorFullName?.firstName,
            authorFullName?.middleName
          )}, ${author.email}, ${formatPhoneNumber(author.phone)}`}
        </Typography>
      </Grid>
    </Grid>
  );
};
