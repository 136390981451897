export const MESSAGE_REQUIRED = 'Обязательное поле';
export const MESSAGE_INVALID_EMAIl = 'Некорректный адрес электронной почты';
export const MESSAGE_UNKNOWN = 'Неизвестная ошибка';
export const MESSAGE_FULL_NAME_REQUIRED = 'Укажите, как минимум, Фамилию и Имя';
export const MESSAGE_FULL_NAME_ALLOWED_CHARACTERS = 'Недопустимые символы';
export const MESSAGE_FETCH_ERROR = 'Ошибка соединения с сервером';
export const MESSAGE_BASKET_ERROR =
  'Некоторых товаров из корзины нет в наличии';
export const MESSAGE_PRODUCT_ERROR = 'Товара нет в наличии';
export const MESSAGE_ORDER_NOT_FOUND = 'Запрашиваемый заказ не найден';
export const MESSAGE_INVALID_PHONE = 'Некорректный формат номера';
export const MESSAGE_DEPARTMENT_INVALID_PROPERTIES =
  'В настоящий момент учреждение не подключено к системе';
export const MESSAGE_ERROR_DATA_LOAD = 'Ошибка загрузки данных';

export const allowedFullNameCharsRegExp = /^[А-яёЁa-zA-Z-\s]+$/g;

export const fullNameRegExp =
  /^\s*(?<lastName>[А-яёЁa-zA-Z-]+)\s+(?<firstName>[А-яёЁa-zA-Z-]+)(?<middleName>[А-яёЁa-zA-Z-\s]+)?\s*$/;

export const regExOnlyNumberKey = /[0-9+]/;

export const regExEmailCharacters = /^[a-zA-Z-0-9@.-_]+$/;
