import {
  Autocomplete,
  CircularProgress,
  Paper,
  TextField,
} from '@mui/material';
import { RegionDTO } from '@/types/RegionDTO';
import React, { FC, Fragment } from 'react';

interface Props {
  open: boolean;
  loading?: boolean;
  options: RegionDTO[] | undefined;
  value: RegionDTO | null;
  onOpen: () => void;
  onClose: () => void;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: RegionDTO | null,
    reason: string
  ) => void;
  error?: any;
  helperText?: any;
}

export const RegionField: FC<Props> = (props: Props) => {
  const {
    open,
    loading,
    options,
    value,
    onOpen,
    onClose,
    onChange,
    error,
    helperText,
  } = props;
  return (
    <Autocomplete
      id={'select-region'}
      open={open}
      loading={loading}
      options={options || []}
      value={value}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      onOpen={onOpen}
      onClose={onClose}
      onChange={onChange}
      fullWidth={true}
      blurOnSelect={true}
      noOptionsText={'Не найдено'}
      loadingText={'Загрузка ...'}
      openText={''}
      clearText={''}
      PaperComponent={({ children }) => (
        <Paper sx={{ marginTop: '3px' }}>{children}</Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              right: '9px',
            },
          }}
          label={'Выберите регион'}
          InputLabelProps={{
            shrink: false,
          }}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color={'inherit'} size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};
