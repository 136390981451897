import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const AlarmIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <mask
      id={'mask0_962_780'}
      style={{ maskType: 'alpha' }}
      maskUnits={'userSpaceOnUse'}
      x={'0'}
      y={'0'}
      width={'24'}
      height={'24'}>
      <rect width={'24'} height={'24'} fill={'#d9d9d9'} />
    </mask>
    <g mask={'url(#mask0_962_780)'}>
      <path
        d={
          'M12.0001 21.9996C10.7501 21.9996 9.57943 21.7623 8.4881 21.2876C7.3961 20.8123 6.4461 20.1703 5.6381 19.3616C4.82943 18.5536 4.18743 17.6036 3.7121 16.5116C3.23743 15.4203 3.0001 14.2496 3.0001 12.9996C3.0001 11.7496 3.23743 10.5786 3.7121 9.48661C4.18743 8.39528 4.82943 7.44528 5.6381 6.63661C6.4461 5.82861 7.3961 5.18728 8.4881 4.71261C9.57943 4.23728 10.7501 3.99961 12.0001 3.99961C13.2501 3.99961 14.4211 4.23728 15.5131 4.71261C16.6044 5.18728 17.5544 5.82861 18.3631 6.63661C19.1711 7.44528 19.8128 8.39528 20.2881 9.48661C20.7628 10.5786 21.0001 11.7496 21.0001 12.9996C21.0001 14.2496 20.7628 15.4203 20.2881 16.5116C19.8128 17.6036 19.1711 18.5536 18.3631 19.3616C17.5544 20.1703 16.6044 20.8123 15.5131 21.2876C14.4211 21.7623 13.2501 21.9996 12.0001 21.9996ZM14.8001 17.1996L16.2001 15.7996L13.0001 12.5996V7.99961H11.0001V13.3996L14.8001 17.1996ZM5.6001 2.34961L7.0001 3.74961L2.7501 7.99961L1.3501 6.59961L5.6001 2.34961ZM18.4001 2.34961L22.6501 6.59961L21.2501 7.99961L17.0001 3.74961L18.4001 2.34961ZM12.0001 19.9996C13.9501 19.9996 15.6041 19.3203 16.9621 17.9616C18.3208 16.6036 19.0001 14.9496 19.0001 12.9996C19.0001 11.0496 18.3208 9.39528 16.9621 8.03661C15.6041 6.67861 13.9501 5.99961 12.0001 5.99961C10.0501 5.99961 8.3961 6.67861 7.0381 8.03661C5.67943 9.39528 5.0001 11.0496 5.0001 12.9996C5.0001 14.9496 5.67943 16.6036 7.0381 17.9616C8.3961 19.3203 10.0501 19.9996 12.0001 19.9996Z'
        }
        fill={'#009f53'}
      />
    </g>
  </SvgIcon>
);
