import { Box, Divider, Grid, Skeleton } from '@mui/material';
import { CategoriesTabMobile } from '@/components/order/CategoriesTabMobile';
import { ShowcaseDTO } from '@/types/order/ShowcaseDTO';
import React, { FC, Fragment } from 'react';

interface Props {
  data: ShowcaseDTO[];
  loading: boolean;
  setCategory: (categoryName: string) => void;
}

export const NavigationPanelMobile: FC<Props> = (props: Props) => {
  const { data, loading, setCategory } = props;
  return (
    <Grid
      container={true}
      direction={'column'}
      sx={{ height: '100%' }}
      wrap={'nowrap'}>
      {(loading ? Array.from(new Array(4)) : data).map(
        (item: ShowcaseDTO, index: number) => (
          <Grid item={true} key={index}>
            {loading ? (
              <Box pl={2}>
                <Skeleton height={'20px'} width={'80%'} />
              </Box>
            ) : (
              <Fragment>
                <CategoriesTabMobile
                  categoryName={item.categoryName}
                  handleSetCategory={setCategory}
                />
                {index === data.length - 1 && <Divider />}
              </Fragment>
            )}
          </Grid>
        )
      )}
    </Grid>
  );
};
