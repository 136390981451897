import { GoodsDTO } from '@/types/order/GoodsDTO';
import { Grid, Theme, Typography } from '@mui/material';
import {
  expirationDateFormat,
  requiredPrescriptionFormat,
} from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  goods: GoodsDTO;
}

export const GoodsAddInfo: FC<Props> = (props: Props) => {
  const { goods } = props;

  return (
    <Grid container={true} direction={'column'} wrap={'nowrap'} rowSpacing={2}>
      {goods.formRelease && (
        <Grid item={true}>
          <Typography variant={'body2'} fontWeight={500}>
            {'Форма выпуска'}
          </Typography>
          <Typography
            variant={'body2'}
            color={(theme: Theme) => theme.colors.grayText}>
            {goods.formRelease}
          </Typography>
        </Grid>
      )}
      {goods.substanceINN && (
        <Grid item={true}>
          <Typography variant={'body2'} fontWeight={500}>
            {'Действующие вещества'}
          </Typography>
          <Typography
            variant={'body2'}
            color={(theme: Theme) => theme.colors.grayText}>
            {goods.substanceINN}
          </Typography>
        </Grid>
      )}
      {goods.expirationDate > 0 && (
        <Grid item={true}>
          <Typography variant={'body2'} fontWeight={500}>
            {'Срок годности'}
          </Typography>
          <Typography
            variant={'body2'}
            color={(theme: Theme) => theme.colors.grayText}>
            {expirationDateFormat(goods.expirationDate)}
          </Typography>
        </Grid>
      )}
      {goods.requiredCold && (
        <Grid item={true}>
          <Typography variant={'body2'} fontWeight={500}>
            {'Условия хранения'}
          </Typography>
          <Typography
            variant={'body2'}
            color={(theme: Theme) => theme.colors.grayText}>
            {'Хранить в охлажденом ввиде'}
          </Typography>
        </Grid>
      )}
      <Grid item={true}>
        <Typography variant={'body2'} fontWeight={500}>
          {requiredPrescriptionFormat(goods.requiredPrescription)}
        </Typography>
      </Grid>
    </Grid>
  );
};
