import { GoodsDTO } from '@/types/order/GoodsDTO';
import { Grid } from '@mui/material';
import GoodsTable from '@/components/order/GoodsTable';
import NavigationPanelGoods from '@/components/order/NavigationPanelGoods';
import React, { FC } from 'react';

interface Props {
  currentCategories: string[];
  loading: boolean;
  handleSetCategory: (categoryName: string) => void;
  currentCategory: string | undefined;
  currentGoods: GoodsDTO[];
  valueSearchPanel: GoodsDTO | null;
  isCanShowMore: boolean;
  handleShowMoreGoods: (pageSize: number) => void;
}

const ShowcaseCreateForm: FC<Props> = (props: Props) => {
  const {
    currentCategories,
    loading,
    handleSetCategory,
    currentCategory,
    currentGoods,
    valueSearchPanel,
    isCanShowMore,
    handleShowMoreGoods,
  } = props;

  return (
    <Grid
      container={true}
      direction={'column'}
      sx={{ height: '100%' }}
      wrap={'nowrap'}>
      <Grid item={true} xs={true}>
        <Grid
          container={true}
          direction={'row'}
          sx={{ height: '100%' }}
          columnSpacing={7}>
          <Grid item={true}>
            <NavigationPanelGoods
              categories={currentCategories}
              currentCategory={currentCategory}
              setCategory={handleSetCategory}
              loading={loading}
            />
          </Grid>
          <Grid item={true} xs={true}>
            <Grid
              container={true}
              direction={'column'}
              sx={{ height: '100%' }}
              wrap={'nowrap'}>
              <Grid item={true} xs={true}>
                <GoodsTable
                  loading={loading}
                  goods={currentGoods}
                  isCanShowMore={isCanShowMore && !valueSearchPanel}
                  handleShowMoreGoods={handleShowMoreGoods}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShowcaseCreateForm;
