import Logo from '@assets/icons/logo.svg';
import React, { FC } from 'react';

interface Props {
  width: string;
  height: string;
}

export const LogoIcon: FC<Props> = (props: Props) => {
  const { width, height } = props;
  return (
    <img src={Logo} alt={'logo'} style={{ height: height, width: width }} />
  );
};
