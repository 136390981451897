import { BasketDTO } from '@/types/order/BasketDTO';
import { GoodsDTO } from '@/types/order/GoodsDTO';
import { OrderDTO } from '@/types/order/OrderDTO';
import { OrderItemDTO } from '@/types/order/OrderItemDTO';
import { PageRequestDTO } from '@/types/page/PageRequestDTO';
import { ShowcaseDTO } from '@/types/order/ShowcaseDTO';

export const SHOW_MORE_SIZE = 50;

export const getGoodsByCategoryName = (
  goodsJSON?: ShowcaseDTO[],
  categoryName?: string,
  pageRequest?: PageRequestDTO
): GoodsDTO[] => {
  let goods: GoodsDTO[] = [];
  if (goodsJSON && categoryName) {
    goodsJSON.map((dto) => {
      if (dto.categoryName === categoryName) {
        if (pageRequest) {
          goods = dto.goodsDTOS.slice(
            pageRequest.pageNumber *
              (pageRequest.oldPageSize
                ? pageRequest.oldPageSize
                : pageRequest.pageSize),
            (pageRequest.pageNumber + 1) * pageRequest.pageSize
          );
        } else {
          goods = dto.goodsDTOS.slice();
        }
      }
    });
  }
  return goods;
};

export const getAllCategories = (goodsJSON?: ShowcaseDTO[]): string[] => {
  const categories: string[] = [];
  if (goodsJSON) {
    goodsJSON.map((dto) => {
      categories.push(dto.categoryName);
    });
  }
  return categories;
};

export const getAllGoods = (goodsJSON?: ShowcaseDTO[]): GoodsDTO[] => {
  const goods: GoodsDTO[] = [];
  if (goodsJSON) {
    return goodsJSON.reduce((newArray, dto) => {
      return newArray.concat(dto.goodsDTOS);
    }, goods);
  }
  return goods;
};

export const countAllGoodsInBasket = (basket: BasketDTO) => {
  let counter = 0;
  basket.goodsBasket.map((product) => (counter = counter + product.count));
  return counter;
};

export const getTotalPages = (goods: GoodsDTO[], pageSize: number) => {
  return Math.ceil(goods.length / pageSize);
};

export const isEndGoodsArray = (
  allGoodsInCategory: GoodsDTO[],
  pageRequest: PageRequestDTO
): boolean => {
  if (pageRequest.oldPageSize) {
    return (
      allGoodsInCategory.length >
      pageRequest.pageNumber * pageRequest.oldPageSize +
        (pageRequest.pageNumber + 1) * pageRequest.pageSize
    );
  } else {
    return false;
  }
};

export const isIOS = (): boolean => {
  return (
    // iOS detection
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.userAgent) ||
    // macOS detection
    navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
    navigator.userAgent.toUpperCase().indexOf('MAC') >= 0 ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isYandexBrowser = (): boolean => {
  return navigator.userAgent.indexOf('YaBrowser') >= 0;
};

/**
 * Calculates the total sum of the approved order items and includes the order commission.
 *
 * @param order The order for which to calculate the total sum.
 * @returns The total sum of the approved order items and order commission.
 */
export const calculateOrderTotalSum = (order: OrderDTO | undefined): number => {
  let totalSum = 0;
  if (order) {
    if (order.orderItems && order.orderItems.length > 0) {
      order.orderItems
        .filter((item: OrderItemDTO) => item.approved)
        .map((item: OrderItemDTO) => {
          totalSum += item.price * item.quantity;
        });
    }
    totalSum += order.commission;
  }
  return totalSum;
};
