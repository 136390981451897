import { Tab, TabProps, Theme } from '@mui/material';
import React, { FC } from 'react';

interface Props extends TabProps {
  label: string;
}

export const CategoriesTab: FC<Props> = (props: Props) => {
  return (
    <Tab
      disableRipple={true}
      sx={{
        '&.MuiTab-root': {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 500,
          alignItems: 'flex-start',
          textAlign: 'left',
          maxWidth: (theme: Theme) => theme.spacing(30),
          marginBottom: (theme: Theme) => theme.spacing(1),
          color: (theme: Theme) => theme.colors.textColor,
          borderRadius: (theme: Theme) => theme.spacing(0.75),
          padding: (theme: Theme) => theme.spacing(1),
          minHeight: 'auto',
          '&:hover': {
            backgroundColor: (theme: Theme) => theme.colors.buttonGrayHover,
          },
          '&.Mui-selected': {
            backgroundColor: (theme: Theme) => theme.colors.alert,
          },
        },
      }}
      {...props}
    />
  );
};
