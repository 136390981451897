import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { OrderItemDTO } from '@/types/order/OrderItemDTO';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  goods: OrderItemDTO[];
  commission: number;
  title?: string;
}

export const OrderTableInfo: FC<Props> = (props: Props) => {
  const { goods, commission, title } = props;
  return (
    <Grid container={true} direction={'column'}>
      <Grid
        item={true}
        pb={2}
        sx={{
          borderBottom: (theme: Theme) =>
            '1px solid ' + theme.colors.borderColor,
        }}>
        <Typography
          variant={'body2'}
          color={(theme: Theme) => theme.colors.grayText}>
          {title || 'Товары в заказе'}
        </Typography>
      </Grid>
      <Grid item={true}>
        <TableContainer>
          <Table>
            <TableBody>
              {goods?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={(theme: Theme) => ({
                      padding: theme.spacing(1.75, 3.5, 1.75, 0),
                    })}>
                    <Typography variant={'body2'}>{item.name}</Typography>
                  </TableCell>
                  <TableCell
                    sx={(theme: Theme) => ({
                      width: theme.spacing(12),
                      padding: theme.spacing(1.75, 3.5, 1.75, 0),
                    })}>
                    <Typography
                      variant={'body2'}
                      align={
                        'right'
                      }>{`${item.quantity} ${item.measureUnit}`}</Typography>
                  </TableCell>
                  <TableCell
                    sx={(theme: Theme) => ({
                      width: theme.spacing(12),
                      padding: theme.spacing(1.75, 0, 1.75, 0),
                    })}>
                    <Typography
                      variant={'body2'}
                      fontWeight={500}
                      align={'right'}>
                      {formatCurrency(item.price)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  sx={(theme: Theme) => ({
                    padding: theme.spacing(1.75, 3.5, 1.75, 0),
                    borderWidth: theme.spacing(0),
                  })}>
                  <Typography variant={'body2'}>{'Доставка'}</Typography>
                </TableCell>
                <TableCell
                  sx={(theme: Theme) => ({
                    width: theme.spacing(12),
                    padding: theme.spacing(1.75, 3.5, 1.75, 0),
                    borderWidth: theme.spacing(0),
                  })}
                />
                <TableCell
                  sx={(theme: Theme) => ({
                    width: theme.spacing(12),
                    padding: theme.spacing(1.75, 0, 1.75, 0),
                    borderWidth: theme.spacing(0),
                  })}>
                  <Typography
                    variant={'body2'}
                    fontWeight={500}
                    align={'right'}>
                    {formatCurrency(commission)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
