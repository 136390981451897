import { Theme, Typography } from '@mui/material';
import { formatPhoneNumber } from '@/utils/string-utils';
import React, { FC, Fragment } from 'react';

interface Props {
  tittle: string;
  text?: string;
  isPhoneNumber?: boolean;
}

export const ClientInfoItem: FC<Props> = (props: Props) => {
  const { tittle, text, isPhoneNumber } = props;
  return (
    <Fragment>
      <Typography
        variant={'body2'}
        color={(theme: Theme) => theme.colors.grayText}
        paddingBottom={0.5}>
        {tittle}
      </Typography>
      <Typography variant={'body1'}>
        {isPhoneNumber ? formatPhoneNumber(text) : text}
      </Typography>
    </Fragment>
  );
};
