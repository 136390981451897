import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  href: string | undefined;
  mainText: string;
  secondaryText: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    stroke: theme.colors.secondaryGrayText,
    color: theme.colors.secondaryGrayText,
    '&:hover': {
      stroke: theme.colors.primary,
      color: theme.colors.primary,
      cursor: 'pointer',
    },
    '&:active': {
      stroke: theme.colors.primary,
      color: theme.colors.primary,
    },
  },
}));

export const FooterItem: FC<Props> = (props: Props) => {
  const { icon, href, mainText, secondaryText } = props;
  const classes = useStyles();
  return (
    <a
      href={href}
      target={'_blank'}
      rel={'noopener noreferrer'}
      className={classes.link}>
      <Grid container={true} sx={{ color: 'inherit', stroke: 'inherit' }}>
        <Grid item={true} pr={1}>
          {icon}
        </Grid>
        <Grid item={true}>
          <Typography
            variant={'body3'}
            fontSize={'13px'}
            fontWeight={500}
            color={'inherit'}
            component={'p'}>
            {mainText}
          </Typography>
          <Typography
            variant={'body3'}
            fontSize={'10px'}
            component={'p'}
            color={(theme: Theme) => theme.colors.secondaryGrayText}>
            {secondaryText}
          </Typography>
        </Grid>
      </Grid>
    </a>
  );
};
