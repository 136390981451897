import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const HomeIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 578 604'}
    sx={{ width: '578px', height: '604px' }}
    {...props}>
    <path
      opacity={'0.2'}
      d={
        'M531.216 326.666C543.965 214.226 463.306 112.722 351.059 99.9509C238.812 87.1797 137.483 167.977 124.733 280.417C111.984 392.857 192.643 494.361 304.89 507.132C417.137 519.903 518.467 439.105 531.216 326.666Z'
      }
      fill={'#D1DDE8'}
    />
    <path
      d={
        'M466.48 595.967C520.295 595.967 563.922 552.266 563.922 498.358C563.922 444.45 520.295 400.749 466.48 400.749C412.664 400.749 369.038 444.45 369.038 498.358C369.038 552.266 412.664 595.967 466.48 595.967Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M305.34 468.867L233.876 344.533L116.249 418.228L189.142 545.242C190.761 548.453 192.758 551.458 195.091 554.194C215.198 577.9 255.333 576.807 284.739 551.774C311.938 528.51 320.355 492.833 305.34 468.867Z'
      }
      fill={'#009F53'}
    />
    <path
      d={
        'M261.282 504.856C267.74 499.362 273.412 493.005 278.142 485.964C277.804 485.365 277.518 484.741 277.128 484.142L249.462 435.845C245.133 441.807 240.109 447.232 234.499 452.005C202.625 479.172 159.164 480.369 137.239 454.763L171.582 514.614C194.909 532.647 232.707 529.212 261.282 504.856Z'
      }
      fill={'#00CC95'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M237.123 568.116C234.468 564.971 232.185 561.531 230.317 557.863L146.903 412.503L239.513 354.474L233.798 344.533L116.171 418.228L189.064 545.242C190.683 548.453 192.681 551.458 195.013 554.194C205.768 566.893 222.29 572.409 239.799 571.004C238.889 570.067 237.98 569.104 237.123 568.116Z'
      }
      fill={'url(#paint0_linear_3247_87506)'}
    />
    <path
      d={
        'M212.719 426.778C242.114 401.727 249.627 362.208 229.499 338.509C209.372 314.811 169.226 315.907 139.832 340.958C110.437 366.009 102.924 405.529 123.052 429.227C143.179 452.925 183.324 451.829 212.719 426.778Z'
      }
      fill={'#009F53'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M209.302 422.729C235.932 400.034 242.733 364.226 224.493 342.75C206.253 321.273 169.879 322.261 143.249 344.956C116.619 367.651 109.817 403.459 128.057 424.935C146.297 446.411 182.672 445.424 209.302 422.729Z'
      }
      fill={'url(#paint1_linear_3247_87506)'}
    />
    <path
      d={
        'M251.826 411.618V400.038H249.228C247.024 395.632 243.629 391.935 239.429 389.367C235.229 386.8 230.393 385.466 225.474 385.518C220.554 385.569 215.747 387.004 211.602 389.659C207.457 392.314 204.139 396.081 202.027 400.533H199.429V412.113C199.362 415.64 200.001 419.144 201.31 422.419C202.619 425.694 204.57 428.672 207.049 431.178C209.528 433.683 212.484 435.664 215.741 437.005C218.999 438.345 222.491 439.017 226.013 438.98C229.534 438.944 233.012 438.199 236.241 436.792C239.47 435.384 242.384 433.342 244.811 430.785C247.237 428.229 249.127 425.211 250.368 421.909C251.608 418.608 252.175 415.091 252.034 411.566L251.826 411.618Z'
      }
      fill={'#D1DDE8'}
    />
    <path
      d={
        'M244.005 418.948C254.281 408.654 254.281 391.964 244.005 381.669C233.728 371.375 217.066 371.375 206.789 381.669C196.513 391.964 196.513 408.654 206.789 418.948C217.066 429.243 233.728 429.243 244.005 418.948Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M199.741 394.652C199.309 396.599 199.1 398.59 199.118 400.585C199.128 402.586 199.381 404.578 199.871 406.518L251.073 405.972C251.525 404.027 251.743 402.035 251.722 400.038C251.686 398.039 251.433 396.05 250.969 394.105L199.741 394.652Z'
      }
      fill={'white'}
    />
    <path
      d={
        'M208.678 358.091L198.053 353.485L196.988 356.087C192.07 356.309 187.314 357.911 183.262 360.71C179.209 363.509 176.024 367.393 174.068 371.918C172.113 376.443 171.467 381.428 172.203 386.303C172.939 391.178 175.028 395.748 178.232 399.492L177.167 401.964L187.766 406.57C194.1 408.959 201.109 408.811 207.338 406.157C213.566 403.504 218.534 398.548 221.21 392.32C223.885 386.092 224.062 379.071 221.704 372.716C219.346 366.361 214.634 361.16 208.548 358.195L208.678 358.091Z'
      }
      fill={'#D1DDE8'}
    />
    <path
      d={
        'M211.743 388.146C209.673 392.922 206.237 396.976 201.868 399.797C197.499 402.617 192.393 404.076 187.197 403.991C182 403.906 176.945 402.279 172.67 399.317C168.396 396.355 165.094 392.19 163.182 387.349C161.27 382.507 160.833 377.207 161.927 372.117C163.021 367.027 165.597 362.376 169.329 358.752C173.061 355.128 177.781 352.694 182.893 351.756C188.006 350.819 193.281 351.42 198.052 353.485C201.225 354.858 204.097 356.844 206.503 359.33C208.909 361.816 210.802 364.752 212.073 367.972C213.345 371.191 213.97 374.63 213.913 378.092C213.857 381.554 213.119 384.97 211.743 388.146Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M171.997 398.867C173.608 400.062 175.351 401.067 177.193 401.86C179.025 402.653 180.942 403.229 182.908 403.577L203.248 356.477C201.624 355.302 199.883 354.299 198.052 353.485C196.216 352.704 194.3 352.128 192.337 351.767L171.997 398.867Z'
      }
      fill={'white'}
    />
    <path
      d={
        'M187.116 432.644L184.518 421.377L181.921 422.001C178.799 418.191 174.672 415.336 170.009 413.761C165.346 412.186 160.336 411.955 155.548 413.094C150.761 414.234 146.389 416.697 142.932 420.204C139.474 423.711 137.07 428.121 135.992 432.93L133.395 433.555L135.992 444.823C136.791 448.189 138.243 451.364 140.266 454.168C142.29 456.972 144.845 459.349 147.785 461.163C150.726 462.978 153.994 464.195 157.404 464.744C160.814 465.294 164.299 465.165 167.659 464.365C171.019 463.565 174.189 462.111 176.988 460.084C179.787 458.057 182.16 455.498 183.972 452.552C185.784 449.606 186.998 446.332 187.546 442.917C188.095 439.501 187.966 436.01 187.168 432.644H187.116Z'
      }
      fill={'#D1DDE8'}
    />
    <path
      d={
        'M163.769 453.165C178.048 450.449 187.425 436.653 184.714 422.35C182.004 408.047 168.231 398.654 153.953 401.369C139.674 404.085 130.297 417.881 133.008 432.184C135.718 446.487 149.491 455.88 163.769 453.165Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M132.563 427.622C132.58 429.619 132.815 431.609 133.265 433.555C133.725 435.503 134.414 437.39 135.317 439.176L185.142 427.31C185.145 425.312 184.918 423.322 184.466 421.377C183.978 419.437 183.29 417.553 182.414 415.756L132.563 427.622Z'
      }
      fill={'white'}
    />
    <path
      d={
        'M233.875 302.924L231.278 291.656L228.68 292.281C225.558 288.47 221.431 285.615 216.768 284.04C212.106 282.466 207.095 282.235 202.308 283.374C197.52 284.513 193.149 286.977 189.691 290.484C186.234 293.991 183.829 298.401 182.752 303.21L180.154 303.835L182.752 315.102C184.364 321.9 188.606 327.778 194.545 331.443C200.483 335.108 207.632 336.26 214.418 334.645C221.205 333.03 227.073 328.78 230.731 322.832C234.39 316.883 235.54 309.722 233.927 302.924H233.875Z'
      }
      fill={'#D1DDE8'}
    />
    <path
      d={
        'M211.743 323.377C206.684 324.579 201.384 324.251 196.512 322.435C191.639 320.62 187.413 317.398 184.369 313.177C181.324 308.956 179.597 303.926 179.405 298.722C179.214 293.518 180.567 288.374 183.294 283.94C186.021 279.507 189.999 275.982 194.725 273.813C199.451 271.643 204.713 270.926 209.846 271.752C214.979 272.578 219.752 274.91 223.562 278.452C227.372 281.995 230.048 286.59 231.252 291.656C232.052 295.022 232.181 298.513 231.634 301.929C231.086 305.346 229.871 308.62 228.059 311.566C226.248 314.512 223.874 317.072 221.074 319.098C218.274 321.125 215.104 322.579 211.743 323.377Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M179.323 297.902C179.34 299.899 179.575 301.889 180.024 303.835C180.49 305.781 181.179 307.667 182.077 309.455L231.901 297.589C231.91 295.59 231.674 293.598 231.2 291.656C230.732 289.714 230.052 287.829 229.173 286.035L179.323 297.902Z'
      }
      fill={'white'}
    />
    <path
      d={
        'M170.309 211.82L167.711 200.553L165.113 201.177C161.994 197.367 157.868 194.511 153.207 192.935C148.546 191.358 143.537 191.125 138.75 192.262C133.963 193.398 129.591 195.858 126.132 199.362C122.673 202.866 120.266 207.273 119.185 212.08L116.587 212.731L119.185 223.999C119.983 227.365 121.435 230.54 123.459 233.344C125.482 236.148 128.037 238.525 130.978 240.34C133.918 242.154 137.187 243.371 140.597 243.92C144.007 244.47 147.491 244.341 150.852 243.541C154.212 242.742 157.382 241.287 160.181 239.26C162.98 237.233 165.353 234.674 167.164 231.728C168.976 228.783 170.191 225.508 170.739 222.093C171.288 218.677 171.159 215.186 170.361 211.82H170.309Z'
      }
      fill={'#D1DDE8'}
    />
    <path
      d={
        'M148.15 232.274C143.086 233.482 137.777 233.158 132.897 231.344C128.016 229.529 123.782 226.306 120.731 222.08C117.68 217.855 115.948 212.818 115.756 207.607C115.563 202.395 116.917 197.244 119.648 192.804C122.379 188.364 126.363 184.835 131.096 182.664C135.829 180.492 141.099 179.776 146.239 180.606C151.378 181.435 156.157 183.773 159.97 187.324C163.783 190.874 166.459 195.478 167.659 200.553C168.459 203.919 168.588 207.41 168.04 210.826C167.493 214.242 166.278 217.517 164.467 220.463C162.655 223.409 160.281 225.968 157.481 227.995C154.682 230.021 151.511 231.475 148.15 232.274Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M115.756 206.772C115.773 208.778 116.008 210.776 116.457 212.731C116.918 214.671 117.607 216.549 118.509 218.326L168.334 206.486C168.345 204.487 168.109 202.494 167.633 200.553C167.174 198.608 166.494 196.722 165.607 194.932L115.756 206.772Z'
      }
      fill={'white'}
    />
    <path
      d={
        'M127.368 274.924L116.483 263.526L113.522 266.883C86.765 256.839 52.2409 269.85 31.277 299.749C14.6254 323.507 10.8846 351.663 19.3273 373.184L16.288 376.592L32.4461 392.726C34.0838 394.236 35.8194 395.635 37.6416 396.916C65.2037 416.302 107.002 404.228 131.161 369.931C154.099 336.831 152.748 301.441 127.368 274.924Z'
      }
      fill={'#D1DDE8'}
    />
    <path
      d={
        'M62.1122 405.893C59.9716 405.715 57.8454 405.393 55.7476 404.931L15.8202 361.057L7.68921 352.106L10.6767 349.373L15.0149 354.161L62.1122 405.893Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M78.2962 405.243C76.6856 405.503 75.049 405.763 73.4384 405.893L24.5227 352.106L27.4842 349.373L78.2962 405.243Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M91.7265 401.47C90.3497 402.016 88.9469 402.51 87.57 402.927L41.3301 352.106L44.3176 349.373L91.7265 401.47Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M103.339 395.771C102.144 396.473 100.923 397.176 99.7018 397.827L58.1377 352.106L61.1512 349.373L103.339 395.771Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M113.6 388.615C112.535 389.447 111.495 390.28 110.378 391.061L74.9451 352.028L77.9324 349.295L113.6 388.615Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M122.692 380.079C121.783 381.094 120.821 382.031 119.86 382.968L91.7524 352.106L94.7659 349.373L122.692 380.079Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M130.797 370.087C129.992 371.206 129.186 372.325 128.199 373.392L93.8828 335.53L96.8443 332.797L130.797 370.087Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M137.759 358.689C137.135 359.938 136.434 361.291 135.707 362.41L93.8828 316.429L96.8443 313.827L137.759 358.689Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M143.396 345.808C142.902 347.239 142.383 348.671 141.785 350.076L93.8828 297.433L96.8443 294.831L143.396 345.808Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M146.903 330.611C146.695 332.329 146.435 334.02 146.098 335.816L93.8828 278.567L96.8443 275.809L146.903 330.611Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M147.215 317.913L97.8573 263.656L93.9087 259.311L96.8702 256.578L103.91 264.385L146.357 310.991C146.795 313.276 147.082 315.589 147.215 317.913Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M115.919 354.905C139.954 320.622 137.11 277.121 109.566 257.744C82.0219 238.367 40.2084 250.451 16.1727 284.734C-7.86289 319.018 -5.01879 362.518 22.5253 381.895C50.0694 401.272 91.883 389.189 115.919 354.905Z'
      }
      fill={'#E7EDF4'}
    />
    <path
      d={
        'M78.426 257.593C87.6968 257.48 96.7739 260.253 104.404 265.53C115.912 273.623 122.796 286.842 123.835 302.768C124.63 319.837 119.6 336.668 109.573 350.492C95.7531 370.191 74.4775 382.421 54.0072 382.421C44.7351 382.517 35.6607 379.735 28.0297 374.459C4.13035 357.648 1.81835 319.526 22.8342 289.496C36.6282 269.824 57.9299 257.593 78.4001 257.593H78.426ZM78.4001 254.991C57.774 254.991 35.5372 266.857 20.704 288.013C-1.22103 319.24 1.37667 358.923 26.4969 376.488C34.5494 382.095 44.1492 385.043 53.9552 384.919C74.5554 384.919 96.8182 373.027 111.651 351.897C133.55 320.671 130.979 280.987 105.858 263.422C97.8019 257.823 88.2049 254.876 78.4001 254.991Z'
      }
      fill={'white'}
    />
    <path
      d={
        'M374.44 565.643L318.25 543.967C313.345 542.076 307.891 542.215 303.087 544.353C298.284 546.49 294.525 550.451 292.636 555.364C290.749 560.278 290.888 565.742 293.022 570.554C295.156 575.365 299.11 579.131 304.015 581.022L360.204 602.673C365.11 604.556 370.561 604.414 375.363 602.277C380.164 600.141 383.924 596.184 385.818 591.275C386.752 588.844 387.198 586.252 387.132 583.648C387.066 581.043 386.487 578.478 385.431 576.098C384.374 573.717 382.86 571.569 380.974 569.775C379.088 567.981 376.867 566.577 374.44 565.643Z'
      }
      fill={'#F6F8FA'}
    />
    <path
      d={
        'M374.44 565.643L346.332 554.818L332.097 591.848L360.204 602.673C362.631 603.609 365.219 604.057 367.819 603.992C370.419 603.927 372.981 603.349 375.359 602.292C377.736 601.235 379.882 599.719 381.674 597.831C383.467 595.943 384.87 593.72 385.805 591.288C386.74 588.857 387.187 586.265 387.122 583.66C387.057 581.056 386.48 578.489 385.425 576.108C384.369 573.727 382.856 571.577 380.971 569.781C379.087 567.986 376.867 566.58 374.44 565.643Z'
      }
      fill={'#009F53'}
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d={
          'M373.895 591.613L301.157 563.614C297.52 562.227 294.374 559.791 292.117 556.614C290.621 561.422 291.007 566.62 293.198 571.153C295.388 575.686 299.218 579.215 303.911 581.022L360.1 602.673C364.791 604.477 369.991 604.427 374.646 602.533C379.301 600.639 383.063 597.042 385.169 592.472C381.407 593.293 377.489 592.995 373.895 591.613Z'
        }
        fill={'#E7EDF4'}
      />
    </g>
    <path
      d={
        'M407.925 484.584L349.969 500.978C344.913 502.414 340.632 505.804 338.07 510.4C335.508 514.997 334.873 520.425 336.305 525.491C337.012 527.998 338.205 530.341 339.817 532.386C341.428 534.431 343.426 536.138 345.696 537.409C347.966 538.68 350.464 539.491 353.047 539.794C355.63 540.098 358.248 539.889 360.75 539.179L418.706 522.759C421.209 522.051 423.548 520.855 425.589 519.241C427.631 517.627 429.334 515.626 430.603 513.352C431.872 511.078 432.682 508.575 432.985 505.988C433.288 503.4 433.079 500.779 432.37 498.272C431.663 495.765 430.47 493.422 428.858 491.377C427.247 489.332 425.249 487.625 422.979 486.354C420.709 485.083 418.211 484.272 415.628 483.969C413.045 483.665 410.427 483.874 407.925 484.584Z'
      }
      fill={'white'}
    />
    <path
      d={
        'M407.925 484.584L378.96 492.781L389.741 530.982L418.706 522.759C423.759 521.327 428.038 517.942 430.6 513.35C433.163 508.758 433.8 503.334 432.37 498.272C430.94 493.21 427.562 488.924 422.978 486.357C418.393 483.79 412.979 483.152 407.925 484.584Z'
      }
      fill={'#009F53'}
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d={
          'M423.096 505.688L348.177 526.896C344.442 527.972 340.471 527.908 336.772 526.714C338.445 531.463 341.861 535.395 346.324 537.71C350.788 540.026 355.965 540.551 360.802 539.179L418.758 522.759C423.592 521.39 427.724 518.228 430.315 513.917C432.906 509.605 433.762 504.467 432.708 499.547C430.179 502.5 426.835 504.637 423.096 505.688Z'
        }
        fill={'#E7EDF4'}
      />
    </g>
    <path
      d={
        'M410.055 19.985H390.105V0H367.868V19.985H347.917V42.26H367.868V62.271H390.105V42.26H410.055V19.985Z'
      }
      fill={'#00CC95'}
    />
    <path
      d={
        'M269.725 163.341H256.164V149.757H241.072V163.341H227.511V178.46H241.072V192.017H256.164V178.46H269.725V163.341Z'
      }
      fill={'#009F53'}
    />
    <path
      d={
        'M578 120.222H564.466V106.639H549.373V120.222H535.812V135.341H549.373V148.925H564.466V135.341H578V120.222Z'
      }
      fill={'#00CC95'}
    />
    <path
      d={
        'M478.61 474.045L573.48 197.352C574.287 194.388 573.895 191.225 572.387 188.55C570.879 185.874 568.377 183.904 565.427 183.066L405.925 128.159C403.078 126.996 399.888 127.008 397.051 128.192C394.213 129.377 391.959 131.638 390.78 134.482L295.91 411.046C295.101 414.013 295.493 417.18 297.001 419.859C298.509 422.539 301.01 424.514 303.963 425.358L463.465 480.239C466.294 481.417 469.474 481.427 472.311 480.267C475.148 479.107 477.412 476.871 478.61 474.045Z'
      }
      fill={'#009F53'}
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M452.295 179.136L415.926 166.75C412.763 165.669 409.302 165.882 406.296 167.344C403.289 168.806 400.981 171.398 399.872 174.557C398.794 177.721 399.008 181.185 400.468 184.192C401.927 187.2 404.515 189.507 407.666 190.612L444.034 202.999C445.598 203.541 447.253 203.769 448.906 203.67C450.558 203.571 452.174 203.147 453.663 202.422C455.151 201.697 456.482 200.685 457.58 199.445C458.678 198.204 459.522 196.759 460.062 195.192C461.149 192.031 460.942 188.567 459.486 185.558C458.031 182.55 455.445 180.241 452.295 179.136Z'
        }
        fill={'#009F53'}
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M533.89 207.214L497.522 194.828C495.958 194.286 494.302 194.058 492.65 194.157C490.998 194.256 489.381 194.68 487.893 195.405C486.405 196.13 485.073 197.141 483.975 198.382C482.877 199.622 482.034 201.067 481.493 202.634C480.4 205.802 480.606 209.275 482.068 212.291C483.529 215.306 486.125 217.617 489.287 218.716L525.655 231.103C528.835 232.178 532.311 231.946 535.32 230.458C538.329 228.97 540.627 226.348 541.709 223.166C542.755 220.011 542.521 216.571 541.059 213.587C539.596 210.603 537.022 208.315 533.89 207.214Z'
        }
        fill={'#009F53'}
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M437.513 222.229L401.145 209.843C397.982 208.754 394.518 208.964 391.509 210.427C388.501 211.89 386.193 214.487 385.091 217.649C384.011 220.818 384.224 224.285 385.684 227.296C387.143 230.308 389.731 232.621 392.884 233.731L429.252 246.117C432.415 247.206 435.879 246.996 438.888 245.533C441.896 244.07 444.204 241.473 445.307 238.311C446.393 235.143 446.183 231.673 444.723 228.659C443.262 225.645 440.67 223.334 437.513 222.229Z'
        }
        fill={'#009F53'}
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M519.109 250.307L482.74 237.92C479.561 236.845 476.085 237.077 473.076 238.565C470.066 240.053 467.768 242.675 466.686 245.857C465.614 249.039 465.846 252.517 467.331 255.527C468.817 258.538 471.435 260.834 474.609 261.913L510.978 274.325C514.154 275.4 517.626 275.167 520.631 273.679C523.637 272.191 525.929 269.569 527.006 266.389C527.542 264.813 527.762 263.148 527.655 261.487C527.548 259.826 527.116 258.203 526.382 256.709C525.649 255.216 524.629 253.882 523.381 252.784C522.133 251.685 520.682 250.843 519.109 250.307Z'
        }
        fill={'#009F53'}
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M422.732 265.348L386.364 252.961C384.799 252.419 383.144 252.191 381.492 252.29C379.84 252.389 378.223 252.813 376.735 253.538C375.246 254.263 373.915 255.275 372.817 256.515C371.719 257.756 370.876 259.201 370.336 260.768C369.249 263.936 369.459 267.406 370.919 270.42C372.38 273.433 374.972 275.745 378.129 276.85L414.497 289.236C417.677 290.311 421.153 290.08 424.162 288.592C427.171 287.104 429.469 284.482 430.551 281.299C431.597 278.145 431.363 274.704 429.901 271.721C428.438 268.737 425.864 266.448 422.732 265.348Z'
        }
        fill={'#009F53'}
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M504.328 293.426L467.959 281.039C464.796 279.958 461.335 280.171 458.329 281.633C455.322 283.095 453.014 285.687 451.905 288.846C450.819 292.014 451.028 295.484 452.489 298.498C453.949 301.511 456.541 303.823 459.699 304.927L496.067 317.314C499.23 318.402 502.694 318.192 505.702 316.729C508.711 315.266 511.018 312.67 512.121 309.507C513.208 306.339 512.998 302.869 511.538 299.855C510.077 296.842 507.485 294.53 504.328 293.426Z'
        }
        fill={'#009F53'}
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M407.457 309.82L371.089 297.433C369.524 296.891 367.869 296.663 366.217 296.762C364.565 296.861 362.948 297.285 361.46 298.01C359.971 298.735 358.64 299.747 357.542 300.987C356.444 302.228 355.601 303.673 355.061 305.24C353.967 308.408 354.173 311.881 355.635 314.896C357.096 317.912 359.693 320.223 362.854 321.321L399.222 333.708C402.402 334.783 405.878 334.551 408.887 333.064C411.896 331.576 414.194 328.953 415.276 325.771C416.322 322.616 416.088 319.176 414.626 316.192C413.163 313.209 410.589 310.92 407.457 309.82Z'
        }
        fill={'#009F53'}
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M489.053 337.872L452.684 325.485C449.525 324.397 446.065 324.608 443.06 326.072C440.056 327.535 437.753 330.131 436.656 333.292C435.563 336.459 435.769 339.933 437.23 342.948C438.692 345.964 441.288 348.275 444.45 349.373L480.818 361.76C483.981 362.848 487.445 362.638 490.453 361.175C493.462 359.712 495.769 357.116 496.872 353.953C497.95 350.782 497.734 347.312 496.269 344.299C494.804 341.287 492.21 338.977 489.053 337.872Z'
        }
        fill={'#009F53'}
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M392.339 353.901L355.97 341.515C352.79 340.439 349.315 340.671 346.305 342.159C343.296 343.647 340.998 346.269 339.916 349.451C338.837 352.616 339.051 356.08 340.511 359.087C341.971 362.095 344.558 364.402 347.709 365.507L384.078 377.894C385.642 378.436 387.297 378.664 388.949 378.565C390.601 378.466 392.218 378.042 393.706 377.317C395.195 376.592 396.526 375.58 397.624 374.34C398.722 373.099 399.565 371.654 400.106 370.087C400.664 368.514 400.906 366.845 400.817 365.178C400.728 363.511 400.31 361.878 399.588 360.373C398.866 358.868 397.854 357.522 396.609 356.411C395.365 355.3 393.914 354.447 392.339 353.901Z'
        }
        fill={'#009F53'}
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M473.934 382.109L437.565 369.723C434.403 368.634 430.938 368.844 427.93 370.307C424.922 371.77 422.614 374.367 421.511 377.529C420.432 380.698 420.645 384.165 422.104 387.176C423.564 390.188 426.151 392.501 429.304 393.611L465.673 405.997C468.853 407.073 472.328 406.841 475.338 405.353C478.347 403.865 480.645 401.243 481.727 398.061C482.774 394.909 482.544 391.472 481.086 388.489C479.629 385.505 477.06 383.215 473.934 382.109Z'
        }
        fill={'#009F53'}
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M377.558 397.02L341.189 384.633C338.026 383.545 334.562 383.755 331.554 385.218C328.545 386.681 326.238 389.277 325.135 392.44C324.048 395.608 324.258 399.078 325.719 402.092C327.179 405.105 329.771 407.417 332.928 408.522L369.297 420.908C372.459 421.997 375.924 421.786 378.932 420.323C381.94 418.861 384.248 416.264 385.351 413.102C386.437 409.933 386.228 406.463 384.767 403.45C383.307 400.436 380.715 398.124 377.558 397.02Z'
        }
        fill={'#009F53'}
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity={'0.6'}>
      <path
        d={
          'M459.153 425.098L422.784 412.711C419.604 411.636 416.129 411.868 413.12 413.355C410.11 414.843 407.812 417.466 406.73 420.648C405.651 423.813 405.865 427.276 407.325 430.284C408.785 433.291 411.372 435.599 414.523 436.704L450.892 449.09C452.456 449.632 454.111 449.86 455.763 449.761C457.415 449.662 459.032 449.238 460.521 448.513C462.009 447.788 463.34 446.777 464.438 445.536C465.536 444.296 466.379 442.85 466.92 441.283C467.478 439.71 467.72 438.042 467.631 436.375C467.542 434.708 467.124 433.074 466.402 431.57C465.68 430.065 464.668 428.718 463.423 427.608C462.179 426.497 460.728 425.644 459.153 425.098Z'
        }
        fill={'#009F53'}
      />
    </g>
    <path
      d={
        'M454.684 172.084L418.316 159.698C415.154 158.602 411.686 158.809 408.676 160.273C405.666 161.737 403.359 164.338 402.262 167.505C401.182 170.673 401.396 174.14 402.855 177.152C404.314 180.163 406.902 182.476 410.055 183.586L446.423 195.973C449.583 197.053 453.041 196.839 456.043 195.377C459.045 193.914 461.349 191.323 462.452 188.166C463.547 185.002 463.344 181.531 461.888 178.516C460.431 175.502 457.841 173.188 454.684 172.084Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M455.022 188.661L407.925 172.657C405.575 171.862 403.514 170.386 402.002 168.415C401.165 171.52 401.54 174.827 403.05 177.665C404.56 180.502 407.093 182.657 410.133 183.69L446.502 196.077C449.542 197.107 452.861 196.941 455.784 195.612C458.707 194.284 461.016 191.892 462.244 188.921C459.864 189.547 457.352 189.456 455.022 188.661Z'
      }
      fill={'url(#paint2_linear_3247_87506)'}
    />
    <path
      d={
        'M536.306 200.162L499.938 187.776C496.778 186.695 493.32 186.91 490.318 188.372C487.316 189.834 485.012 192.426 483.909 195.582C482.816 198.75 483.022 202.224 484.483 205.239C485.945 208.254 488.541 210.565 491.703 211.664L528.071 224.051C531.234 225.139 534.698 224.929 537.706 223.466C540.715 222.003 543.023 219.407 544.125 216.244C545.204 213.072 544.987 209.602 543.522 206.59C542.057 203.578 539.464 201.267 536.306 200.162Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M536.618 216.764L489.573 200.735C487.223 199.94 485.161 198.464 483.65 196.493C482.812 199.598 483.187 202.905 484.697 205.743C486.208 208.58 488.741 210.735 491.781 211.768L528.149 224.155C531.19 225.192 534.51 225.029 537.435 223.699C540.36 222.37 542.668 219.973 543.892 216.999C541.497 217.644 538.966 217.562 536.618 216.764Z'
      }
      fill={'url(#paint3_linear_3247_87506)'}
    />
    <path
      d={
        'M439.695 215.88L403.327 203.493C400.165 202.398 396.697 202.604 393.687 204.068C390.677 205.532 388.37 208.133 387.273 211.3C386.194 214.468 386.407 217.935 387.866 220.947C389.326 223.959 391.913 226.271 395.066 227.382L431.435 239.768C434.594 240.848 438.052 240.634 441.054 239.172C444.056 237.71 446.36 235.118 447.463 231.961C448.558 228.797 448.355 225.327 446.899 222.312C445.443 219.297 442.852 216.984 439.695 215.88Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M440.007 232.456L392.962 216.452C390.601 215.65 388.531 214.165 387.013 212.185C386.181 215.292 386.558 218.601 388.067 221.441C389.577 224.281 392.106 226.442 395.144 227.486L431.513 239.872C434.55 240.901 437.864 240.734 440.783 239.405C443.702 238.076 446.006 235.685 447.229 232.716C444.848 233.333 442.339 233.243 440.007 232.456Z'
      }
      fill={'url(#paint4_linear_3247_87506)'}
    />
    <path
      d={
        'M521.291 243.958L484.922 231.571C481.76 230.483 478.295 230.693 475.287 232.156C472.279 233.619 469.971 236.215 468.868 239.378C467.789 242.546 468.002 246.013 469.461 249.025C470.921 252.037 473.509 254.349 476.661 255.459L513.03 267.846C516.189 268.934 519.65 268.723 522.654 267.259C525.658 265.796 527.961 263.2 529.058 260.039C530.153 256.875 529.95 253.404 528.494 250.39C527.038 247.375 524.448 245.062 521.291 243.958Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M521.603 260.534L474.557 244.53C472.207 243.735 470.146 242.259 468.635 240.288C467.791 243.393 468.162 246.703 469.674 249.542C471.185 252.382 473.722 254.535 476.765 255.563L513.134 267.95C516.175 268.98 519.493 268.814 522.416 267.486C525.339 266.157 527.649 263.765 528.877 260.794C526.48 261.431 523.948 261.34 521.603 260.534Z'
      }
      fill={'url(#paint5_linear_3247_87506)'}
    />
    <path
      d={
        'M424.68 259.649L388.312 247.262C386.737 246.726 385.073 246.507 383.414 246.616C381.755 246.724 380.133 247.16 378.642 247.897C377.151 248.634 375.82 249.658 374.725 250.911C373.629 252.164 372.791 253.621 372.258 255.199C371.172 258.364 371.382 261.83 372.843 264.84C374.304 267.849 376.896 270.156 380.051 271.255L416.42 283.641C419.582 284.737 423.049 284.53 426.059 283.066C429.07 281.602 431.377 279.001 432.473 275.835C433.024 274.259 433.259 272.591 433.165 270.925C433.071 269.258 432.65 267.627 431.926 266.124C431.203 264.62 430.19 263.275 428.947 262.164C427.703 261.053 426.254 260.198 424.68 259.649Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M424.992 276.251L377.921 260.221C375.574 259.419 373.515 257.945 371.998 255.98C371.167 259.084 371.545 262.389 373.054 265.225C374.564 268.061 377.093 270.216 380.129 271.255L416.498 283.641C419.535 284.686 422.855 284.53 425.781 283.205C428.707 281.88 431.017 279.485 432.24 276.511C429.85 277.133 427.331 277.043 424.992 276.251Z'
      }
      fill={'url(#paint6_linear_3247_87506)'}
    />
    <path
      d={
        'M506.276 287.753L469.907 275.366C466.745 274.271 463.278 274.478 460.268 275.941C457.257 277.405 454.95 280.006 453.853 283.173C452.767 286.341 452.977 289.811 454.437 292.825C455.897 295.838 458.49 298.15 461.647 299.255L498.015 311.641C501.178 312.73 504.642 312.519 507.65 311.057C510.659 309.594 512.967 306.997 514.069 303.835C515.163 300.667 514.957 297.193 513.495 294.178C512.034 291.163 509.437 288.852 506.276 287.753Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M506.64 304.329L459.542 288.429C457.189 287.628 455.12 286.153 453.594 284.188C452.763 287.292 453.14 290.597 454.65 293.433C456.159 296.269 458.688 298.424 461.725 299.463L498.093 311.849C501.134 312.88 504.452 312.714 507.375 311.385C510.298 310.056 512.608 307.664 513.836 304.693C511.454 305.285 508.95 305.159 506.64 304.329Z'
      }
      fill={'url(#paint7_linear_3247_87506)'}
    />
    <path
      d={
        'M409.64 303.444L373.271 291.058C370.112 289.977 366.654 290.192 363.652 291.654C360.649 293.116 358.346 295.708 357.243 298.864C356.149 302.032 356.355 305.505 357.817 308.521C359.278 311.536 361.875 313.847 365.036 314.946L401.405 327.333C404.567 328.421 408.032 328.211 411.04 326.748C414.048 325.285 416.356 322.688 417.459 319.526C418.537 316.354 418.32 312.884 416.855 309.872C415.391 306.86 412.797 304.549 409.64 303.444Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M409.977 320.046L362.906 304.043C360.556 303.248 358.494 301.771 356.983 299.801C356.152 302.905 356.529 306.21 358.039 309.046C359.549 311.882 362.078 314.037 365.114 315.076L401.483 327.463C404.523 328.499 407.843 328.336 410.768 327.007C413.693 325.677 416.002 323.281 417.225 320.307C414.837 320.943 412.314 320.852 409.977 320.046Z'
      }
      fill={'url(#paint8_linear_3247_87506)'}
    />
    <path
      d={
        'M491.261 331.548L454.892 319.136C451.716 318.061 448.244 318.294 445.239 319.782C442.234 321.27 439.941 323.892 438.864 327.072C437.785 330.241 437.998 333.708 439.457 336.72C440.917 339.731 443.505 342.044 446.658 343.154L483.026 355.541C486.206 356.616 489.681 356.384 492.691 354.896C495.7 353.408 497.998 350.786 499.08 347.604C500.157 344.436 499.94 340.969 498.475 337.961C497.01 334.953 494.417 332.648 491.261 331.548Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M491.572 348.124L444.501 332.121C442.148 331.319 440.086 329.833 438.579 327.853C437.747 330.961 438.123 334.269 439.632 337.109C441.142 339.95 443.671 342.11 446.709 343.154L483.078 355.541C486.135 356.572 489.47 356.395 492.401 355.046C495.332 353.696 497.637 351.276 498.846 348.28C496.459 348.941 493.93 348.887 491.572 348.124Z'
      }
      fill={'url(#paint9_linear_3247_87506)'}
    />
    <path
      d={
        'M394.624 347.24L358.256 334.853C355.093 333.765 351.629 333.975 348.621 335.438C345.612 336.901 343.304 339.497 342.202 342.66C341.122 345.828 341.335 349.295 342.795 352.307C344.254 355.318 346.842 357.631 349.995 358.741L386.363 371.128C389.523 372.215 392.983 372.004 395.988 370.541C398.992 369.078 401.295 366.482 402.392 363.321C403.487 360.157 403.284 356.686 401.828 353.672C400.371 350.657 397.781 348.343 394.624 347.24Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M394.936 363.894L347.891 347.89C345.541 347.095 343.479 345.619 341.968 343.648C341.124 346.753 341.496 350.063 343.007 352.902C344.518 355.742 347.055 357.895 350.099 358.923L386.467 371.31C389.508 372.34 392.826 372.174 395.749 370.846C398.672 369.517 400.982 367.125 402.21 364.154C399.813 364.791 397.282 364.7 394.936 363.894Z'
      }
      fill={'url(#paint10_linear_3247_87506)'}
    />
    <path
      d={
        'M476.22 375.317L439.852 362.931C436.675 361.857 433.203 362.089 430.198 363.577C427.193 365.065 424.9 367.687 423.823 370.868C423.282 372.434 423.055 374.093 423.153 375.748C423.252 377.403 423.676 379.022 424.399 380.513C425.123 382.004 426.133 383.337 427.371 384.437C428.61 385.537 430.052 386.382 431.617 386.923L467.985 399.31C471.148 400.405 474.615 400.199 477.625 398.735C480.636 397.271 482.943 394.67 484.039 391.503C484.589 389.926 484.823 388.256 484.727 386.589C484.632 384.921 484.208 383.289 483.482 381.785C482.756 380.282 481.74 378.936 480.494 377.827C479.248 376.717 477.796 375.864 476.22 375.317Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M476.532 391.919L429.487 375.89C427.124 375.118 425.044 373.66 423.512 371.7C422.674 374.805 423.049 378.112 424.56 380.95C426.07 383.787 428.603 385.942 431.643 386.975L468.011 399.362C471.049 400.394 474.364 400.231 477.287 398.908C480.209 397.584 482.521 395.198 483.754 392.232C481.367 392.827 478.859 392.719 476.532 391.919Z'
      }
      fill={'url(#paint11_linear_3247_87506)'}
    />
    <path
      d={
        'M379.609 391.035L343.241 378.648C340.078 377.553 336.611 377.759 333.601 379.223C330.591 380.687 328.284 383.288 327.187 386.455C326.107 389.623 326.32 393.09 327.78 396.102C329.239 399.114 331.827 401.426 334.98 402.537L371.349 414.923C374.508 416.003 377.965 415.789 380.968 414.327C383.97 412.865 386.274 410.273 387.377 407.116C388.472 403.952 388.269 400.482 386.813 397.467C385.357 394.452 382.766 392.139 379.609 391.035Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M379.921 407.611L332.876 391.607C330.522 390.806 328.454 389.331 326.927 387.366C326.096 390.47 326.474 393.775 327.983 396.61C329.493 399.446 332.022 401.602 335.058 402.641L371.427 415.027C374.447 416.019 377.729 415.832 380.619 414.506C383.508 413.179 385.791 410.81 387.013 407.871C384.674 408.476 382.21 408.385 379.921 407.611Z'
      }
      fill={'url(#paint12_linear_3247_87506)'}
    />
    <path
      d={
        'M461.205 419.113L424.837 406.726C421.674 405.645 418.213 405.858 415.206 407.32C412.199 408.782 409.891 411.374 408.782 414.533C407.696 417.701 407.906 421.171 409.366 424.185C410.827 427.198 413.418 429.51 416.576 430.614L452.944 443.001C456.103 444.089 459.564 443.878 462.568 442.414C465.573 440.951 467.875 438.355 468.972 435.194C470.067 432.03 469.864 428.559 468.408 425.545C466.952 422.53 464.362 420.217 461.205 419.113Z'
      }
      fill={'white'}
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      d={
        'M461.517 435.715L414.471 419.685C412.121 418.89 410.06 417.414 408.548 415.444C407.704 418.548 408.076 421.858 409.587 424.698C411.099 427.537 413.636 429.69 416.679 430.719L453.048 443.105C456.089 444.135 459.407 443.969 462.33 442.641C465.253 441.312 467.562 438.92 468.79 435.949C466.396 436.594 463.865 436.513 461.517 435.715Z'
      }
      fill={'url(#paint13_linear_3247_87506)'}
    />
    <path
      d={
        'M504.275 514.302C504.27 517.687 503.264 520.995 501.382 523.807C499.501 526.62 496.829 528.81 493.706 530.102C490.582 531.394 487.146 531.73 483.832 531.066C480.518 530.402 477.475 528.77 475.087 526.374C472.699 523.978 471.073 520.927 470.416 517.606C469.758 514.286 470.098 510.844 471.393 507.717C472.687 504.59 474.878 501.917 477.688 500.037C480.499 498.157 483.803 497.153 487.182 497.153C491.718 497.16 496.066 498.97 499.271 502.185C502.475 505.4 504.275 509.758 504.275 514.302Z'
      }
      fill={'#00CC95'}
    />
    <path
      d={
        'M72.945 471.183C72.945 474.569 71.9424 477.88 70.0642 480.696C68.186 483.512 65.5164 485.706 62.393 487.002C59.2697 488.298 55.8328 488.637 52.517 487.977C49.2012 487.316 46.1557 485.685 43.7651 483.29C41.3746 480.896 39.7465 477.845 39.087 474.523C38.4274 471.202 38.766 467.759 40.0597 464.63C41.3535 461.502 43.5443 458.828 46.3553 456.946C49.1662 455.065 52.471 454.06 55.8517 454.06C60.383 454.067 64.7267 455.873 67.9308 459.083C71.135 462.293 72.9381 466.644 72.945 471.183Z'
      }
      fill={'#00CC95'}
    />
    <path
      d={
        'M529.578 550.29C529.578 552.087 529.046 553.842 528.05 555.336C527.054 556.829 525.638 557.993 523.981 558.681C522.324 559.368 520.502 559.548 518.743 559.198C516.984 558.847 515.369 557.982 514.101 556.712C512.833 555.442 511.969 553.824 511.62 552.062C511.27 550.3 511.449 548.474 512.135 546.815C512.821 545.155 513.983 543.737 515.474 542.739C516.965 541.741 518.718 541.209 520.511 541.209C521.703 541.205 522.883 541.438 523.985 541.893C525.087 542.348 526.087 543.017 526.93 543.861C527.773 544.705 528.44 545.707 528.895 546.811C529.349 547.914 529.581 549.097 529.578 550.29Z'
      }
      fill={'#00CC95'}
    />
    <path
      d={
        'M28.1335 441.362C28.1386 443.16 27.6106 444.919 26.6164 446.417C25.6222 447.914 24.2065 449.082 22.5489 449.773C20.8912 450.464 19.0661 450.646 17.305 450.296C15.5439 449.946 13.9261 449.081 12.6567 447.809C11.3872 446.538 10.5232 444.917 10.1742 443.153C9.82518 441.389 10.0069 439.561 10.6963 437.9C11.3857 436.24 12.5518 434.822 14.0467 433.826C15.5416 432.83 17.298 432.301 19.0933 432.306C21.4909 432.306 23.7904 433.26 25.4858 434.958C27.1811 436.657 28.1335 438.96 28.1335 441.362Z'
      }
      fill={'#00CC95'}
    />
    <defs>
      <linearGradient
        id={'paint0_linear_3247_87506'}
        x1={'324.927'}
        y1={'498.74'}
        x2={'56.882'}
        y2={'385.925'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint1_linear_3247_87506'}
        x1={'125318'}
        y1={'34277.7'}
        x2={'131832'}
        y2={'41627.2'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint2_linear_3247_87506'}
        x1={'64854.8'}
        y1={'24805.4'}
        x2={'64803.7'}
        y2={'25743.9'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint3_linear_3247_87506'}
        x1={'66829.6'}
        y1={'25511.2'}
        x2={'66778.5'}
        y2={'26450.1'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint4_linear_3247_87506'}
        x1={'64627.1'}
        y1={'25308.8'}
        x2={'64576.4'}
        y2={'26248.5'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint5_linear_3247_87506'}
        x1={'66642.5'}
        y1={'26025.8'}
        x2={'66591.4'}
        y2={'26964.3'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint6_linear_3247_87506'}
        x1={'64460.2'}
        y1={'25830.4'}
        x2={'64409.3'}
        y2={'26769.4'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint7_linear_3247_87506'}
        x1={'66436.7'}
        y1={'26534.4'}
        x2={'66385.7'}
        y2={'27472.9'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint8_linear_3247_87506'}
        x1={'64267'}
        y1={'26342.2'}
        x2={'64216'}
        y2={'27280.9'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint9_linear_3247_87506'}
        x1={'66271.9'}
        y1={'27059.6'}
        x2={'66220.8'}
        y2={'27999'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint10_linear_3247_87506'}
        x1={'64085.5'}
        y1={'26858.1'}
        x2={'64034.4'}
        y2={'27796.8'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint11_linear_3247_87506'}
        x1={'66054.8'}
        y1={'27562.3'}
        x2={'66003.8'}
        y2={'28500.9'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint12_linear_3247_87506'}
        x1={'63714.2'}
        y1={'27303.8'}
        x2={'63663.6'}
        y2={'28241'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
      <linearGradient
        id={'paint13_linear_3247_87506'}
        x1={'65867.3'}
        y1={'28076.4'}
        x2={'65816.3'}
        y2={'29015.4'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'white'} stopOpacity={'0'} />
        <stop offset={'1'} stopColor={'#235196'} />
      </linearGradient>
    </defs>
  </SvgIcon>
);
