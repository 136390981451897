import { DepartmentDTO } from '@/types/DepartmentDTO';
import { RegionDTO } from '@/types/RegionDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const createFormApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getRegions: build.query<RegionDTO[], void>({
      query: () => ({
        url: '/static/regions',
        method: 'GET',
      }),
      providesTags: (result?: RegionDTO[]) => {
        return result
          ? [
              ...result
                .flatMap((value) => value.departments)
                .map((item: DepartmentDTO) => ({
                  type: 'Departments' as const,
                  id: item.id,
                })),
              'Departments',
              'Regions',
            ]
          : ['Regions'];
      },
    }),
    checkValidDepartment: build.mutation<boolean, number>({
      query: (departmentId) => ({
        url: '/static/check-department',
        method: 'GET',
        params: {
          departmentId: departmentId,
        },
      }),
      invalidatesTags: (result: unknown, error, arg) => {
        return [{ type: 'Departments', id: arg }];
      },
    }),
    checkOrdersModeration: build.mutation<boolean, number>({
      query: (departmentId) => ({
        url: '/static/check-orders-moderation',
        method: 'GET',
        params: {
          departmentId: departmentId,
        },
      }),
      invalidatesTags: (result: unknown, error, arg) => {
        return [{ type: 'Departments', id: arg }];
      },
    }),
  }),
});

export const {
  useGetRegionsQuery,
  useCheckValidDepartmentMutation,
  useCheckOrdersModerationMutation,
  useLazyGetRegionsQuery,
} = createFormApiSlice;
