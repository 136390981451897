import { Box, Container, Grid, Theme, useMediaQuery } from '@mui/material';
import { FeedbackPage } from '@/components/feedback/FeedbackPage';
import { Footer } from '@/components/common/footer/Footer';
import { Header } from '@/components/common/header/Header';
import { MobileHeader } from '@/components/common/header/MobileHeader';
import { OrderPage } from '@/components/common/OrderPage';
import { ROUTE_FEEDBACK, ROUTE_INFO, ROUTE_ORDER } from '@/app/routes';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import InformationPage from '@/components/information/InformationPage';
import React, { FC, Fragment } from 'react';

const Main: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const location = useLocation();
  return (
    <Fragment>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
        }}>
        <Box display={'flex'} flex={1}>
          <Grid
            container={true}
            direction={'column'}
            wrap={'nowrap'}
            spacing={7}>
            <Grid item={true} pb={isMobile ? 0 : 6}>
              {isMobile ? <MobileHeader /> : <Header />}
            </Grid>
            <Grid item={true} xs={true}>
              <Switch>
                <Route path={ROUTE_ORDER}>
                  <OrderPage />
                </Route>
                <Route path={ROUTE_INFO}>
                  <InformationPage />
                </Route>
                <Route path={ROUTE_FEEDBACK}>
                  <FeedbackPage />
                </Route>
                <Redirect to={ROUTE_ORDER} />
              </Switch>
            </Grid>
            {((location.pathname === ROUTE_ORDER && isMobile) || !isMobile) && (
              <Grid
                item={true}
                sx={{
                  '&.MuiGrid-root': {
                    paddingTop: isMobile ? '0' : '56px',
                    paddingBottom: isMobile ? '48px' : '0',
                  },
                }}>
                <Footer isMobile={isMobile} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

export default Main;
