import * as Yup from 'yup';
import {
  MESSAGE_INVALID_EMAIl,
  MESSAGE_REQUIRED,
  regExEmailCharacters,
} from '@/utils/validation-utils';

export const emailSchema: Yup.StringSchema = Yup.string()
  .required(MESSAGE_REQUIRED)
  .email(MESSAGE_INVALID_EMAIl)
  .matches(regExEmailCharacters, MESSAGE_INVALID_EMAIl);
