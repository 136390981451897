import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const CloseIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      d={
        'M3.38527 18.6548C2.88136 19.1704 2.85792 20.0845 3.39698 20.6118C3.93605 21.1392 4.83839 21.1275 5.35402 20.6235L12.0103 13.9673L18.6548 20.6235C19.1821 21.1392 20.0845 21.1509 20.6118 20.6118C21.1392 20.0728 21.1509 19.1821 20.6235 18.6548L13.9673 11.9985L20.6235 5.35402C21.1509 4.82667 21.1509 3.92433 20.6118 3.39698C20.0728 2.86964 19.1821 2.86964 18.6548 3.38527L12.0103 10.0415L5.35402 3.38527C4.83839 2.88136 3.92433 2.85792 3.39698 3.39698C2.86964 3.93605 2.88136 4.83839 3.38527 5.3423L10.0415 11.9985L3.38527 18.6548Z'
      }
    />
  </SvgIcon>
);
