import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { HomeIcon } from '@/app/icons/HomeIcon';
import { OrderDeclinedPage } from '@/components/order/success/OrderDeclinedPage';
import { OrderMismatchPage } from '@/components/order/success/OrderMismatchPage';
import { OrderModeratedPage } from '@/components/order/success/OrderModeratedPage';
import { OrderModerationPage } from '@/components/order/success/OrderModerationPage';
import { OrderPayedPage } from '@/components/order/success/OrderPayedPage';
import { OrderPaymentPage } from '@/components/order/success/OrderPaymentPage';
import {
  ROUTE_ORDER,
  ROUTE_ORDER_CLIENT,
  ROUTE_ORDER_DECLINED_UUID,
  ROUTE_ORDER_MISMATCH_UUID,
  ROUTE_ORDER_MODERATED_UUID,
  ROUTE_ORDER_MODERATION_UUID,
  ROUTE_ORDER_PAYMENT_UUID,
  ROUTE_ORDER_SHOWCASE,
  ROUTE_ORDER_SUCCESS_UUID,
} from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ShowcasePage } from '@/components/order/ShowcasePage';
import ClientCreateForm from '@/components/order/ClientCreateForm';
import DepartmentCreateForm from '@/components/order/DepartmentCreateForm';
import React, { FC } from 'react';

export const OrderPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  return (
    <Switch>
      <Route exact={true} path={ROUTE_ORDER}>
        <Grid container={true} direction={'column'} sx={{ height: '100%' }}>
          <Grid item={true}>
            <Grid
              container={true}
              paddingBottom={isMobile ? 2 : 4}
              mt={isMobile ? -3 : 0}>
              <Grid item={true} xs={isMobile ? 12 : 8}>
                <Typography
                  fontSize={isMobile ? '24px' : '48px'}
                  lineHeight={isMobile ? '32px' : '58px'}
                  whiteSpace={'pre-line'}
                  fontWeight={500}>
                  {'Ф-Аптека:\nДоставка лекарств в исправительные учреждения'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={isMobile} pb={isMobile ? 2 : 0}>
            <Grid container={true} wrap={'nowrap'}>
              <Grid item={true} xs={isMobile ? 12 : 6}>
                <DepartmentCreateForm isMobile={isMobile} />
              </Grid>
              {!isMobile && (
                <Grid item={true} ml={-8} mt={6} sx={{ zIndex: -1 }}>
                  <HomeIcon />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Route>
      <Route exact={true} path={ROUTE_ORDER_CLIENT}>
        <ClientCreateForm isMobile={isMobile} />
      </Route>
      <Route exact={true} path={ROUTE_ORDER_SHOWCASE}>
        <ShowcasePage />
      </Route>
      <Route path={ROUTE_ORDER_SUCCESS_UUID}>
        <OrderPayedPage />
      </Route>
      <Route path={ROUTE_ORDER_MODERATION_UUID}>
        <OrderModerationPage />
      </Route>
      <Route path={ROUTE_ORDER_PAYMENT_UUID}>
        <OrderPaymentPage />
      </Route>
      <Route path={ROUTE_ORDER_MODERATED_UUID}>
        <OrderModeratedPage />
      </Route>
      <Route path={ROUTE_ORDER_DECLINED_UUID}>
        <OrderDeclinedPage />
      </Route>
      <Route path={ROUTE_ORDER_MISMATCH_UUID}>
        <OrderMismatchPage />
      </Route>
      <Redirect to={ROUTE_ORDER} />
    </Switch>
  );
};
