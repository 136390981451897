import { Footer } from '@/components/common/footer/Footer';
import { Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { MESSAGE_ERROR_DATA_LOAD } from '@/utils/validation-utils';
import { Theme } from '@mui/material/styles';
import { useGetInfoPageContentQuery } from '@/services/api/helpApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useEffect } from 'react';

interface Props {
  isMainPage?: boolean;
}

const InformationPage: FC<Props> = (props: Props) => {
  const { isMainPage } = props;
  const { enqueueSnackbar } = useSnackbar();

  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  const {
    data: content,
    isFetching,
    isLoading,
    isError,
  } = useGetInfoPageContentQuery();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(MESSAGE_ERROR_DATA_LOAD, {
        variant: 'error',
      });
    }
  }, [isError]);

  return (
    <Grid container={true} direction={'column'} sx={{ minHeight: '100%' }}>
      {!isMainPage && (
        <Grid item={true} mb={isMobile ? 2 : 4}>
          <Typography variant={'h1'}>{'Информация'}</Typography>
        </Grid>
      )}
      <Grid item={true} mb={5} xs={true}>
        <Grid container={true} columns={16}>
          <Grid item={true} xs={16} sm={12} md={9}>
            <Grid container={true} direction={'column'}>
              {isFetching || isLoading || typeof content === 'undefined' ? (
                <Fragment>
                  {Array.from(new Array(6)).map((index) => (
                    <Skeleton key={index} height={'24px'} />
                  ))}
                </Fragment>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: content.content }} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isMobile && !isMainPage && (
        <Grid item={true} mt={5}>
          <Footer isMobile={isMobile} />
        </Grid>
      )}
    </Grid>
  );
};

export default InformationPage;
